import * as React from 'react'
import { PropsWithChildren, useContext } from 'react'

import {
  CandidateCategories,
  candidateNounSlugs,
  getSanitySlug,
  isCandidateSlug
} from '../async/sanity/utils'
import { generateQueryString } from '../utilities/string'
import { InputOption } from '../components/action-selector/action-input'

export enum FilterType {
  COBENEFITS = 'COBENEFITS',
  MEDIUMS = 'MEDIUMS',
  IMPACT = 'IMPACT',
  SKILLS = 'SKILLS'
}

interface FilterOptions {
  [FilterType.COBENEFITS]?: Queries.SanityCobenefits[] | null
  [FilterType.SKILLS]?: Queries.SanitySkill[] | null
  [FilterType.MEDIUMS]?: Queries.SanityMedium[] | null
  [FilterType.IMPACT]?:
    | { slug: Pick<Queries.Maybe<Queries.SanitySlug>, 'current'>; title: string }[]
    | null
}

interface SelectedFilterValues {
  [FilterType.COBENEFITS]?: InputOption
  [FilterType.SKILLS]?: InputOption
  [FilterType.MEDIUMS]?: InputOption
  [FilterType.IMPACT]?: InputOption
}

const FILTER_TYPE_TO_ENDPOINT = {
  [FilterType.COBENEFITS]: `/.netlify/functions/sanity-cobenefits`,
  [FilterType.MEDIUMS]: `/.netlify/functions/sanity-mediums`,
  [FilterType.SKILLS]: `/.netlify/functions/sanity-skills`
}

const GENERAL_ERROR_MSG = 'There was an error loading this data.'
const IMPACT_OPTIONS = [
  { slug: { current: '1' }, title: 'low' },
  { slug: { current: '2' }, title: 'medium' },
  { slug: { current: '3' }, title: 'high' }
]

export const isFilterActivated = (filter: FilterType, activeFilters: FilterType[]) => {
  return activeFilters != null && activeFilters.includes(filter)
}

export type ActionSelectorState = {
  loading?: boolean
  error?: string
  matchingParams?: MatchingParams
  optionsVerbs?: Queries.SanityVerb[]
  optionsNouns?: Queries.SanityNoun[]
  optionsFilters?: FilterOptions
  optionsEndorsements?: Queries.SanityOrganization[]
  optionsIssues?: Queries.SanityNoun[]
  activeFilters?: FilterType[]
  selectedVerb?: Queries.SanityVerb
  selectedNoun?: Queries.SanityNoun
  selectedFilterValues?: SelectedFilterValues
  selectedEndorsement?: Queries.SanityOrganization
  selectedIssue?: Queries.SanityNoun
  selectedCandidateCategory?: CandidateCategories
  isSearchingCandidates?: boolean
  query?: string
  location?: string
  canSearch?: boolean
  expandLocations?: boolean
  showAddress?: boolean
  showAdditionalFilters?: boolean
  setSelectedVerb: Function
  setSelectedNoun: Function
  setSelectedFilterValues: Function
  setSelectedCandidateCategory: Function
  setSelectedEndorsement: Function
  setSelectedIssue: Function
  fetchVerbs: Function
  resetSearch: (resetVerb?: boolean) => void
  setLocation: Function
  setExpandLocations: Function
  setActiveFilters: Function
}

export const ActionSelectorContext = React.createContext<ActionSelectorState>({
  setSelectedVerb: () => {},
  setSelectedNoun: () => {},
  setSelectedFilterValues: () => {},
  setSelectedEndorsement: () => {},
  setSelectedIssue: () => {},
  setSelectedCandidateCategory: () => {},
  setLocation: () => {},
  setExpandLocations: () => {},
  setActiveFilters: () => {},
  fetchVerbs: () => {},
  resetSearch: () => {}
})

type MatchingParams = {
  cobenefits?: string[]
  mediums?: string[]
  skills?: string[]
  impact?: string[]
}

export const ActionSelectorProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [loading, setLoading] = React.useState(false)
  const [error, setError] = React.useState<string>()
  const [activeFilters, setActiveFilters] = React.useState<FilterType[]>([])
  const [optionsVerbs, setOptionsVerbs] = React.useState<Queries.SanityVerb[]>()
  const [selectedVerb, setSelectedVerb] = React.useState<Queries.SanityVerb>()
  const [optionsNouns, setOptionsNouns] = React.useState<Queries.SanityNoun[]>()
  const [selectedNoun, setSelectedNoun] = React.useState<Queries.SanityNoun>()

  const [optionsFilters, setOptionsFilters] = React.useState<FilterOptions>({
    [FilterType.IMPACT]: IMPACT_OPTIONS
  })
  const [selectedFilterValues, setSelectedFilterValues] = React.useState<SelectedFilterValues>()

  const [location, setLocation] = React.useState<string>()
  const [expandLocations, setExpandLocations] = React.useState<boolean>(false)

  const [selectedCandidateCategory, setSelectedCandidateCategory] =
    React.useState<CandidateCategories>()
  const [optionsEndorsements, setOptionsEndorsements] =
    React.useState<Queries.SanityOrganization[]>()
  const [selectedEndorsement, setSelectedEndorsement] = React.useState<Queries.SanityOrganization>()

  const [optionsIssues, setOptionsIssues] = React.useState<Queries.SanityNoun[]>()
  const [selectedIssue, setSelectedIssue] = React.useState<Queries.SanityNoun>()

  const [matchingParams, setMatchingParams] = React.useState<MatchingParams>()
  // intial load

  const fetchVerbs = async () => {
    setLoading(true)
    const response = await fetch(`/.netlify/functions/sanity-verbs`)
    if (response?.status !== 200) {
      setError(GENERAL_ERROR_MSG)
    }

    const verbs = (await response?.json()) as Queries.SanityVerb[]
    setOptionsVerbs(verbs)
    setLoading(false)
  }

  const resetSearch = (resetVerb = true) => {
    if (resetVerb) {
      setSelectedVerb(undefined)
    }

    setSelectedNoun(undefined)
    resetSearchParams()
  }

  const resetSearchParams = () => {
    setActiveFilters([])
    setSelectedFilterValues(undefined)
    setSelectedCandidateCategory(undefined)
    setSelectedEndorsement(undefined)
    setSelectedIssue(undefined)
  }

  const handleVerbSelect = async (verb?: Queries.SanityVerb) => {
    resetSearch()
    setOptionsNouns([])
    if (!verb) {
      setSelectedVerb(undefined)
    } else {
      setLoading(true)

      setSelectedVerb(verb)
      const categorySlug = verb?.category?.slug?.current
      const slugs = verb?.category?.include_candidates ? candidateNounSlugs : null

      const response = await fetch(
        `/.netlify/functions/sanity-nouns${generateQueryString({
          categories: categorySlug,
          slugs: slugs
        })}`
      )

      if (response?.status !== 200) {
        setError(GENERAL_ERROR_MSG)
      }

      const nouns = (await response?.json()) as Queries.SanityNoun[]

      setOptionsNouns(nouns)
      setLoading(false)
    }
  }

  const handleNounSelect = async (noun?: Queries.SanityNoun) => {
    setMatchingParams(undefined)
    resetSearchParams()
    if (!noun) {
      setSelectedNoun(undefined)
    } else {
      setSelectedNoun(noun)
      const categorySlug = selectedVerb?.category?.slug?.current

      const matchingParamsRequest = await fetch(
        `/.netlify/functions/sanity-get-matching-params${generateQueryString({
          categories: categorySlug,
          nouns: noun.slug.current
        })}`
      )

      if (matchingParamsRequest?.status !== 200) {
        setError(GENERAL_ERROR_MSG)
      }

      const matchingParams = (await matchingParamsRequest?.json()) as MatchingParams
      setMatchingParams(matchingParams)
    }
  }

  const fetchCandidateFields = async () => {
    setLoading(true)
    const response = await fetch(`/.netlify/functions/sanity-get-candidate-searchable-data`)
    if (response?.status !== 200) {
      setError(GENERAL_ERROR_MSG)
    } else {
      const { endorsements, issues } = (await response?.json()) as {
        endorsements: Queries.SanityOrganization[]
        issues: Queries.SanityNoun[]
      }
      setOptionsEndorsements(endorsements.filter(exists => exists))
      setOptionsIssues(issues.filter(exists => exists))
    }
    setLoading(false)
  }

  const optionallyFetchFilterOptions = async (filterType: FilterType) => {
    const currentOptions = optionsFilters?.[filterType]
    if (isFilterActivated(filterType, activeFilters) && !currentOptions) {
      const endpoint = FILTER_TYPE_TO_ENDPOINT[filterType]
      const response = await fetch(endpoint)
      if (response?.status !== 200) {
        setError(GENERAL_ERROR_MSG)
      } else {
        const options = await response?.json()
        setOptionsFilters(value => ({ ...value, [filterType]: options }))
      }
    }
  }

  React.useEffect(() => {
    const fetchActiveFilterOptions = async () => {
      if (activeFilters && activeFilters.length > 0) {
        optionallyFetchFilterOptions(FilterType.COBENEFITS)
        optionallyFetchFilterOptions(FilterType.SKILLS)
        optionallyFetchFilterOptions(FilterType.MEDIUMS)
      }
    }

    fetchActiveFilterOptions()
  }, [activeFilters])

  React.useEffect(() => {
    if (selectedCandidateCategory) {
      fetchCandidateFields()
    }
  }, [selectedCandidateCategory])

  React.useEffect(() => {
    if (!selectedVerb) {
      setSelectedNoun(undefined)
      setSelectedEndorsement(undefined)
      setSelectedCandidateCategory(undefined)
      setSelectedIssue(undefined)
    }
    if (!selectedNoun) {
      setSelectedEndorsement(undefined)
      setSelectedCandidateCategory(undefined)
      setSelectedIssue(undefined)
    }
  }, [selectedVerb, selectedNoun])

  // clear out error
  React.useEffect(() => {
    setError(undefined)
  }, [selectedVerb, selectedNoun, selectedEndorsement, selectedIssue, selectedCandidateCategory])

  const params = [
    selectedVerb && `verbs=${selectedVerb.slug?.current}`,
    selectedNoun && `nouns=${selectedNoun.slug?.current}`,
    selectedFilterValues?.[FilterType.COBENEFITS] &&
      `cobenefits=${selectedFilterValues?.[FilterType.COBENEFITS].slug}`,
    selectedFilterValues?.[FilterType.MEDIUMS] &&
      `mediums=${selectedFilterValues?.[FilterType.MEDIUMS].slug}`,
    selectedFilterValues?.[FilterType.SKILLS] &&
      `skills=${selectedFilterValues?.[FilterType.SKILLS].slug}`,
    selectedFilterValues?.[FilterType.IMPACT] &&
      `impacts=${selectedFilterValues?.[FilterType.IMPACT].slug}`,
    selectedEndorsement && `endorsements=${selectedEndorsement.slug?.current}`,
    selectedIssue && `issues=${selectedIssue.slug?.current}`,
    location && `locations=${location}`,
    location && expandLocations && 'expandLocations=true'
  ].filter(param => param != null)

  const query = `/results?${params.join('&')}`
  const isSearchingCandidates = Boolean(
    selectedNoun && isCandidateSlug(getSanitySlug(selectedNoun?.slug))
  )

  const showAddress = selectedVerb?.category?.slug?.current === 'be-heard' || isSearchingCandidates

  const canSearch = Boolean(
    selectedVerb && selectedNoun && (!isSearchingCandidates || selectedEndorsement || selectedIssue)
  )

  const showAdditionalFilters = Boolean(selectedVerb && selectedNoun)
  return (
    <ActionSelectorContext.Provider
      value={{
        loading,
        error,
        location,
        expandLocations,
        matchingParams,
        optionsVerbs,
        optionsNouns,
        optionsFilters,
        optionsEndorsements,
        optionsIssues,
        activeFilters,
        selectedVerb,
        selectedNoun,
        selectedFilterValues,
        selectedEndorsement,
        selectedIssue,
        selectedCandidateCategory,
        isSearchingCandidates,
        showAddress,
        showAdditionalFilters,
        canSearch,
        query,

        setLocation,
        setExpandLocations,
        setSelectedVerb: handleVerbSelect,
        setSelectedNoun: handleNounSelect,
        setSelectedFilterValues,
        setSelectedCandidateCategory,
        setSelectedEndorsement,
        setSelectedIssue,
        setActiveFilters,
        fetchVerbs,
        resetSearch
      }}
    >
      {children}
    </ActionSelectorContext.Provider>
  )
}

export const actionSelectorState = () => useContext(ActionSelectorContext)
