import React from 'react'
import { CustomIconProps } from '../../types/component-types'
import { colors } from '../../constants/colors'

const HeartIcon = ({ color }: CustomIconProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
    <path
      d="M12.3541 25.8769L13.0137 25.1253L12.3541 25.8769ZM16 7.96029L15.2435 8.61428C15.4335 8.834 15.7095 8.96029 16 8.96029C16.2905 8.96029 16.5666 8.834 16.7565 8.61428L16 7.96029ZM19.6459 25.8769L20.3056 26.6284L19.6459 25.8769ZM13.0137 25.1253C9.3548 21.9139 5 18.7675 5 12.8187H3C3 19.8641 8.29182 23.642 11.6944 26.6284L13.0137 25.1253ZM5 12.8187C5 9.88692 6.48883 7.48922 8.42864 6.50201C9.38692 6.01432 10.4716 5.85728 11.6042 6.14074C12.743 6.42574 13.9987 7.1744 15.2435 8.61428L16.7565 7.30629C15.3014 5.62307 13.7072 4.60536 12.0897 4.20057C10.4661 3.79424 8.88825 4.024 7.52151 4.71956C4.81127 6.09886 3 9.2535 3 12.8187H5ZM11.6944 26.6284C12.3077 27.1667 12.9727 27.7467 13.6489 28.1867C14.3217 28.6244 15.1176 29 16 29V27C15.6824 27 15.2783 26.8608 14.7396 26.5103C14.2044 26.1621 13.6464 25.6806 13.0137 25.1253L11.6944 26.6284ZM20.3056 26.6284C23.7082 23.642 29 19.8641 29 12.8187H27C27 18.7675 22.6452 21.9139 18.9863 25.1253L20.3056 26.6284ZM29 12.8187C29 9.2535 27.1887 6.09886 24.4785 4.71956C23.1118 4.024 21.5339 3.79424 19.9103 4.20057C18.2928 4.60536 16.6986 5.62307 15.2435 7.30629L16.7565 8.61428C18.0013 7.1744 19.257 6.42574 20.3958 6.14074C21.5284 5.85728 22.6131 6.01432 23.5714 6.50201C25.5112 7.48922 27 9.88692 27 12.8187H29ZM18.9863 25.1253C18.3536 25.6806 17.7956 26.1621 17.2604 26.5103C16.7217 26.8608 16.3176 27 16 27V29C16.8824 29 17.6783 28.6244 18.3511 28.1867C19.0273 27.7467 19.6923 27.1667 20.3056 26.6284L18.9863 25.1253Z"
      fill={color != null ? color : colors.WHITE}
    />
  </svg>
)

export default HeartIcon
