import React from 'react'
import { IconPath } from '../../elements/basic-elements'
import { CustomIconProps } from '../../types/component-types'

const PremiumIcon = ({ size = 24, color = '#FF4438' }: CustomIconProps) => (
  <svg
    width={size}
    height={size / 0.96}
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <IconPath
      d="M8.74381 3.23253C8.70336 3.08493 8.85131 2.94994 8.98826 3.01824C11.7769 4.40894 12.1596 7.26489 11.573 9.56074C11.0347 11.6675 11.743 13.9134 13.6812 13.9134C15.4586 13.9134 16.6638 10.9835 16.0954 8.71559C16.0521 8.54309 16.2569 8.39236 16.3922 8.50773C21.4699 12.836 19.1475 22.1702 11.9927 22.1702C7.98412 22.1702 4.63281 18.819 4.63281 14.8103C4.63281 11.2524 5.99469 8.82059 4.92709 6.21142C4.87311 6.0795 4.98299 5.93622 5.12023 5.97474C6.30699 6.30779 7.08023 7.2865 7.35738 8.44192C7.38998 8.57784 7.55696 8.63474 7.65212 8.53235C8.90592 7.18325 9.2165 4.95756 8.74381 3.23253Z"
      fill={color}
    />
  </svg>
)

export default PremiumIcon
