import { BoxProps, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import { actionSelectorState, FilterType } from '../../context/action-selector-context'

import { HBox, WhiteBox } from '../../elements/basic-elements'

import { CandidateCategories } from '../../async/sanity/utils'

import {
  ActionFormFragments,
  BelowForm,
  FormContainer,
  RemainingSpaceContainer,
  SuggestionsAndFiltersPortal,
} from '../../styles/styled-components/action-selector'
import AdditionalFilters from './additional-filters'
import CandidateEndorsementSelect from './candidate-endorsement-select'
import CandidateIssuesSelect from './candidate-issues-select'
import CandidateSelect from './candidate-select'
import ClearButton from './clear-button'
import LocationInput from './location-input'
import NounSelect from './noun-select'
import SearchButton from './search-button'
import VerbSelect from './verb-select'
import FilterSelect from './filter-select'
import ImpactScoreGlobes from '../impact-score-globes'
import { InputOption } from './action-input'

interface ActionSelectorProps {
  resultsOffset?: number
  onSearch?: () => void
}

const ActionSelector: React.FC<BoxProps & ActionSelectorProps> = props => {
  const { resultsOffset, onSearch, ...rest } = props
  const portal = React.useRef<HTMLDivElement>()

  const {
    error,
    optionsVerbs,
    selectedVerb,
    selectedNoun,
    selectedCandidateCategory,
    showAddress,
    showAdditionalFilters,
    isSearchingCandidates,
    fetchVerbs,
    activeFilters,
    matchingParams
  } = actionSelectorState()

  useEffect(() => {
    if (!optionsVerbs && fetchVerbs) {
      fetchVerbs()
    }
  }, [optionsVerbs, fetchVerbs])

  const formError = error
  const filterComponents = {
    [FilterType.COBENEFITS]: <FilterSelect suggestedSearchResultsPortal={portal} filterType={FilterType.COBENEFITS} label='co-benefits' matchingOptions={matchingParams?.cobenefits} />,
    [FilterType.SKILLS]: <FilterSelect suggestedSearchResultsPortal={portal} filterType={FilterType.SKILLS} label='skills' matchingOptions={matchingParams?.skills} />,
    [FilterType.MEDIUMS]: <FilterSelect suggestedSearchResultsPortal={portal} filterType={FilterType.MEDIUMS} label='mediums' matchingOptions={matchingParams?.mediums} />,
    [FilterType.IMPACT]: <FilterSelect suggestedSearchResultsPortal={portal} filterType={FilterType.IMPACT} label='impact' matchingOptions={matchingParams?.impact} renderOption={(option: InputOption) => (<ImpactScoreGlobes rank={Number(option.slug)} />)} sortOptions={false} />
  };

  return (
    <FormContainer {...rest}>
      <WhiteBox sx={{ p: 1, width: '100%' }}>
        <ActionFormFragments>
          {/* VERB Input */}
          <VerbSelect suggestedSearchResultsPortal={portal} />

          {/* NOUN Input */}
          {selectedVerb && <NounSelect suggestedSearchResultsPortal={portal} />}

          {/* Candidate Category Input */}
          {isSearchingCandidates && <CandidateSelect suggestedSearchResultsPortal={portal} />}

          {/* Candidate Endorsements Input */}
          {selectedCandidateCategory === CandidateCategories.ARE_ENDORSED_BY && (
            <CandidateEndorsementSelect suggestedSearchResultsPortal={portal} />
          )}

          {/* Candidate Endorsements Input */}
          {selectedCandidateCategory === CandidateCategories.SUPPORT && (
            <CandidateIssuesSelect suggestedSearchResultsPortal={portal} />
          )}

          {showAddress && selectedNoun && <LocationInput />}
        </ActionFormFragments>
      </WhiteBox>
      {activeFilters.map(filter => filterComponents[filter])}
      <BelowForm>
        {formError && (
          <Typography variant="body1" color="error">
            {formError}
          </Typography>
        )}

        <RemainingSpaceContainer offset={resultsOffset} minHeight={'108px'}>
          <SuggestionsAndFiltersPortal ref={portal}>
            {showAdditionalFilters && <AdditionalFilters />}
          </SuggestionsAndFiltersPortal>
        </RemainingSpaceContainer>
      </BelowForm>

      <HBox gap={1}>
        <ClearButton />
        <SearchButton onClick={onSearch} />
      </HBox>
    </FormContainer>
  )
}

export default ActionSelector
