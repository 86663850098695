import { IosShare } from '@mui/icons-material'
import { Box, IconButton, Tooltip } from '@mui/material'
import * as React from 'react'
import { triggerShare } from '../utilities/browser'
import TooltipTitle from './tooltip-title'

let showTextTimeout
export const ShareButtonIcon = ({ text }: { text?: string }) => {
  const [showCopiedText, setShowCopiedText] = React.useState(false)
  const copyRef = React.createRef<HTMLDivElement>()
  React.useEffect(() => {
    showTextTimeout = setTimeout(() => setShowCopiedText(false), 2000)
    return () => {
      clearTimeout(showTextTimeout)
    }
  }, [showCopiedText])

  return (
    <Tooltip
      placement="top"
      title={<TooltipTitle description={`Link copied`} />}
      PopperProps={{
        open: showCopiedText
      }}
      disableHoverListener
      sx={{
        display: 'flex',
        alignItems: 'center'
      }}
    >
      <Box>
        <div ref={copyRef}></div>
        <IconButton
          color="secondary"
          onClick={async () => {
            const copied = await triggerShare(text, copyRef.current || undefined)
            if (copied) {
              setShowCopiedText(true)
            }
          }}
        >
          <IosShare />
        </IconButton>
      </Box>
    </Tooltip>
  )
}
