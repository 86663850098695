import { Typography } from '@mui/material'
import React, { PropsWithChildren } from 'react'
import { VBox } from '../elements/basic-elements'

export type IconLabelProps = {
  label?: string
  icon: React.JSX.Element
}
const SquareButtonWithIcon: React.FC<PropsWithChildren<IconLabelProps>> = ({
  children,
  label,
  icon
}) => {
  return (
    <VBox gap={0.5} alignItems="center">
      {icon}
      {label && (
        <Typography fontWeight={500} fontSize={12} color="textPrimary">
          {label}
        </Typography>
      )}
      {children}
    </VBox>
  )
}

export default SquareButtonWithIcon
