import React, { MouseEventHandler } from 'react'
import { ArrowRight } from '@mui/icons-material'
import { Button, Typography, styled } from '@mui/material'
import { T3 } from '../../elements/text-elements'
import RepresentativePhoto from './representative-photo'
import { HBox, VBox } from '../../elements/basic-elements'
import { colors } from '../../constants/colors'

const RepresentativeButton: React.FC<{
  name: string
  title: string
  photo?: string
  onClick?: MouseEventHandler<HTMLButtonElement>
}> = ({ name, title, photo, onClick }) => {
  return (
    <StyledButton
      color={'secondary'}
      variant="contained"
      disableElevation
      endIcon={<ArrowRight />}
      onClick={onClick}
    >
      <HBox flex={1} alignItems="center" gap={1}>
        <RepresentativePhoto size={6} photo={photo} />
        <VBox alignItems="flex-start">
          <Typography variant="body1" fontWeight="bold" color={'textPrimary'}>
            {name}
          </Typography>
          <T3 variant="body2" color={'textPrimary'}>
            {title}
          </T3>
        </VBox>
      </HBox>
    </StyledButton>
  )
}

const StyledButton = styled(Button)`
  ${({ theme }) => `
    padding: ${theme.spacing(1)} ${theme.spacing(2)} ${theme.spacing(1)} ${theme.spacing(1)};
    background: ${
      theme.palette.mode == 'dark' ? colors.DARK_MODE_50 : colors.GLACIER_BLUE_70
    }!important;
  `}
`

export default RepresentativeButton
