import { Badge, Box, styled, Typography, useTheme } from '@mui/material'
import { Link, navigate } from 'gatsby'
import React, { useState } from 'react'
import { authState } from '../../../state/auth-state'
import { HBox, VBox, WhiteBox } from '../../../elements/basic-elements'
import WcidFull from '../../../images/icons/wcid_full'
import CenteredModal from '../../centered-modal'
import Confirmation from '../../confirmation'
import { useLocation } from 'react-router-dom'
import FaqIcon from '../../../images/icons/faq-icon'
import LogoutIcon from '../../../images/icons/logout-icon'
import MagGlassIcon from '../../../images/icons/mag-glass-icon'
import NewsletterIcon from '../../../images/icons/newsletter-icon'
import PodcastIcon from '../../../images/icons/podcast-icon'
import PremiumIcon from '../../../images/icons/premium-icon'
import ShopIcon from '../../../images/icons/shop-icon'
import profilePlaceholder from '../../../images/profile-placeholder.png'
import IconAndLabelButton from '../../icon-and-label-button'
import { Avatar } from '../../user-avatar'
import FullScreenModal from '../../full-screen-modal'
import { MODAL_WIDTH_MEDIUM } from '../../../constants/sizes'
import ActionSelector from '../../action-selector'
import useSearchModal from '../../../hooks/useSearchModal'
import BecomeImportantButton from '../become-important-button'
import StandardButton from '../../buttons/standard-button'
import LoadingShimmer from '../../loading-shimmer'
import { colors } from '../../../constants/colors'

const HeaderDesktop = () => {
  const { account, accountLoading, logout } = authState()
  const theme = useTheme()
  const image = account?.user?.profile.public.avatar
  const userName = account?.user?.profile.public.name
  const premium = account?.premium_access

  const location = useLocation()

  // -------------------------------------------
  // search modal
  // -------------------------------------------

  const searchModalProps = useSearchModal()

  // -------------------------------------------
  // confirm logout modal
  // -------------------------------------------

  const [logoutModalOpen, setLogoutModalOpen] = useState(false)
  const confirmLogout = () => {
    logout()
    hideLogoutModal()
  }
  const hideLogoutModal = () => {
    setLogoutModalOpen(false)
  }

  return (
    <Box width="100%">
      <HBox gap={2} width="100%" alignItems="flex-start">
        <VBox gap={0.5} justifyContent="flex-start" flex={1}>
          <LogoLink
            to="/"
            activeClassName="active"
            sx={{
              width: 165
            }}
          >
            <WcidFull />
          </LogoLink>
          <Typography
            ml={0.5}
            fontWeight={700}
            color="textPrimary"
            textTransform="uppercase"
            fontSize={10}
            component="a"
            href="https://www.importantnotimportant.com/"
            target="_blank"
            sx={{ textDecoration: 'none' }}
          >
            <Typography component="span" fontWeight={400} fontSize={10}>
              By
            </Typography>{' '}
            Important, Not Important
          </Typography>
        </VBox>
        <HBox gap={1} justifyContent="space-between" alignItems="flex-start">
          {location.pathname !== '/' && (
            <WhiteBox p={0.25} height={64}>
              <IconAndLabelButton
                color="secondary"
                label="Search"
                icon={<MagGlassIcon size={17} />}
                sx={{ height: '100%', p: 1.5, pb: 1.25 }}
                onClick={() => searchModalProps.setModalOpen(true)}
              />
            </WhiteBox>
          )}
          <WhiteBox p={0}>
            <HBox p={0.25} gap={0.25} height={64}>
              {/* <IconAndLabelButton
                color="secondary"
                label="Actions"
                icon={<LightningIcon size={17} />}
              /> */}
              <IconAndLabelButton
                color="secondary"
                label="Shop"
                icon={<ShopIcon size={17} />}
                component="a"
                href="https://shop.importantnotimportant.com/"
                target="_blank"
              />
              <IconAndLabelButton
                color="secondary"
                label="Podcast"
                icon={<PodcastIcon size={17} />}
                component={'a'}
                href="https://podcast.importantnotimportant.com/"
                target="_blank"
              />
              <IconAndLabelButton
                color="secondary"
                label="Newsletter"
                icon={<NewsletterIcon size={17} />}
                component={'a'}
                href="https://www.importantnotimportant.com/subscribe"
                target="_blank"
              />
              <IconAndLabelButton
                color="secondary"
                label="FAQ"
                icon={<FaqIcon size={17} />}
                component="a"
                href="/faq"
              />
            </HBox>
          </WhiteBox>
        </HBox>
        <HBox gap={1} flex={1} justifyContent="flex-end" height={64}>
          {accountLoading ? (
            <LoadingShimmer
              borderRadius='15px'
              width='200px'
              height='64px'
              customColors={theme.palette.mode === 'light' ? { base: colors.BLACK_10, highlight: colors.WHITE } : null} speed={2} />
          ) :
            (
              <>
                {!premium && <BecomeImportantButton />}
                {account &&
                  <StandardButton
                    color="tertiary"
                    variant='contained'
                    disableElevation
                    sx={{ padding: 0.5 }}
                    onClick={() => { 
                      navigate(account?.user_id ? `/profile/${account?.user_id}` : '/profile')
                    }}
                >
                  <HBox gap={1.5} alignItems="center">
                    <Box>
                      <Badge badgeContent={premium ? <PremiumIcon /> : 0}>
                        <Avatar src={image || profilePlaceholder} size={56} />
                      </Badge>
                    </Box>
                    {userName && <Typography
                      color="textPrimary"
                      fontSize={14}
                      textAlign="left"
                        sx={{ pr: 1, whiteSpace: userName && userName?.length > 16 ? 'initial' : 'nowrap' }}
                    >
                      {userName.split(' ')[0]}
                    </Typography>}
                  </HBox>
                  </StandardButton>
                }
                <StandardButton
                  variant='contained'
                  color="tertiary"
                  disableElevation
                  onClick={() => { account ? setLogoutModalOpen(true) : navigate('/login') }}
                >
                  {account ? (
                    <LogoutIcon />
                  ) : (
                    <Typography sx={{ padding: "0px 12px" }} fontWeight={500}>
                      Log In
                    </Typography>
                  )}
                </StandardButton>
              </>
            )}
        </HBox>
      </HBox>
      <CenteredModal open={logoutModalOpen} onClose={hideLogoutModal}>
        <Confirmation
          onDeny={hideLogoutModal}
          onConfirm={confirmLogout}
          title={`Confirm Logout`}
          description="Are you sure you want to logout from WCID?"
          denyText="oops, no"
          confirmText="yes"
        />
      </CenteredModal>
      <FullScreenModal
        open={Boolean(searchModalProps.modalOpen)}
        size={MODAL_WIDTH_MEDIUM}
        onClose={() => searchModalProps.setModalOpen(false)}
      >
        <VBox p={2} flex={1}>
          <ActionSelector
            resultsOffset={2}
            sx={{ flex: 1 }}
            onSearch={() => searchModalProps.setModalOpen(false)}
          />
        </VBox>
      </FullScreenModal>
    </Box>
  )
}

const StyledLink = styled(Link)`
  text-decoration: none;
`

const LogoLink = styled(StyledLink)`
  display: flex;
  align-items: center;
  position: relative;
`

export default HeaderDesktop
