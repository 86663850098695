import { Box, Button, styled, Theme } from '@mui/material'
import { colors } from '../constants/colors'
import { T3 } from './text-elements'
import { PillSmall } from '../styles/styled-components/inputs/pills'

export const VBox = styled(Box)`
  display: flex;
  flex-direction: column;
  max-width: 100%;
`
export const HBox = styled(Box)`
  display: flex;
  flex-direction: row;
`

export const PageContentContainer = styled(VBox)`
  ${({ padding, theme }) => {
    return `
      width: 100%;
      padding: ${padding !== undefined ? padding : `0 ${theme.spacing(3)}`};
      ${theme.breakpoints.down('sm')} {
        padding: 0 ${theme.spacing(2) };
      }
    `
  }}
`

export const HeightBox = styled(Box)`
  display: block;
  flex: 1;
  width: 100%;
  height: 0; // idea here is that it's parent flex column will stretch this to be full height
`

export const CoverBox = styled(Box)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

export const CenteredBox = styled(Box)<{ alignment?: 'center' | 'left' }>`
  ${({ alignment = 'center' }) => `
    position: absolute;
    left: ${alignment == 'center' ? '50%' : '0'};
    top: ${alignment == 'center' ? '50%' : '0'};
    transform: translate(-50%, -50%);
  `}
`

export const WhiteBox = styled(Box)`
  ${({ p, borderRadius, theme }) => {
    return `
      background: ${theme.palette.tertiary.main};
      padding: ${p !== undefined ? (typeof p === 'number' ? theme.spacing(p) : p) : theme.spacing(2)};
      border-radius: ${`${borderRadius || theme.shape.borderRadius}px`} ;
  `
  }}
`

export const GrayBox = styled(PillSmall)`
  ${({ theme }) => `
    background: ${theme.palette.mode == 'dark' ? colors.DARK_MODE_70 : colors.GLACIER_BLUE_70};
    color: ${theme.palette.mode == 'dark' ? colors.WHITE : colors.BLACK_100};
  `}
`

export const BlueBox = styled(PillSmall)`
  ${({ theme }) => `
    background: ${theme.palette.mode == 'dark' ? colors.DARK_MODE_50 : colors.GEORGIAN_BAY_10};
  `}
`

export const SquareButton = styled(Button)`
  width: 64px;
  height: 64px;
  ${({ theme }) => `
     ${theme.breakpoints.down('sm')} {
      width: 48px;
      height: 48px;
      padding: 12px;
      border-radius: 10px;
    }
  `}
`

export const SmallButton = styled(Button)`
  ${({ theme }) => `
    font-size: 13px;
    padding: ${theme.spacing(1)} ${theme.spacing(2)};
  `}
`

export const GrayT3 = styled(T3)`
  display: inline-flex;
  align-items: center;
  ${({ theme }) => `
    gap: ${theme.spacing(0.5)};
    color: ${theme.palette.mode == 'dark' ? colors.DARK_MODE_10 : colors.BLACK_50};
  `}
`

export const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1
})

export const OverflowInvisibleScroll = styled(Box)`
  overflow: scroll;
  height: 100%;

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
`

export const PageContent = styled('main')`
  min-height: 100%;
  width: 100%;
  display: flex;
  ${({ theme }) => `
    padding: ${theme.spacing(2)};
  `}
`

export const DEFAULT_PATH_COLOR = 'default'

export const getColorForPath = (theme: Theme, color?: string) => {
  if (color == DEFAULT_PATH_COLOR) {
    return theme.palette.mode == 'dark' ? colors.DARK_MODE_10 : colors.BLACK_100
  }

  return color != null ? color : 'none'
}

export const IconPath = styled('path')<{
  stroke?: string
  fill?: string
}>`
  ${({ strokeLinecap, fill, strokeWidth, strokeLinejoin, vectorEffect, stroke, theme }) => `
  stroke-width: ${strokeWidth || 1.5};
  stroke-linecap: ${strokeLinecap || 'round'};
  stroke-linejoin: ${strokeLinejoin || 'round'};
  vector-effect: ${vectorEffect || 'non-scaling-stroke'};
  stroke: ${getColorForPath(theme, stroke)};
  fill: ${getColorForPath(theme, fill)};
`}
`
