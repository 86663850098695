import { ActionFilters } from '../../types/filter-types'

import { attempt } from '../utils'
import { fetchNetlify } from '../netlify'
import { ActionToolAccountWithId } from '../../types/firebase-types'
import { SanitySuggestedActionResult } from '../../types/sanity-types'
import { removeDuplicates } from '../../utilities/array'

export const fetchActions = async (slugs: string[]) => {
  return await fetchNetlify<Queries.SanityAction[]>(
    `/.netlify/functions/sanity-actions-by-slug?slugs=${slugs.join(',')}`
  )
}

export const fetchFilters = async (
  existing: ActionFilters = {},
  backgroundUpdate: (newFilters: ActionFilters) => void
) => {
  // NOTE: breaking each filter into separate request to prevent netlify
  // function timeouts
  const getFilter = async (filter: string) =>
    await attempt(
      async () => {
        const data = await fetchNetlify<ActionFilters>(
          `/.netlify/functions/sanity-filters?${filter}=true`
        )
        return data
      },
      err => console.log(err || `Could not fetch ${filter} filters`)
    )

  let mutations = { ...existing }
  const updateInBackground = (filters?: ActionFilters) => {
    mutations = { ...mutations, ...filters }
    backgroundUpdate(mutations)
  }
  await Promise.all([
    updateInBackground({ nouns: (await getFilter('nouns'))?.nouns || [] }),
    updateInBackground({ verbs: (await getFilter('verbs'))?.verbs || [] }),
    updateInBackground({ cobenefits: (await getFilter('cobenefits'))?.cobenefits || [] }),
    updateInBackground({ mediums: (await getFilter('mediums'))?.mediums || [] }),
    updateInBackground({ skills: (await getFilter('skills'))?.skills || [] }),
    updateInBackground({ endorsements: (await getFilter('endorsements'))?.endorsements || [] }),
    updateInBackground({ issues: (await getFilter('issues'))?.issues || [] })
  ])
}

export const fetchOtherVerbs = async (
  category: Queries.SanityCategory
): Promise<Queries.SanityVerb[] | undefined> => {
  const query = `category=${category.slug?.current}`
  return await fetchNetlify<Queries.SanityVerb[]>(
    `/.netlify/functions/sanity-other-verbs-than-category?${query}`
  )
}

export const fetchVerbsRelatedToNoun = async (
  nouns: Queries.SanityNoun[],
  category: Queries.SanityCategory
) => {
  const nounSlugs = nouns.map(noun => noun.slug?.current) as string[]
  const query = `nouns=${nounSlugs.join(',')}&category=${category.slug?.current}`
  return await fetchNetlify<Queries.SanityVerb[]>(
    `/.netlify/functions/sanity-related-verbs-to-noun?${query}`
  )
}

export const fetchRecentActions = async (count?: number, shuffle?: boolean) => {
  return await fetchNetlify<SanitySuggestedActionResult[]>(
    `/.netlify/functions/sanity-recent-actions?count=${count}&shuffle=${shuffle}`
  )
}

export const hasDataForPersonalizedActions = (account: ActionToolAccountWithId) => {
  if (!account?.user?.profile?.private) {
    return false
  }

  const { verbs, skills, cobenefits, nouns } = account.user.profile.private
  return [verbs, skills, cobenefits, nouns].some(
    (arr) => Array.isArray(arr) && arr.length > 0
  )
}

export const fetchPersonalizedActions = async (count: number, account: ActionToolAccountWithId) => {
  const verbs = account?.user?.profile.private.verbs?.join(',')

  let categories: string | undefined
  if (verbs) {
    const categoryResults = await fetchNetlify<string[]>(
      `/.netlify/functions/categories-from-verbs?${encodeURIComponent(`verbs=${verbs}`)}`
    )
    categories = categoryResults?.join(',')
  }

  const skills = account?.user?.profile.private.skills?.join(',')

  let cobenefits = account?.user?.profile.private.cobenefits?.join(',')
  if (cobenefits) {
    cobenefits = (
      await fetchNetlify<string[]>(
        `/.netlify/functions/sanity-cobenefits-from-titles?cobenefits=${encodeURIComponent(cobenefits)}`
      )
    )?.join(',')
  }

  const nouns = account?.user?.profile.private.nouns?.join(',')
  const actionsToExclude = removeDuplicates([
    ...(account?.user?.action_history?.map(action => action.action_slug) || []),
    ...(account?.user?.saved_actions?.map(slug => slug) || [])
  ]).join(',')

  const slugs = await fetchNetlify<string[]>(
    `/.netlify/functions/firebase-query-actions?${[
      actionsToExclude && `actionsToExclude=${encodeURIComponent(actionsToExclude)}`,
      categories && `categories=${encodeURIComponent(categories)}`,
      cobenefits && `cobenefits=${encodeURIComponent(cobenefits)}`,
      count && `count=${encodeURIComponent(count)}`,
      nouns && `nouns=${encodeURIComponent(nouns)}`,
      skills && `skills=${encodeURIComponent(skills)}`
    ]
      .filter(exists => !!exists)
      .join('&')}`
  )

  return await fetchNetlify<SanitySuggestedActionResult[]>(
    `/.netlify/functions/sanity-personalized-actions?${[
      categories && `categories=${encodeURIComponent(categories)}`,
      cobenefits && `cobenefits=${encodeURIComponent(cobenefits)}`,
      count && `count=${encodeURIComponent(count)}`,
      nouns && `nouns=${encodeURIComponent(nouns)}`,
      skills && `skills=${encodeURIComponent(skills)}`,
      slugs && `slugs=${encodeURIComponent(slugs.join(','))}`,
      verbs && `verbs=${encodeURIComponent(verbs)}`
    ]
      .filter(exists => !!exists)
      .join('&')}`
  )
}
