import * as React from 'react'
import Snackbar from '@mui/material/Snackbar'

import { styled } from '@mui/material'

type ToastAlertTypes = 'error' | 'success'
export default function ToastAlert({
  message,
  type,
  open,
  onClose,
}: {
  message?: string
  type?: ToastAlertTypes
  open?: boolean
    onClose?: Function
}) {
  return (
    <StyledSnackbar
      open={open}
      type={type}
      autoHideDuration={6000}
      onClose={() => onClose && onClose()}
      message={message}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      sx={{ zIndex: theme => theme.zIndex.snackbar }}
    />
  )
}

const StyledSnackbar = styled(Snackbar)<{ type?: ToastAlertTypes }>`
  ${({ theme, type }) => `
    max-width: 300px;
    .MuiPaper-root {

      background: ${type == 'error' ? theme.palette.error.main : theme.palette.success.main};
      color: ${theme.palette.getContrastText(type == 'error' ? theme.palette.error.main : theme.palette.success.main)};
    }
  `}
`
