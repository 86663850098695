import Stripe from 'stripe'
import { fetchNetlify } from '../netlify'

export const getCustomerFromEmail = async (email: string) =>
  await fetchNetlify<{ customer: Stripe.Customer }>(
    `/.netlify/functions/stripe-get-customer?email=${email}`
  )

export const testVerification = async (email: string, name: string) =>
  await fetchNetlify(`/.netlify/functions/stripe-verification-test?email=${email}&name=${name}`)

export const checkVerification = async (session_id: string) => {
  await fetchNetlify(
    `/.netlify/functions/stripe-check-verification-session?session_id=${session_id}`
  )
}

export const cancelTest = async (email: string) =>
  await fetchNetlify(`/.netlify/functions/stripe-verification-cancel-test?email=${email}`)

export const createStripeVerificationSession = async (wcid_user_id: string) =>
  await fetchNetlify<string>(
    `/.netlify/functions/stripe-create-verification-session?wcid_user_id=${wcid_user_id}`
  )

export const cancelStripeVerificationSession = async (session_id: string) =>
  await fetchNetlify(
    `/.netlify/functions/stripe-cancel-verification-session?session_id=${session_id}`
  )

export const lookupStripeCustomerByCustomerId = async (customer_id?: string) =>
  await fetchNetlify<Stripe.Customer>(
    `/.netlify/functions/stripe-get-customer-by-id?customer_id=${customer_id}`
  )

export const lookupStripeSubscriptionByCustomer = async (id: string) =>
  await fetchNetlify<Stripe.Subscription>(
    `/.netlify/functions/stripe-get-customer-subscription?customer_id=${id}`,
    { allowedErrorCodes: [404] }
  )

export const createStripeCustomer = async (email: string) =>
  await fetchNetlify<Stripe.Customer>(`/.netlify/functions/stripe-create-customer?email=${email}`)

export const createStripePremiumSubscriptionIntent = async (stripe_customer_id: string) =>
  await fetchNetlify<{ clientSecret: string }>(
    `/.netlify/functions/stripe-create-subscription-intent?customer_id=${stripe_customer_id}`
  )

export const retrieveStripePremiumSubscriptionIntent = async (intent_id: string) =>
  await fetchNetlify<{ setupIntent: Stripe.SetupIntent }>(
    `/.netlify/functions/stripe-retrieve-subscription-intent?intent_id=${intent_id}`
  )

export const createStripePremiumSubscription = async (
  customer_id: string,
  payment_id: string,
  yearly: boolean,
  existing_subscription_id?: string
) =>
  await fetchNetlify<{ subscription_id: string }>(
    `/.netlify/functions/stripe-create-premium-subscription?customer_id=${customer_id}&payment_id=${payment_id}&existing_subscription_id=${existing_subscription_id}${
      yearly ? '&yearly=true' : ''
    }`
  )

export const checkStripeVerifiedName = async (session_id?: string) =>
  await fetchNetlify<{ name: string }>(
    `/.netlify/functions/stripe-get-verified-name?session_id=${session_id}`
  )
