import { Button, ButtonProps, Typography } from '@mui/material'
import React from 'react'
import { WhiteBox } from '../../elements/basic-elements'

const WhiteButton: React.FC<ButtonProps & { boxPadding?: number }> = ({
  children,
  boxPadding,
  ...rest
}) => {
  return (
    <WhiteBox height="100%" sx={{ p: theme => theme.spacing(boxPadding || 0) }}>
      <Button color="secondary" {...rest}>
        <Typography color="textPrimary" fontWeight={500}>
          {children}
        </Typography>
      </Button>
    </WhiteBox>
  )
}

export default WhiteButton
