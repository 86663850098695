import { Box } from "@mui/material"
import React from "react"
import { HBox } from "../elements/basic-elements"
import { T3 } from "../elements/text-elements"
import GlobeIcon from "../images/icons/globe-icon"
import { createEmptyArray } from "../utilities/array"

const HIGHEST_RANK = 3

interface ImpactScoreGlobesProps { rank?: number | null, includeLabel?: boolean }

const ImpactScoreGlobes = (props: ImpactScoreGlobesProps) => {
    const actingRank = props.rank != null ? props.rank : 0
    return (
        <HBox gap="5px">
            {props.includeLabel && <T3 variant="body2" color="textPrimary"> Impact Score: </T3>}
            <HBox alignItems="center">
                {createEmptyArray(actingRank).map((_, i) => (
                    <GlobeIcon key={i} />
                ))}
                {createEmptyArray(HIGHEST_RANK - actingRank).map((_, i) => (
                    <Box sx={{ opacity: 0.25 }} key={i}>
                        <GlobeIcon />
                    </Box>
                ))}
            </HBox>
        </HBox>
    )

}

export default ImpactScoreGlobes