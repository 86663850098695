import { User, onAuthStateChanged, signOut } from 'firebase/auth'
import * as React from 'react'
import { PropsWithChildren, useContext } from 'react'
import { ActionToolAccountWithId, UserActionHistory } from '../types/firebase-types'
import { auth } from '../async/firebase/config'
import { attempt } from '../async/utils'
import {
  getActionHistory,
  getFavorites,
  PartialUserActionHistory,
  toggleActionTaken,
  toggleFavorite
} from '../utilities/account'
import { redirect } from '../utilities/routing'
import { handleAuthUser } from '../utilities/auth'
import { handleFirebaseRedirectUrl, isFirebaseRedirectUrl } from '../async/firebase/utils'

export const AuthContext = React.createContext<{
  authUser?: User | null
  authUserLoading?: boolean
  account?: ActionToolAccountWithId
  accountLoading?: boolean
  isUpdatingUser?: boolean
  authRedirect?: string
  ignorePremiumRedirect?: boolean
  favorites?: string[] | null
  actionsTaken?: (UserActionHistory | PartialUserActionHistory)[] | null
  setAccount: (account: ActionToolAccountWithId) => void
  setIsUpdatingUser: (loading: boolean) => void
  setAuthRedirect: (route?: string) => void
  setIgnorePremiumRedirect: (ignore: boolean) => void
  updateFavorite: (slug: string, favorited: boolean) => void
  updateActionTaken: (slug: string, action_taken: boolean) => void
  logout: () => void
}>({
  authUser: null,
  setAccount: () => {},
  setIsUpdatingUser: () => { },
  setAuthRedirect: () => {},
  setIgnorePremiumRedirect: () => {},
  updateFavorite: async () => {},
  updateActionTaken: async () => {},
  logout: () => {}
})

export const AuthProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [isUpdatingUser, setIsUpdatingUser] = React.useState(false)
  const [authRedirect, setAuthRedirect] = React.useState<string>()
  const [handledPotentialLogIn, setHandledPotentialLogIn] = React.useState<boolean>(false)
  const [ignorePremiumRedirect, setIgnorePremiumRedirect] = React.useState(false)
  const [authUser, setAuthUser] = React.useState(auth?.currentUser || undefined)
  const [authUserLoading, setAuthUserLoading] = React.useState(true)
  const [account, setAccount] = React.useState<ActionToolAccountWithId>()
  const [accountLoading, setAccountLoading] = React.useState<boolean>(true)
  const [favorites, setFavorites] = React.useState(getFavorites(account))
  const [actionsTaken, setActionsTaken] = React.useState(getActionHistory(account))

  React.useEffect(() => {
    const attemptLoginIfApplicable = async () => {
      if (isFirebaseRedirectUrl()) {
        // if user is coming from a verification email, try and authenticate them
        try {
          await handleFirebaseRedirectUrl()
        } catch {
          await redirect('/access-account')
        } finally {
          setHandledPotentialLogIn(true)
        }
      }

      setHandledPotentialLogIn(true)
    }

    attemptLoginIfApplicable()
  }, [])

  React.useEffect(() => {
    if (handledPotentialLogIn) {
      // when we auth gate a page, we often set a redirect to point the user
      // to when they become authenticated; this manages that process
      const unsubscribe = onAuthStateChanged(auth, async authUser => {
        if (authUser == null) {
          setAccountLoading(false)
        }

        setAuthUser(authUser || undefined)
        setAuthUserLoading(false)
        if (authUser && authUser.emailVerified && authRedirect) {
          redirect(authRedirect)
          setAuthRedirect(undefined)
        }
      })

      return () => unsubscribe()
    }
  }, [authRedirect, handledPotentialLogIn])

  const onLoadedAccount = (loadedAccount: ActionToolAccountWithId) => {
    setAccount(loadedAccount)
    setAccountLoading(false)
  }

  React.useEffect(() => {
    const handle = async () => {
      // note: account data is automatically updated by firebase observer
      // created during this method
      await attempt(
        async () => {
          await handleAuthUser(authUser, onLoadedAccount)
        },
        err => {
          // if an error during auth occurs, log out the user
          alert(
            'An error occured while authenticating your login credentials: ' +
              (err || '') +
              'You will be logged out as a result; please try logging in again.'
          )
          logout()
        }
      )
    }
    handle()
  }, [authUser])

  const updateFavorite = React.useCallback(
    async (slug: string, favorited: boolean) => {
      setIsUpdatingUser(true)
      setFavorites((await toggleFavorite(slug, favorited, account)) || [])
      setIsUpdatingUser(false)
    },
    [account]
  )

  const updateActionTaken = React.useCallback(
    async (slug: string, action_taken: boolean) => {
      setIsUpdatingUser(true)
      setActionsTaken((await toggleActionTaken(slug, action_taken, account)) || [])
      setIsUpdatingUser(false)
    },
    [account]
  )

  // update account favorites on login/out
  React.useEffect(() => {
    setFavorites(getFavorites(account))
    setActionsTaken(getActionHistory(account))
  }, [account])

  const logout = async () => {
    setIsUpdatingUser(true)
    await signOut(auth)
      .then(() => {
        setAccount(undefined)
        setAuthUser(undefined)
        setIsUpdatingUser(false)
        setActionsTaken([])
        setFavorites([])
        // Sign-out successful.
      })
      .catch(() => {
        // An error happened.
      })
    setIsUpdatingUser(false)
  }

  return (
    <AuthContext.Provider
      value={{
        account,
        authUser,
        authUserLoading,
        accountLoading,
        isUpdatingUser,
        authRedirect,
        favorites,
        ignorePremiumRedirect,
        actionsTaken,
        setAccount,
        setIsUpdatingUser,
        setAuthRedirect,
        setIgnorePremiumRedirect,
        updateFavorite,
        updateActionTaken,
        logout
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export const authState = () => useContext(AuthContext)
