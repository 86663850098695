import { ButtonProps, LinkProps } from '@mui/material'
import React from 'react'
import IconAndLabel, { IconLabelProps } from './icon-and-label'
import IconAndLabelHorizontal from './icon-and-label-horizontal'
import StandardButton from './buttons/standard-button'

export type IconAndLabelButtonProps = ButtonProps &
  IconLabelProps &
  Pick<LinkProps, 'target'> & { flexDirection?: 'row' | 'column' }

const IconAndLabelButton: React.FC<IconAndLabelButtonProps> = ({
  children,
  label,
  icon,
  flexDirection = 'column',
  ...rest
}) => {
  return (
    <StandardButton {...rest} >
      {flexDirection === 'row' ? (
        <IconAndLabelHorizontal label={label} icon={icon}>
          {children}
        </IconAndLabelHorizontal>
      ) : (
        <IconAndLabel label={label} icon={icon}>
          {children}
        </IconAndLabel>
      )}
    </StandardButton>
  )
}

export default IconAndLabelButton
