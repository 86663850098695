import React, { Fragment, ReactNode } from 'react'
import { Filters, getActionSentenceFromFilters } from '../utilities/sanity-filters'
import { addSentenceStructureToStringArray } from '../utilities/string'
import { Box, Typography, TypographyProps } from '@mui/material'

const ActionSentence = React.forwardRef(({
  beginning = 'I want to',
  placeholders = { noun: 'anything' },
  filters,
  ...rest
}: TypographyProps & {
  beginning?: ReactNode
  filters: Filters
  placeholders?: { verb?: ReactNode; noun?: ReactNode }
  }, ref) => {
  const {
    verbs: verbFragment,
    nouns: nounFragment,
    cobenefits: cobenefitFragment,
    skills: skillFragment,
    endorsements: endorsementFragment,
    issues: issueFragment
  } = getActionSentenceFromFilters(filters)
  const mediumSinglularTitles =
    filters.mediums && (filters.mediums?.map(({ title }) => title) as string[])
  const mediumPluralTitles =
    filters.mediums && (filters.mediums?.map(({ plural }) => plural) as string[])

  return (
    <Box ref={ref}>
    <Typography
      {...{ variant: 'h4', color: 'textPrimary', textAlign: 'left', lineHeight: '1.3' }}
      {...rest}
    >
      {verbFragment ? (
        <Fragment>
          {beginning} {verbFragment}{' '}
        </Fragment>
      ) : placeholders.verb ? (
        <Fragment>{placeholders.verb} </Fragment>
      ) : (
        `Show me ${mediumPluralTitles ? addSentenceStructureToStringArray(mediumPluralTitles, 'or') : 'everything'} about `
      )}
      {nounFragment || placeholders?.noun}
      {cobenefitFragment && <Fragment> specifically related to {cobenefitFragment}</Fragment>}
      {endorsementFragment && <Fragment> endorsed by {endorsementFragment}</Fragment>}
      {skillFragment && <Fragment> in need of {skillFragment} skills</Fragment>}
      {issueFragment && <Fragment> who/that support {issueFragment}</Fragment>}
      {filters.levels && filters.levels.length > 0 && (
        <Fragment> on a {addSentenceStructureToStringArray(filters.levels, 'or')} level</Fragment>
      )}
      {filters.locations && filters.locations.length > 0 && (
        <Fragment>
          {filters.expandLocations && ', particularly '} in{' '}
          {addSentenceStructureToStringArray(filters.locations, 'or')}
        </Fragment>
      )}
      {verbFragment && mediumSinglularTitles && (
        <Fragment>
          {' '}
          from a {addSentenceStructureToStringArray(mediumSinglularTitles, 'or')}
        </Fragment>
      )}
      .{rest.children}
    </Typography>
    </Box>
  )
})

export default ActionSentence
