import { getSanitySlug } from '../../async/sanity/utils'
import { InputOption } from './action-input'
import { SearchParamEntity } from './filter-select'

export const sortByTitleMatch = (inputValue) => (a, b) => {
  const { title: titleA } = a
  const { title: titleB } = b
  const titleAMatch = titleA && titleA.indexOf(inputValue) > -1
  const titleBMatch = titleB && titleB.indexOf(inputValue) > -1
  return titleAMatch && titleBMatch ? -titleB.localeCompare(titleA) : titleAMatch ? -1 : 1
}

export const filterOptionsByInput = (options: InputOption[], { inputValue }) => {
  const inputValueLc = inputValue.toLowerCase()
  const filtered = options.filter(option => {
    const { tags, title } = option
    const tagMatch = tags && tags.find(tag => tag?.toLowerCase().indexOf(inputValueLc) > -1)
    const titleMatch = inputValueLc && title?.toLowerCase().indexOf(inputValueLc) > -1
    return tagMatch || titleMatch
  })

  return inputValue === '' ? options : filtered
}

export const createVerbInputOption = ({ slug, title }: Queries.SanityVerb) => ({
  slug: getSanitySlug(slug) || title || '',
  title: title || ''
})

export const createNounOptionFrom = ({ slug, title }: Queries.SanityNoun) => {
  return {
    slug: getSanitySlug(slug) || title || '',
    title: title || ''
  }
}

export const createSearchParamOptionFromEntity = (entity: SearchParamEntity, matchingOptions: (string | number)[]) => {
  const optionSlug = 'slug' in entity ? getSanitySlug(entity.slug) : null
  const matchesCurrentSearch = matchingOptions?.find(option => {
    const fieldToCompare = optionSlug ? optionSlug : entity.title
    return typeof option === 'number'
      ? option.toString() === fieldToCompare
      : option?.toLowerCase() === fieldToCompare.toLowerCase()
  }) != null

  return {
    slug: optionSlug || entity.title || '',
    title: entity.title || '',
    emphasize: matchesCurrentSearch
  }
}
