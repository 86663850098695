import { Modal, Slide, styled, useTheme } from '@mui/material'
import React, { PropsWithChildren, useLayoutEffect, useState } from 'react'
import { colors } from '../constants/colors'
import { HeightBox, SquareButton, VBox } from '../elements/basic-elements'
import CloseIcon from '../images/icons/close-icon'
import { isInBrowser } from '../utilities/browser'

const PartialScreenModal: React.FC<
  PropsWithChildren & {
    open: boolean
    onClose: () => void
  }
> = ({ children, open, onClose }) => {
  const [slideDirection, setSlideDirection] = useState<'up' | 'left'>('up')
  const theme = useTheme()

  useLayoutEffect(() => {
    if (isInBrowser()) {
      const setDirection = () => {
        const { innerWidth } = window
        setSlideDirection(innerWidth > theme.breakpoints.values.sm ? 'left' : 'up')
      }
      setDirection()

      window.addEventListener('resize', setDirection)
      return () => window.removeEventListener('resize', setDirection)
    }
  }, [])
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={onClose}
      closeAfterTransition
      sx={{
        position: 'fixed',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        zIndex: theme => theme.zIndex.modal
      }}
    >
      <Slide direction={slideDirection} in={open} timeout={500} easing="cubic-bezier(0, 0, 0, 1)">
        <ModalContainer justifyContent="flex-end">
          <ModalContent>
            <SquareButton
              variant="contained"
              disableElevation
              color={theme.palette.mode == 'dark' ? 'tertiary' : 'info'}
              onClick={() => {
                onClose && onClose()
              }}
              sx={{ ml: 'auto' }}
            >
              <CloseIcon />
            </SquareButton>
            <HeightBox>{children}</HeightBox>
          </ModalContent>
        </ModalContainer>
      </Slide>
    </Modal>
  )
}

const ModalContainer = styled(VBox)`
  position: absolute;
  width: 100%;
  height: 100%;
  pointer-events: none;
  ${({ theme }) => {
    return `
      ${theme.breakpoints.up('sm')} {
          max-width: 430px;
          right: ${theme.spacing(2)};
          top: ${theme.spacing(2)};
          bottom: ${theme.spacing(2)};
          height: auto;
          border-radius: ${theme.shape.borderRadius}px;
      }
  `
  }}
`

const ModalContent = styled(VBox)`
  pointer-events: all;
  ${({ theme }) => `
        max-height: calc(100vh - 100px);
        overflow: scroll;
        padding: ${theme.spacing(2)};
        border-radius:
        ${theme.shape.borderRadius} ${theme.shape.borderRadius} 0 0;

        background: ${theme.palette.mode == 'dark' ? colors.DARK_MODE_80 : colors.BLACK_10};

        ${theme.breakpoints.up('sm')} {
            max-height: 100%;
            height: 100%;
            width: 100%;
            border-radius: ${theme.shape.borderRadius}px;
        }
    `}
`

export default PartialScreenModal
