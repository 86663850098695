import React from 'react'
import { Box, styled, useTheme } from '@mui/material'
import { colors } from '../constants/colors'
import { pageState } from '../context/page-context'
import PremiumIcon from '../images/icons/premium-icon'
import FeaturedIcon from '../images/icons/featured-icon'
import { NewReleases, TrendingUp } from '@mui/icons-material'

type IndicatorTagSize = 'small' | 'medium'
type IndicatorTagProps = {
  type: SuggestionType
  size: IndicatorTagSize
}
enum SuggestionType {
  FEATURED = 'featured',
  PERSONAL = 'personal',
  HOT = 'hot',
  NEW = 'new'
}

const SIZE_CONFIG_IN_PX = {
  small: {
    mobile: { icon: 21, tag: 36.5 },
    desktop: { icon: 24, tag: 50 }
  },
  medium: {
    mobile: { icon: 24, tag: 44 },
    desktop: { icon: 24, tag: 64 }
  }
}

interface SuggestionTagConfig {
  color: string
  colorDark: string
  icon: (iconSize: number) => React.JSX.Element
}

const suggestionTypeProps: Partial<Record<SuggestionType, SuggestionTagConfig>> = {
  new: {
    color: colors.RED_100,
    colorDark: colors.DARK_MODE_50,
    icon: (iconSize: number) => <NewReleases sx={{ color: colors.WHITE, fontSize: iconSize }} />
  },
  featured: {
    color: colors.RED_100,
    colorDark: colors.DARK_MODE_50,
    icon: (iconSize: number) => <FeaturedIcon color={colors.WHITE} size={iconSize} />
  },
  personal: {
    color: colors.RED_100,
    colorDark: colors.DARK_MODE_50,
    icon: (iconSize: number) => <PremiumIcon color={colors.WHITE} size={iconSize} />
  },
  hot: {
    color: colors.RED_100,
    colorDark: colors.DARK_MODE_50,
    icon: (iconSize: number) => <TrendingUp sx={{ color: colors.WHITE, fontSize: iconSize }} />
  }
}

const IndicatorTag = ({ size, type }: IndicatorTagProps) => {
  const { isMobile } = pageState()
  const theme = useTheme()
  const sizeConfig = isMobile ? SIZE_CONFIG_IN_PX[size].mobile : SIZE_CONFIG_IN_PX[size].desktop
  const { color, colorDark, icon } = suggestionTypeProps[type] || {}
  return (
    <IconContainer
      bgcolor={theme.palette.mode == 'dark' ? colorDark : color}
      width={sizeConfig.tag}
      height={sizeConfig.tag}
    >
      {icon && icon(sizeConfig.icon)}
    </IconContainer>
  )
}

const IconContainer = styled(Box)`
  display: flex;
  border-radius: 14px;
  ${({ theme }) => `
    ${theme.breakpoints.down('md')} {
      border-radius: 10px;
    }
  `}
  align-items: center;
  justify-content: center;
`

export default IndicatorTag
