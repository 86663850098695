import {
  Box,
  CircularProgress,
  Portal,
  Typography,
  useAutocomplete,
  UseAutocompleteProps,
  useTheme
} from '@mui/material'
import { styled } from '@mui/system'
import React, { useEffect, useRef, useState } from 'react'
import { colors } from '../../constants/colors'

import MagGlassIcon from '../../images/icons/mag-glass-icon'
import { ActionInputField } from '../../styles/styled-components/inputs/inputs'
import { isDarkMode, isInBrowser } from '../../utilities/browser'
import { usePrevious } from '../../utilities/react_hooks'
import Input from '../input'
import { ActionPill } from '../../styles/styled-components/inputs/pills'
import { filterOptionsByInput, sortByTitleMatch } from './utils'
import { ADDITIONAL_FILTERS_ID, ADDITIONAL_FILTERS_PADDING } from './additional-filters'

export interface InputOption {
  slug: string
  title: string
  emphasize?: boolean
  tags?: string[]
}

export interface ActionInputPropsBase {
  suggestedSearchResultsPortal: React.RefObject<HTMLDivElement>
}
interface InputProps extends ActionInputPropsBase {
  id: string
  options: InputOption[]
  sortOptions?: boolean
  value?: InputOption
  defaultValue?: string
  placeholder?: string
  loading?: boolean
  revealOptionsDelay?: number
  onValueChanged: (value: any) => void
  onIsEditingChanged?: (isEditing: boolean) => void
  onCloseClick?: () => void
  preventValueChange?: boolean
  renderOption?: (option: InputOption) => React.ReactNode
}

let renderGroupedOptionsTimeout: NodeJS.Timeout

let ignoreBlurSelect = false

const MIN_INPUT_WIDTH = 200

const ActionInput = (props: InputProps) => {
  const { id, revealOptionsDelay, options = [], renderOption, sortOptions = true } = props
  const [isEditing, setIsEditing] = useState(false)
  const [value, setValue] = useState(props.value)
  const [inputValue, setInputValue] = useState(props.value?.title || '')
  const [renderGroupedOptions, shouldRenderGroupedOptions] = useState(false)
  const [revealGroupedOptions, shouldRevealGroupedOptions] = useState(false)

  const [width, setWidth] = useState(MIN_INPUT_WIDTH)
  const theme = useTheme()
  const hiddenText = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (props.value?.slug !== value?.slug) {
      const newValue = props.value?.title
      setValue(props.value)
      setInputValue(newValue || '')
    }
  }, [props.value])

  // --------------------------------
  // Resize text boxes to actual text length
  // when input value changes
  // --------------------------------

  const setWidthToSelection = () => {
    requestAnimationFrame(() => {
      const el = hiddenText?.current

      if (el) {
        const elAsHtml = el as HTMLElement

        setWidth(elAsHtml.offsetWidth + 10)
      }
    })
  }

  const onInputValueChange = (value?: InputOption) => {
    const valueChanged = value != null && value !== props.value
    const currentValue = (!valueChanged && props.value) || value

    if (!props.preventValueChange) {
      ignoreBlurSelect = true
      setIsEditing(false)
      setInputValue(currentValue?.title || '')
      if (valueChanged) {
        setWidthToSelection()
        setValue(value)
      }
    }

    if (valueChanged && props.onValueChanged) {
      props.onValueChanged(currentValue)
    }
  }

  useEffect(() => {
    // resize textboxes when fonts have loaded

    requestAnimationFrame(() => {
      setWidthToSelection()
    })

    document.fonts.onloadingdone = function () {
      requestAnimationFrame(() => {
        setWidthToSelection()
      })
    }
    document.fonts.ready.then(function () {})
  }, [value, props.placeholder])

  // --------------------------------
  // --------------------------------
  // --------------------------------

  // --------------------------------
  // Trigger input option functionality
  // when user begins editing
  // --------------------------------

  useEffect(() => {
    if (isEditing) {
      ignoreBlurSelect = false
      setInputValue('')
    } else {
      shouldRenderGroupedOptions(false)
      shouldRevealGroupedOptions(false)
    }
    if (props.onIsEditingChanged) {
      props.onIsEditingChanged(isEditing)
    }
  }, [isEditing])

  // --------------------------------
  // --------------------------------
  // --------------------------------

  const config: UseAutocompleteProps<InputOption, false, false, false> = {
    id,
    options: sortOptions ? options?.sort(sortByTitleMatch(inputValue)) : options,
    blurOnSelect: true,
    inputValue: inputValue,
    open: isEditing,
    getOptionLabel: (option) => (option.title),
    isOptionEqualToValue: (option, value) => option.slug === value.slug,
    onChange: (_e, changedValue) => { onInputValueChange(changedValue) },
    filterOptions: filterOptionsByInput,
  }

  const { getRootProps, getInputProps, getListboxProps, getOptionProps, groupedOptions } =
    useAutocomplete(config)

  useEffect(() => {
    // Automatically focus the input field once options are loaded
    const inputFieldRef = getInputProps().ref as React.MutableRefObject<HTMLDivElement>
    if (!props.value && !props.loading && inputFieldRef?.current) {
      inputFieldRef.current.focus()
    }

  }, [props.loading])

  const rerenderGroupOptions = () => {
    shouldRenderGroupedOptions(false)
    shouldRevealGroupedOptions(false)
    requestAnimationFrame(() => {
      shouldRenderGroupedOptions(true)
    })
  }

  useEffect(() => {
    // Rerender group options when the window resizes to ensure desired layout
    // remains the same (all options visible in viewport, via horizontal scroll)
    if (isInBrowser()) {
      window.addEventListener('resize', rerenderGroupOptions)
    }
    return () => {
      // Clean up timeout if this component is dismounted
      clearTimeout(renderGroupedOptionsTimeout)
      window.removeEventListener('resize', rerenderGroupOptions)
    }
  }, [])

  // --------------------------------
  // --------------------------------
  // --------------------------------

  // --------------------------------
  // Trigger reveal of grouped options
  // only when options change
  // --------------------------------

  const prevOptions = usePrevious(groupedOptions)
  useEffect(() => {
    const newOptions =
      groupedOptions?.length > 0 &&
      (!prevOptions || JSON.stringify(groupedOptions) !== JSON.stringify(prevOptions))

    if (newOptions) {
      clearTimeout(renderGroupedOptionsTimeout)
      renderGroupedOptionsTimeout = setTimeout(
        () => shouldRenderGroupedOptions(true),
        revealOptionsDelay || 0
      )
    }
  }, [revealOptionsDelay, groupedOptions])

  // Make sure grouped options fits within viewport and if not, expand horizontally
  const suggestedInner = React.useRef<HTMLDivElement>()

  // --------------------------------
  // --------------------------------
  // --------------------------------

  // --------------------------------
  // Reveal grouped options after
  // renderGroupedOptions is set to true
  // --------------------------------

  useEffect(() => {
    if (renderGroupedOptions) {
      const innerIsTaller = () => {
        const filtersComponent: HTMLDivElement | null = props.suggestedSearchResultsPortal?.current?.querySelector(`#${ADDITIONAL_FILTERS_ID}`)
        const filtersHeight = filtersComponent?.offsetHeight
        const usedPortalHeight = filtersHeight != null ? filtersHeight + ADDITIONAL_FILTERS_PADDING : 0
        const portalHeight = props.suggestedSearchResultsPortal?.current?.offsetHeight
        const remainingPortalHeight = portalHeight ? portalHeight - usedPortalHeight : 0
        return (
          (suggestedInner?.current?.offsetHeight || 0) >
          (remainingPortalHeight)
        )
      }
      const fitIntoViewport = () => {
        if (suggestedInner.current) {
          const startTime = Date.now()

          while (Date.now() - startTime < 1000 && innerIsTaller()) {
            suggestedInner.current.style.width = suggestedInner?.current?.offsetWidth + 10 + 'px'
          }
          shouldRevealGroupedOptions(true)
        }
      }
      requestAnimationFrame(() => {
        // nested under RAF to make sure the right element sizes are used
        if (innerIsTaller()) {
          suggestedInner.current?.removeAttribute('style')
          requestAnimationFrame(fitIntoViewport)
        } else {
          shouldRevealGroupedOptions(true)
        }
      })
    }
  }, [renderGroupedOptions])

  // --------------------------------
  // --------------------------------
  // --------------------------------

  const inputProps = getInputProps()
  const placeholder = props.placeholder
  const isLoading = props.loading
  const darkmode = isDarkMode()

  return (
    <Input
      hasValue={Boolean(value)}
      onCloseClick={() => {
        if (props.onCloseClick) {
          props.onCloseClick()
        }

        const inputFieldRef = getInputProps().ref as React.MutableRefObject<HTMLDivElement>
        if (inputFieldRef.current) {
          inputFieldRef.current.focus()
        }
      }}
      background={theme.palette.mode == 'dark' ? colors.DARK_MODE_50 : colors.BLACK_10}
      inputIcon={<MagGlassIcon />}
    >
      {() => {
        return (
          <InputContainer sx={{ paddingTop: theme => theme.spacing(1) }}>
            <Box {...getRootProps()}>
              {isLoading && (
                <Loader>
                  <CircularProgress color={darkmode ? 'info' : 'secondary'} size={20} />
                </Loader>
              )}
              <Box
                sx={{
                  display: 'flex',
                  visibility: isLoading ? 'hidden' : 'visible'
                }}
              >
                <ActionInputField
                  {...getInputProps()}
                  className={hiddenText.current?.className}
                  style={{
                    maxWidth: value ? '100%' : width,
                    width: value ? width : MIN_INPUT_WIDTH,
                    flex: 1,
                    lineHeight: 1.3,
                    fontSize: 20,
                    fontWeight: 400,
                    textOverflow: 'ellipsis'
                  }}
                  placeholder={placeholder}
                  onFocus={(e: React.FocusEvent<HTMLInputElement, Element>) => {
                    inputProps.onFocus && inputProps.onFocus(e)
                    setIsEditing(true)
                  }}
                  onBlur={e => {
                    inputProps.onBlur && inputProps.onBlur(e)
                    setIsEditing(false)
                    if (!ignoreBlurSelect) {
                      if (inputValue !== '' && groupedOptions[0]) {
                        onInputValueChange(groupedOptions[0] as InputOption)
                      } else {
                        onInputValueChange()
                      }
                    }
                  }}
                  onInput={e => {
                    // @ts-expect-error: existing type issue
                    inputProps.onChange && inputProps.onChange(e)
                    // @ts-expect-error: existing type issue
                    setInputValue(e.target.value || '')

                    if (props.suggestedSearchResultsPortal.current) {
                      props.suggestedSearchResultsPortal.current.scrollTo(0, 0)
                    }
                  }}
                  onKeyDown={e => {
                    inputProps.onKeyDown && inputProps.onKeyDown(e)
                  }}
                />
              </Box>
            </Box>
            {props.suggestedSearchResultsPortal?.current && (
              <Portal container={props.suggestedSearchResultsPortal?.current}>
                {renderGroupedOptions && groupedOptions.length > 0 && (
                  <SuggestedSearchBoxContainer>
                    <SuggestedSearchBoxInner ref={suggestedInner}>
                      <SuggestedSearchBox {...getListboxProps()}>
                        {groupedOptions?.map((option, index) => {
                          return (
                            <SuggestedSearchOption {...getOptionProps({ option, index })}>
                              <ActionPill animate={revealGroupedOptions} emphasize={option.emphasize}>
                                {renderOption ? renderOption(option) : option.title}
                              </ActionPill>
                            </SuggestedSearchOption>
                          )
                        })}
                      </SuggestedSearchBox>
                    </SuggestedSearchBoxInner>
                  </SuggestedSearchBoxContainer>
                )}
              </Portal>
            )}

            <Typography
              variant="h4"
              component="span"
              style={{
                fontSize: 20,
                visibility: 'hidden',
                position: 'absolute',
                whiteSpace: 'nowrap'
              }}
              ref={hiddenText}
            >
              {value?.title || placeholder}
            </Typography>
          </InputContainer>
        )
      }}
    </Input>
  )
}

const InputContainer = styled(Box)`
  flex: 1 1 auto;

  ${({ theme }) => `
    padding: ${theme.spacing(1)} 0;
  `}
`

const SuggestedSearchBoxContainer = styled(Box)`
    display: flex
    overflow: auto;
    overflow-x: scroll;
    white-space: nowrap;
    width: 100%;
    scrollbar-width: none; /* Firefox */
    &::-webkit-scrollbar {  /* Chrome, Safari, Edge */
      display: none;
    }
`

const SuggestedSearchBoxInner = styled(Box)`
  position: relative;
`

const SuggestedSearchBox = styled('ul')`
  ${({ theme }) => `
    margin: 0;
    padding: 0 ${theme.spacing(2)};
    list-style-type: none;
    display: flex;
    gap: ${theme.spacing(1)};
    flex-wrap: wrap;
    justify-content: flex-start;
  `}
`
const SuggestedSearchOption = styled('li')`
  cursor: pointer;
  white-space: nowrap;
  border-radius: ${({ theme }) => theme.spacing(2)};
  &:focus,
  &:hover,
  &.Mui-focusVisible {
    transition-delay: 0s;
  }
  &:focus {
    outline: none;
  }
`

const Loader = styled(Box)`
  position: absolute;
  height: 20px;
  width: 20px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
`

export default ActionInput
