export const createSearchableString = (str?: string | null) => {
  return str?.replace(/[^a-zA-Z0-9]/g, '').toLocaleLowerCase() || ''
}

export const capitalizeFirstLetter = (string: string) => {
  return string.substring(0, 1).toUpperCase() + string.substring(1)
}

export const stringExists = (str?: string) => str && str !== '' && str !== ' '

export const slugify = (input: string) =>
  input
    .trim()
    .toLowerCase()
    .replace(/\s+/g, '-')
    .replace(/[^a-zA-Z0-9\-]/g, '')
    .slice(0, 200) as string

export const randomHash = (length: number) => {
  const set = [
    '0',
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7',
    '8',
    '9',
    'b',
    'c',
    'd',
    'f',
    'g',
    'h',
    'l',
    'm',
    'n',
    'p',
    'q',
    'r',
    's',
    't',
    'v',
    'w',
    'x',
    'z'
  ]

  const s = new Set()
  while (s.size < length) {
    const n = set[Math.floor(Math.random() * set.length)]
    if (!s.has(n)) {
      s.add(n)
    }
  }
  return Array.from(s).join('')
}

const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
export const isValidEmail = (email: string) => emailRegex.test(email)

export const stringToQueryParams = (string: string) => {
  const queryParams = new URLSearchParams()

  string.split('&').forEach(pair => {
    const [key, value] = pair.split('=')
    queryParams.append(key, decodeURIComponent(value))
  })

  return queryParams
}

export const createArrayString = (array?: string[]) => {
  if (!array) return
  return `"${array?.join('","')}"`
}

export const extractSimpleUrl = (url: string) => {
  const regex = /^(?:https?:\/\/)?(?:[^@\/\n]+@)?(?:www\.)?([^:\/?\n]+)/gim
  const match = regex.exec(url)
  return match && match[1]
}

export const generateQueryString = (obj: { [s: string]: unknown } | ArrayLike<unknown>) => {
  const queryParams = []

  for (const [key, value] of Object.entries(obj)) {
    if (value) {
      queryParams.push(`${encodeURIComponent(key)}=${encodeURIComponent(value as string)}`)
    }
  }

  return queryParams.length ? `?${queryParams.join('&')}` : ''
}

export const addSentenceStructureToStringArray = (stringArray?: string[], andOrText?: string) => {
  const strings = stringArray?.map((string, i) => {
    const shouldAddAndOr = stringArray.length > 1 && i === stringArray.length - 1
    const shouldAddComma = stringArray.length > 2 && i < stringArray.length - 2
    const isLast = i === stringArray.length - 1
    const andOrTextString = shouldAddAndOr ? (andOrText || '') + ' ' : ''
    const returnString = `${andOrTextString}${string}${shouldAddComma ? ',' : ''}${
      isLast ? '' : ' '
    }`

    return returnString
  })

  return strings
}
