import React from 'react'
import { styled, Box, Typography } from '@mui/material'

import { excludeScProps } from '../../../utilities/browser'
import { colors } from '../../../constants/colors'
import { BoxProps } from '@mui/system'

export const PillSmall = styled(Box)`
  display: inline-block;
  ${({ theme }) => `
    border-radius: 9px;
    padding: ${theme.spacing(0.5)} ${theme.spacing(1)};
`}
`
export const PillLarge = styled(Box)`
  ${({ theme }) => `
    white-space: nowrap;
    border-radius: 100px;
    padding: ${theme.spacing(1.5)} ${theme.spacing(3)};
    ${theme.breakpoints.down('sm')} {
      padding: 12px 15px;
    }
    
`}
`

type AnimatedPillProps = {
  animate?: boolean
  active?: boolean
}
export const AnimatedPill = styled(PillLarge, excludeScProps('animate'))<AnimatedPillProps>`
  ${({ animate }) => `
  cursor: pointer;
  ${
    animate == true
      ? `
        @keyframes pop {
          0% {
            opacity: 0;
            transform: translateY(5px);
          }
          100% {
            opacity: 1;
            transform: translateY(0px);
          }
        }
        animation: pop 0.4s cubic-bezier(0, 0, 0, 1);
        animation-fill-mode: backwards;
          `
      : animate == false
        ? `
        opacity: 0;
      `
        : ``
  }
  `}
`

export const ActionPill = styled((props: BoxProps & AnimatedPillProps & { emphasize?: boolean }) => {
  const { children, emphasize, ...rest } = props
  return (
    <AnimatedPill {...rest} sx={(theme) => (emphasize ? { border: '1px solid', borderColor: theme.palette.mode == 'dark' ? colors.GEORGIAN_BAY_10 : colors.GEORGIAN_BAY_30 } : {})}>
      <Typography variant="h6" fontWeight={400} fontSize={20} sx={{ color: 'current' }}>
        {children}
      </Typography>
    </AnimatedPill>
  )
})(({ theme }) =>
  theme.palette.mode == 'dark'
    ? `
        background: ${colors.DARK_MODE_50};
          color: ${colors.WHITE};
        :hover,
        .Mui-focusVisible & {
          background: ${colors.DARK_MODE_70};
        }
      `
    : `
        background: ${colors.WHITE};
        color: ${colors.BLACK_100};
        :hover,
        .Mui-focusVisible & {
          background: ${colors.GLACIER_BLUE_100};
          color: ${colors.WHITE};
        }
      `
)

export const AdditionalFilterPill = styled((props: BoxProps & AnimatedPillProps) => {
  const { children, ...rest } = props
  return (
    <AnimatedPill {...rest}>
      <Typography variant="h6" fontWeight={500} fontSize={20} sx={{ color: 'current' }}>
        {children}
      </Typography>
    </AnimatedPill>
  )
})(({ theme }) =>
  theme.palette.mode == 'dark'
    ? `
        background: ${colors.DARK_MODE_50};
          color: ${colors.WHITE};
        :hover,
        .Mui-focusVisible & {
          background: ${colors.DARK_MODE_70};
        }
      `
    : `
        background: ${colors.GEORGIAN_BAY_30};
        color: ${colors.WHITE};
        :hover,
        .Mui-focusVisible & {
          background: ${colors.GEORGIAN_BAY_70};
        }
      `
)
