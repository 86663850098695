import { Box, Card, Typography, styled, useTheme } from '@mui/material'
import * as React from 'react'
import { colors } from '../constants/colors'
import { resultsState } from '../context/results-context'
import Binoculars from '../images/icons/illustrations/binoculars'
import HighFive from '../images/icons/illustrations/high-five'
import Sailboat from '../images/icons/illustrations/sailboat'

import { getErrorMessage } from '../utilities/data'

import { graphql, navigate, useStaticQuery } from 'gatsby'
import { useLocation } from 'react-router-dom'
import { fetchOtherVerbs, fetchVerbsRelatedToNoun } from '../async/sanity'
import {
  ButtonBox,
  ButtonPrimary,
  ButtonSecondary,
  ButtonText,
  SmallText,
  TextButton
} from '../elements/form-elements'
import { authState } from '../state/auth-state'
import { shuffleArray } from '../utilities/array'
import {
  convertFiltersToParamObject,
  getActionSentenceFromFilters,
  parameterizeFilters
} from '../utilities/sanity-filters'
import GetMembershipModal from './get-membership-modal'
import LoadingScreen from './loading-screen'
import { ResultCardData } from './result-card'

type ConfirmActionCardProps = {
  action: ResultCardData
  isCandidate?: boolean
  onDeny: () => void
}

type ScreenType = {
  type: string
  icon: React.JSX.Element
  headline: string
  description: string
  ctaYes?: string
  ctaYesIcon?: React.JSX.Element
  ctaYesAlt?: string
  ctaYesAltPrelude?: string
  ctaNo?: string
  ctaNoIcon?: React.JSX.Element
  ctaCancel?: string
}

enum ScreenTypes {
  CONFIRM = 'confirm-action',
  CONFIRM_CANDIDATE = 'confirm-candidate',
  CREATE_PORTFOLIO = 'create-portfolio',
  SHOW_MORE_OPTIONS = 'show-more-actions',
  SHOW_OTHER_OPTIONS = 'show-other-actions'
}

const exploreHeadlines = [
  "Let's throw the kitchen sink at it. Ready?",
  'Shall we pile on?',
  'You ready for some more good trouble?'
]
const screens: { [key: string]: ScreenType } = {
  confirmation: {
    type: ScreenTypes.CONFIRM,
    icon: <Binoculars />,
    headline: 'Did you take this action?',
    description:
      'We don’t track you outside of this tool, so think of the whole thing like one big trust fall.',
    ctaYes: 'yes',
    ctaNo: 'no'
  },
  confirmation_candidate: {
    type: ScreenTypes.CONFIRM_CANDIDATE,
    icon: <Binoculars />,
    headline: 'Did you contact your representative?',
    description:
      'We don’t track you outside of this tool, so think of the whole thing like one big trust fall.',
    ctaYes: 'yes',
    ctaNo: 'no'
  },
  showMoreActions: {
    type: ScreenTypes.SHOW_MORE_OPTIONS,
    icon: <HighFive />,
    headline: 'Excellent work.',
    description:
      'Your action was to __ACTION_TYPE__. Research shows coming at it from more than one angle is how we win. Ready for more?',
    ctaYes: 'yes',
    ctaCancel: 'not right now'
  },
  showOtherActions: {
    type: ScreenTypes.SHOW_OTHER_OPTIONS,
    icon: <Sailboat />,
    headline: 'I want to spread my wings.',
    description: 'Let’s explore another category.',
    ctaCancel: 'maybe later',
    ctaYes: `let's go`
  }
}

const ConfirmActionCard: React.FC<ConfirmActionCardProps> = ({ action, isCandidate, onDeny }) => {
  const theme = useTheme()
  const location = useLocation()
  const currentPath = location.pathname

  const { filters, changeFilters } = resultsState()
  const { authUser, updateActionTaken } = authState()
  const [screen, setScreen] = React.useState(
    isCandidate ? screens.confirmation_candidate : screens.confirmation
  )
  const [loadingRelatedVerb, isLoadingRelatedVerb] = React.useState(false)
  const [otherVerbs, setOtherVerbs] = React.useState<Queries.SanityVerb[]>([])

  const getUniqueVerbs = (verbs: Queries.SanityVerb[]) => {
    const categories = Array.from(new Set(verbs.map(({ category }) => category?.slug?.current)))
    return categories.map((_slug, i) => verbs[i])
  }
  React.useEffect(() => {
    const getRelatedVerbs = async () => {
      if (action.nouns && action.categories && action.categories[0]) {
        isLoadingRelatedVerb(true)
        try {
          const relatedVerbs = await fetchVerbsRelatedToNoun(
            action.nouns as Queries.SanityNoun[],
            action.categories[0]
          )
          setOtherVerbs(relatedVerbs ? getUniqueVerbs(relatedVerbs) : [])
          if (relatedVerbs && relatedVerbs.length > 0) {
          } else {
            setScreen({
              ...screens.showOtherActions,
              icon: <HighFive />,
              headline: 'Excellent work.'
            })
          }
        } catch (err) {
          throw new Error(getErrorMessage(err, 'Could not load related actions'))
        }
        isLoadingRelatedVerb(false)
      }
    }
    if (screen.type === ScreenTypes.SHOW_MORE_OPTIONS) {
      getRelatedVerbs()
    }
  }, [screen])

  const handleConfirm = React.useCallback(async () => {
    if (screen.type === ScreenTypes.CONFIRM || screen.type === ScreenTypes.CONFIRM_CANDIDATE) {
      updateActionTaken(action.slug?.current as string, true)
      if (!authUser) {
        setShowMembershipModal(true)
      } else {
        setScreen(screens.showMoreActions)
      }
    }

    if (screen.type === ScreenTypes.SHOW_MORE_OPTIONS) {
      changeFilters(
        {
          nouns: action.nouns,
          verbs: otherVerbs,
          verbsExclusive: false,
          nounsExclusive: false
        },
        true
      )
    }
    if (screen.type === ScreenTypes.SHOW_OTHER_OPTIONS) {
      isLoadingRelatedVerb(true)
      try {
        const relatedVerbs =
          action?.categories &&
          action?.categories[0] &&
          shuffleArray(await fetchOtherVerbs(action.categories[0]))

        if (relatedVerbs && relatedVerbs[0]) {
          const newFilters = {
            verbs: [relatedVerbs[0]],
            verbsExclusive: false
          }
          if (currentPath.indexOf('/results') === -1) {
            navigate('/results?' + parameterizeFilters(convertFiltersToParamObject(newFilters)))
          } else {
            changeFilters(newFilters, true)
          }
        }
      } catch (err) {
        throw new Error(getErrorMessage(err, 'Could not load related actions'))
      }
      isLoadingRelatedVerb(false)
    }
  }, [screen, otherVerbs])

  const handleConfirmAlt = React.useCallback(async () => {}, [screen])

  const handleDeny = React.useCallback(async () => {
    switch (screen.type) {
      case ScreenTypes.SHOW_MORE_OPTIONS:
        setScreen(screens.showOtherActions)
        break
      default:
        onDeny()
        break
    }
  }, [screen, otherVerbs])

  const { verbs, nouns } = getActionSentenceFromFilters(filters)

  const [showMembershipModal, setShowMembershipModal] = React.useState(false)
  const hideMembershipModal = () => {
    setShowMembershipModal(false)
  }

  const { allSanityUpgradeModal } = useStaticQuery<Queries.SanityUpgradeModalQuery>(graphql`
    query SanityUpgradeModal {
      allSanityUpgradeModal {
        nodes {
          ...upgradeModal
        }
      }
    }
  `)

  // this is an annoying getaround we have to do since querying by slug
  // seems to break Gatsby
  const sanityUpgradeModal = allSanityUpgradeModal?.nodes?.find(
    ({ slug }) => slug.current === 'action-taken-upgrade-modal'
  )

  return (
    <CardEl>
      {loadingRelatedVerb && <LoadingScreen loading={true} />}
      <CardContent sx={loadingRelatedVerb && { opacity: 0 }}>
        {screen.icon}
        <Typography variant="h4" color={colors.WHITE} pt={2}>
          {screen.type == ScreenTypes.SHOW_MORE_OPTIONS
            ? exploreHeadlines[Math.floor(Math.random() * exploreHeadlines.length)]
            : screen.headline}
        </Typography>
        <Typography variant="body2" color={colors.WHITE}>
          {screen.description.replace('__ACTION_TYPE__', `${verbs?.join('')} ${nouns}`)}
        </Typography>
        <ButtonBox>
          {screen.ctaYes && (
            <ButtonPrimary fullWidth startIcon={screen.ctaYesIcon} onClick={handleConfirm}>
              <ButtonText>{screen.ctaYes}</ButtonText>
            </ButtonPrimary>
          )}
          {screen.ctaYesAltPrelude && (
            <SmallText sx={{ my: 1 }}>{screen.ctaYesAltPrelude}</SmallText>
          )}

          {screen.ctaYesAlt && (
            <ButtonPrimary
              variant={theme.palette.mode == 'dark' ? 'contained' : 'outlined'}
              fullWidth
              onClick={handleConfirmAlt}
            >
              <ButtonText>{screen.ctaYesAlt}</ButtonText>
            </ButtonPrimary>
          )}
          {screen.ctaNo && (
            <ButtonSecondary
              sx={{ mt: 1 }}
              fullWidth
              startIcon={screen.ctaNoIcon}
              onClick={handleDeny}
            >
              <ButtonText>{screen.ctaNo}</ButtonText>
            </ButtonSecondary>
          )}
          {screen.ctaCancel && (
            <TextButton sx={{ mt: 2 }} fullWidth onClick={handleDeny}>
              <SmallText>{screen.ctaCancel}</SmallText>
            </TextButton>
          )}
        </ButtonBox>
      </CardContent>

      <GetMembershipModal
        content={sanityUpgradeModal}
        open={showMembershipModal}
        onClose={hideMembershipModal}
      />
    </CardEl>
  )
}

const CardEl = styled(Card)`
  ${({ theme }) => `
    padding: ${theme.spacing(3.5)} ${theme.spacing(2.5)};
    width: 450px;
    max-width: 100%;
    overflow: scroll;
    margin: auto;
    &::-webkit-scrollbar {
      display: none;
    }
   
    background: ${theme.palette.mode == 'dark' ? colors.DARK_MODE_80 : colors.GEORGIAN_BAY_70};
  `}
`

const CardContent = styled(Box)`
  flex: 1;
  display: flex;
  align-items: center;
  width: 100%;
  flex-direction: column;
  justify-content: space-between;
  gap: ${({ theme }) => theme.spacing(1)};
  text-align: center;
`
export default ConfirmActionCard
