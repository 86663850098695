import { Box } from '@mui/material'
import React, { useEffect, useRef } from 'react'
import { iconsByCategory } from './result-card'
import { authState } from '../state/auth-state'
import { Check } from '@mui/icons-material'
import StandardButton from './buttons/standard-button'

const everyWebhookToken = 'lcnjTGepT0ZOsdci4C5ASEd86Tf8cOyp'

export const EveryDonateButton = ({
  organization,
  action_id,
  isCompleted
}: {
  organization: string
  action_id?: string
  isCompleted?: boolean
}) => {
  const { account } = authState()
  const buttonId = `every-${organization}-btn`
  const metadata = btoa(
    JSON.stringify({
      action_id,
      account_id: account?.id
    })
  )

  useEffect(() => {
    const everyButtonScript = document.createElement('script')
    everyButtonScript.onload = () => {
      window.everyDotOrgDonateButton.createWidget({
        selector: `#${buttonId}`,
        nonprofitSlug: organization,
        webhookToken: everyWebhookToken,
        completeDonationInNewTab: true,
        partnerMetadata: metadata
      })
    }
    everyButtonScript.src = 'https://embeds.every.org/0.4/button.js?explicit=1'
    parentRef.current?.appendChild(everyButtonScript)
  }, [])

  const parentRef = useRef<HTMLDivElement>(null)

  return (
    <Box ref={parentRef} id={buttonId}>
      <StandardButton
        variant="contained"
        href={`https://www.every.org/${organization}#/donate`}
        disableElevation
        color={isCompleted ? 'success' : 'primary'}
        startIcon={isCompleted ? <Check sx={{ width: 24, height: 24 }} /> : iconsByCategory['donate']}
        sx={{ width: '100%' }}
      >
        Donate
      </StandardButton>
    </Box>
  )
}
