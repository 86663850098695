import { FirebaseApp, initializeApp } from 'firebase/app'
import { Auth, getAuth } from 'firebase/auth'
import { isInBrowser } from '../../utilities/browser'
const [
  GATSBY_FIREBASE_API_KEY,
  GATSBY_FIREBASE_AUTH_DOMAIN,
  GATSBY_FIREBASE_PROJECT_ID,
  GATSBY_FIREBASE_STORAGE_BUCKET,
  GATSBY_FIREBASE_MESSAGING_SENDER_ID,
  GATSBY_FIREBASE_APP_ID
] = (process.env.GATSBY_FIREBASE_VARS as string).split('|')

const firebaseConfig = {
  apiKey: GATSBY_FIREBASE_API_KEY,
  authDomain: GATSBY_FIREBASE_AUTH_DOMAIN,
  projectId: GATSBY_FIREBASE_PROJECT_ID,
  storageBucket: GATSBY_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: GATSBY_FIREBASE_MESSAGING_SENDER_ID,
  appId: GATSBY_FIREBASE_APP_ID
}

export let app: FirebaseApp
export let auth: Auth

if (isInBrowser()) {
  app = initializeApp(firebaseConfig)
  auth = getAuth(app)
}
