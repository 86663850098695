import { Box, Button, styled, Typography } from '@mui/material'
import * as React from 'react'
import { HBox, VBox } from '../elements/basic-elements'

import { colors } from '../constants/colors'
import { ListTitle } from '../elements/text-elements'

export type ChipProps = {
  title: string
  active: boolean
}
type ChipListType = {
  title?: string
  onToggle: (chip: ChipProps) => void
  items: ChipProps[]
  center?: boolean
  gap?: number
  flat?: boolean
  overscrollPadding?: string
  renderChip?: (props: { title: string; active: boolean }) => React.ReactNode
}
const ChipList: React.FC<ChipListType> = ({
  title,
  items,
  onToggle,
  center,
  flat,
  gap = 0.5,
  overscrollPadding = '0px',
  renderChip
}) => {
  return (
    <VBox>
      {title && <ListTitle variant="caption">{title}</ListTitle>}
      <ChipContainer
        flexWrap={flat ? 'nowrap' : 'wrap'}
        gap={gap}
        alignItems="center"
        justifyContent={center ? 'center' : 'flex-start'}
        sx={
          flat && {
            overflow: 'scroll',
            ml: `calc(-1 * ${overscrollPadding})`,
            width: `calc(100% + (${overscrollPadding} + ${overscrollPadding}))`
          }
        }
      >
        {items.map(({ title, active }, i) => {
          const padFirstItem = i == 0 && flat
          const padLastItem = i == items.length - 1 && flat
          return (
            <Box
              sx={{
                pr: padLastItem ? overscrollPadding : 0,
                pl: padFirstItem ? overscrollPadding : 0
              }}
            >
              {renderChip ? (
                renderChip({ title, active })
              ) : (
                <Chip active={active} onClick={() => onToggle({ title, active: !active })}>
                  <Typography
                    variant="body2"
                    color="textPrimary"
                    sx={flat && { whiteSpace: 'nowrap' }}
                  >
                    {title}
                  </Typography>
                </Chip>
              )}
            </Box>
          )
        })}
      </ChipContainer>
    </VBox>
  )
}

const ChipContainer = styled(HBox)<{ flat?: boolean }>`
  scroll-behavior: smooth;
`

export const Chip = styled(Button) <{ active: boolean }>`
  ${({ theme, active }) => `
        border-radius: 100px;
        padding: ${theme.spacing(1)} ${theme.spacing(2)};
        font-weight: 500;
        color: ${active ? colors.WHITE : colors.BLACK_100};

        ${
          theme.palette.mode == 'dark'
            ? `
          background: ${active ? colors.DARK_MODE_50 : colors.DARK_MODE_70};
          color: ${active ? colors.WHITE : colors.DARK_MODE_10};
        `
            : `
          transition: background;
          background: ${active ? theme.palette.secondary.dark : theme.palette.tertiary.main};
        `
        }

        &:hover {
            background: ${theme.palette.secondary.main};
        }
    `}
`

export default ChipList
