import React from 'react'
import { DEFAULT_PATH_COLOR, IconPath } from '../../elements/basic-elements'
import { CustomIconProps } from '../../types/component-types'

const PodcastIcon = ({ size = 17, color = DEFAULT_PATH_COLOR }: CustomIconProps) => (
  <svg
    width={size}
    height={size / 0.944}
    viewBox="0 0 17 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1932_4828)">
      <rect
        x="5.75195"
        y="4.75"
        width="5.1"
        height="8.5"
        rx="2.55"
        stroke="#004660"
        strokeWidth="1.5"
      />
      <IconPath
        d="M13.2274 8.61841C13.4299 7.86279 13.4558 7.07072 13.3032 6.30348C13.1506 5.53623 12.8235 4.81438 12.3473 4.19375C11.8711 3.57313 11.2584 3.07038 10.5568 2.72439C9.85524 2.37839 9.08345 2.19844 8.30117 2.19844C7.51889 2.19844 6.7471 2.37839 6.0455 2.72439C5.3439 3.07038 4.73129 3.57313 4.25507 4.19376C3.77885 4.81438 3.45178 5.53623 3.29917 6.30348C3.14655 7.07072 3.17248 7.8628 3.37495 8.61842"
        stroke={color}
      />
      <IconPath d="M8.30078 13.25L8.30078 15.8" stroke={color} />
      <IconPath d="M10.002 15.7988L6.60195 15.7988" stroke={color} />
      <IconPath d="M10.8516 10.6992H5.75156" stroke={color} />
    </g>
    <defs>
      <clipPath id="clip0_1932_4828">
        <rect width={size} height={size} fill="white" transform="translate(0 0.5)" />
      </clipPath>
    </defs>
  </svg>
)

export default PodcastIcon
