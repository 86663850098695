import React from 'react'

import { Box, useMediaQuery, useTheme } from '@mui/material'
import HeaderDesktop from './header_desktop'
import HeaderMobile from './header_mobile'

const HeaderMain = () => {
  const theme = useTheme()
  const useMobileMenu = useMediaQuery(theme.breakpoints.down('lg'))
  return <Box p={{ xs: '10px', md: 2 }}>{useMobileMenu ? <HeaderMobile /> : <HeaderDesktop />}</Box>
}

export default HeaderMain
