import { Box, keyframes, styled } from '@mui/material'
import React from 'react'
import { colors } from '../constants/colors'

interface CustomShimmerColors {
  base: string
  highlight: string
}

interface LoadingShimmerProps {
  borderRadius?: string
  customColors?: CustomShimmerColors
  width?: number | string
  height?: number | string
  speed?: number
}

const LoadingShimmer = ({ borderRadius = '4px', width = '100%', height = '100%', ...rest }: LoadingShimmerProps) => {
  return (
    <ShimmerBox
      width={width}
      height={height}
      borderRadius={borderRadius}
      {...rest}
    />
  )
}

const shimmer = keyframes`
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
`;

const ShimmerBox = styled(Box) <{ customColors?: CustomShimmerColors, speed?: number }>`
  animation: ${shimmer} ${({ speed }) => (`${speed != null ? speed : '3'}s`)} linear infinite;
  ${({ theme, customColors }) => {
    const isDarkMode = theme.palette.mode == 'dark'
    const color1 = customColors?.highlight ?? (isDarkMode ? colors.DARK_MODE_80 : colors.GEORGIAN_BAY_10)
    const color2 = customColors?.base ?? (isDarkMode ? colors.DARK_MODE_70 : colors.GEORGIAN_BAY_30)

    return `
      background: linear-gradient(-45deg, ${color1} 0%, ${color2} 50%, ${color1} 100%);
      background-size: 200% 100%;
    `;
  }}
`;

export default LoadingShimmer
