import React from 'react'
import { CustomIconProps } from '../../types/component-types'
import { DEFAULT_PATH_COLOR, IconPath } from '../../elements/basic-elements'

const LogoutIcon = ({ size = 24, color = DEFAULT_PATH_COLOR }: CustomIconProps) => (
  <svg
    width={size || 24}
    height={size || 24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <IconPath
      d="M13 20.5H19C19.5523 20.5 20 20.0523 20 19.5V17.6966M13 4.5H19C19.5523 4.5 20 4.94772 20 5.5V7.5098"
      stroke={color}
      stroke-linejoin="round"
    />
    <IconPath
      d="M13 20.5H19C19.5523 20.5 20 20.0523 20 19.5V17.6966M13 4.5H19C19.5523 4.5 20 4.94772 20 5.5V7.5098"
      stroke={color}
      strokeOpacity="0.2"
      stroke-linejoin="round"
    />
    <IconPath
      d="M11.7831 22.2296L4.78307 20.674C4.32553 20.5723 4 20.1665 4 19.6978V5.30217C4 4.83347 4.32553 4.42766 4.78307 4.32598L11.7831 2.77043C12.4076 2.63165 13 3.10687 13 3.74662V21.2534C13 21.8931 12.4076 22.3683 11.7831 22.2296Z"
      stroke={color}
      stroke-linejoin="round"
    />
    <IconPath
      d="M11.7831 22.2296L4.78307 20.674C4.32553 20.5723 4 20.1665 4 19.6978V5.30217C4 4.83347 4.32553 4.42766 4.78307 4.32598L11.7831 2.77043C12.4076 2.63165 13 3.10687 13 3.74662V21.2534C13 21.8931 12.4076 22.3683 11.7831 22.2296Z"
      stroke={color}
      strokeOpacity="0.2"
      stroke-linejoin="round"
    />
    <IconPath
      d="M18.9688 9.67188L21.7972 12.5003L18.9687 15.3287"
      stroke={color}
      stroke-linejoin="round"
    />
    <IconPath
      d="M18.9688 9.67188L21.7972 12.5003L18.9687 15.3287"
      stroke={color}
      strokeOpacity="0.2"
      stroke-linejoin="round"
    />
    <IconPath d="M21.5703 12.5H16.1375" stroke={color} />
    <IconPath d="M21.5703 12.5H16.1375" stroke={color} strokeOpacity="0.2" />
  </svg>
)

export default LogoutIcon
