import React, { useCallback } from 'react'
import { actionSelectorState } from '../../context/action-selector-context'
import {
  FormFragment,
  FormFragmentInput,
  FormFragmentText,
  TextFragment
} from '../../styles/styled-components/action-selector'
import ActionInput, { ActionInputPropsBase, InputOption } from './action-input'
import { createNounOptionFrom } from './utils'

const NounSelect = ({ suggestedSearchResultsPortal }: ActionInputPropsBase) => {
  const { loading, optionsNouns, selectedVerb, selectedNoun, setSelectedNoun, resetSearch } =
    actionSelectorState()

  const nounValue = selectedNoun && createNounOptionFrom(selectedNoun)

  const allNounOptions = useCallback(() => {
    return optionsNouns?.map(noun => {
      return createNounOptionFrom(noun)
    })
  }, [optionsNouns])

  return (
    <FormFragment>
      {selectedVerb?.preposition && (
        <FormFragmentText>
          <TextFragment variant="h2" color="textPrimary">
            {selectedVerb?.preposition}
          </TextFragment>
        </FormFragmentText>
      )}
      {selectedNoun?.article && (
        <FormFragmentText>
          <TextFragment variant="h2" color="textPrimary">
            {selectedNoun?.article}
          </TextFragment>
        </FormFragmentText>
      )}
      <FormFragmentInput>
        <ActionInput
          id='noun'
          options={allNounOptions()}
          value={nounValue}
          loading={loading && !selectedNoun}
          placeholder={'choose a subject'}
          suggestedSearchResultsPortal={suggestedSearchResultsPortal}
          onCloseClick={() => { resetSearch(false) }}
          onValueChanged={(value?: InputOption) => {
            const newNoun = optionsNouns?.find(({ slug }) => slug?.current === value?.slug)
            setSelectedNoun && setSelectedNoun(newNoun)
          }}
        />
      </FormFragmentInput>
    </FormFragment>
  )
}

export default NounSelect
