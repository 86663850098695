import React from 'react'
import { actionSelectorState } from '../../context/action-selector-context'
import WhiteButton from '../buttons/white-button'

const ClearButton = () => {
  const { selectedVerb, resetSearch } = actionSelectorState()

  return (
    <WhiteButton onClick={() => { resetSearch() }} disabled={!selectedVerb} sx={{ px: 4 }}>
      Reset search
    </WhiteButton>
  )
}

export default ClearButton
