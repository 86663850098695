import { ButtonProps, useMediaQuery } from '@mui/material'
import React from 'react'
import { navigate } from 'gatsby'
import PremiumIcon from '../../images/icons/premium-icon'
import StandardButton from '../buttons/standard-button'
import { SquareButton } from '../../elements/basic-elements'

const SMALL_MOBILE_BREAKPOINT = 'max-width:350px'
const ALL_MOBILE_BREAKPOINT = 'max-width:400px'

interface BecomeImportantButtonProps {
  hasLimitedSpace?: boolean
}

const BecomeImportantButton: React.FC<ButtonProps & BecomeImportantButtonProps> = ({ hasLimitedSpace }: BecomeImportantButtonProps) => {
  const iconOnly = useMediaQuery(`(${hasLimitedSpace ? ALL_MOBILE_BREAKPOINT : SMALL_MOBILE_BREAKPOINT})`)

  return (
    iconOnly ?
      <SquareButton
        variant='contained'
        color='tertiary'
        disableElevation
      >
        <PremiumIcon />
      </SquareButton> :
      <StandardButton
        variant='contained'
        color='tertiary'
        disableElevation
        startIcon={<PremiumIcon />}
        onClick={() => { navigate('/register') }}
        sx={{ whiteSpace: 'nowrap', fontSize: { xs: '14px', sm: '18px' } }}
      >
        {'Important'}
      </StandardButton>
  )
}

export default BecomeImportantButton


