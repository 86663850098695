import { attempt } from './utils'

type NetlifyResponse<T> = {
  success: boolean
  error?: string
  data?: T
}
export const fetchNetlify = async <T>(
  url: string,
  { allowedErrorCodes }: { allowedErrorCodes?: number[] } = {}
) => {
  const timeout = new Promise<NetlifyResponse<T>>(resolve => {
    setTimeout(() => {
      resolve({
        success: false,
        error:
          "This data wasn't able to be loaded right now. Please try again later. Sorry about that!"
      })
    }, 9999)
  })

  const fetcher = new Promise<NetlifyResponse<T>>(async resolve => {
    let resp: NetlifyResponse<T> = { success: false }

    await attempt(
      async () =>
        await fetch(url).then(async response => {
          let data
          try {
            data = await response.json()
          } catch {
            console.log('swallowing non json formatted error')
          }
          if (response.ok || allowedErrorCodes?.includes(response.status)) {
            resp = {
              success: true,
              data: data as T
            }
          } else {
            throw new Error(data)
          }
        }),
      err => {
        resp = {
          success: false,
          error: err || 'An unknown error occured. Please try again later.'
        }
      }
    )

    resolve(resp)
  })

  return Promise.race([timeout, fetcher]).then(({ success, data, error }) => {
    if (success == true) {
      return data
    } else {
      throw new Error(error)
    }
  })
}
