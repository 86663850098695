import {
  Box,
  CircularProgress,
  IconButton,
  InputProps,
  Theme,
  styled,
  useTheme
} from '@mui/material'
import { MUIStyledCommonProps } from '@mui/system'
import React, { ReactNode } from 'react'
import { colors } from '../constants/colors'
import { HBox } from '../elements/basic-elements'
import CloseIcon from '../images/icons/close-icon'
import { getFlatIconButtonStyle } from '../utilities/mui'
import {
  InputBase,
  InputLabelBase,
  InputWrapperBase,
  TextBoxBase
} from '../styles/styled-components/inputs/inputs'

type InputComponentProps = {
  id?: string
  hasValue: boolean
  isEditing?: boolean
  disableClose?: boolean
  onCloseClick?: Function
  inputIcon?: JSX.Element
  icon?: JSX.Element
  loading?: boolean
  error?: string
  label?: string
  background?: string
  flexAuto?: boolean
  children: (props: { Input: typeof InputBase; TextBox: typeof TextboxWithoutLine }) => ReactNode
}
const InputComponent: React.FC<InputComponentProps> = ({
  id,
  label,
  hasValue,
  isEditing,
  onCloseClick,
  disableClose,
  icon,
  inputIcon,
  loading,
  error,
  background,
  flexAuto,
  children
}) => {
  const hasError = Boolean(error)
  const hasLabel = Boolean(label)
  const theme = useTheme()
  // don't love that isEditing is controlled outside of component
  return (
    <InputWrapperBase
      background={background}
      variant="filled"
      error={hasError}
      fullWidth={flexAuto == undefined || !flexAuto}
    >
      <Box flex={1} position="relative">
        {(loading || hasError || hasLabel) && (
          <InputLabelBase htmlFor={id} classes={{ root: 'root' }}>
            {loading ? (
              <CircularProgress
                color={theme.palette.mode == 'dark' ? 'tertiary' : 'secondary'}
                sx={{ height: 24, width: 24 }}
                size="sm"
              />
            ) : (
              error || label
            )}
          </InputLabelBase>
        )}
        {children({
          TextBox: TextboxWithoutLine,
          Input: InputBase
        })}
      </Box>
      <IconBox>
        {!isEditing && inputIcon && !hasValue && <IconRight>{inputIcon}</IconRight>}
        {icon && <IconRight>{icon}</IconRight>}
        {(disableClose ? isEditing && hasValue : hasValue) && (
          <CloseButton
            sx={{
              ...getFlatIconButtonStyle('tertiary.main', colors.BLACK_100),
              width: 36,
              height: 36,
              marginRight: '-4px'
            }}
            onClick={() => {
              if (onCloseClick) {
                onCloseClick()
              }
              requestAnimationFrame(() => document.body.focus())
            }}
          >
            <CloseIcon />
          </CloseButton>
        )}
      </IconBox>
    </InputWrapperBase>
  )
}
const IconBox = styled(HBox)``

const IconRight = styled(Box)`
  z-index: 1;
  display: flex;
`
const CloseButton = styled(IconButton)`
  width: 20px;
  height: 20px;
  top: 50%;
  z-index: 1;
`
const TextboxWithoutLine = (props: InputProps & MUIStyledCommonProps<Theme>) => (
  <TextBoxBase disableUnderline {...props} />
)

export default InputComponent
