import React from 'react'
import { DEFAULT_PATH_COLOR, IconPath } from '../../elements/basic-elements'
import { CustomIconProps } from '../../types/component-types'

const NewsletterIcon = ({ size = 17, color = DEFAULT_PATH_COLOR }: CustomIconProps) => (
  <svg
    width={size}
    height={size / 0.944}
    viewBox="0 0 17 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1932_4838)">
      <IconPath
        d="M15.1 14.7992V6.59922C15.1 6.04693 14.6523 5.59922 14.1 5.59922H13.4V3.19922C13.4 2.64693 12.9523 2.19922 12.4 2.19922H4.2C3.64772 2.19922 3.2 2.64693 3.2 3.19922V5.59922H2.5C1.94772 5.59922 1.5 6.04693 1.5 6.59922V14.7992C1.5 15.3515 1.94771 15.7992 2.5 15.7992H14.1C14.6523 15.7992 15.1 15.3515 15.1 14.7992Z"
        stroke={color}
      />
      <IconPath
        d="M12.8323 13.25L8.89896 10.3C8.5434 10.0333 8.05451 10.0333 7.69896 10.3L3.76562 13.25"
        stroke={color}
        stroke-width="1.5"
      />
      <IconPath
        d="M1.76953 6.44922L6.66744 10.2742M14.8307 6.44922L9.93274 10.2742"
        stroke={color}
        stroke-width="1.5"
      />
    </g>
    <defs>
      <clipPath id="clip0_1932_4838">
        <rect width={size} height={size} fill="white" transform="translate(0 0.5)" />
      </clipPath>
    </defs>
  </svg>
)

export default NewsletterIcon
