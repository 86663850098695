import { Box, styled, Typography } from '@mui/material'
import React, { useCallback, useEffect, useRef, useState } from 'react'

import { resultsState } from '../context/results-context'
import { HBox, WhiteBox } from '../elements/basic-elements'

import { colors } from '../constants/colors'
import { actionDataState } from '../context/action-data-context'
import { candidateDataState } from '../context/candidate-data-context'
import MagGlassIcon from '../images/icons/mag-glass-icon'
import ActionSentence from './action-sentence'
import FilterBox from './filter-box'
import LocationBox from './location-box'
import { InfoBox } from './result-card'

const MIN_HEIGHT_TO_STACK_FILTERS = 60

const SearchResultsDefaultHeader = ({
  forceFiltersOpen,
  onFiltersClosed
}: {
  forceFiltersOpen: boolean
  onFiltersClosed?: () => void
}) => {
  const { filters, loading: resultsLoading } = resultsState()
  const { totalActions = 0 } = actionDataState()
  const { totalCandidates = 0 } = candidateDataState()
  const totalResults = totalActions + totalCandidates

  const titleBoxRef = useRef<HTMLDivElement>(null)
  const [stackFilterBoxes, setStackFilterBoxes] = useState(false);
  const measuredRef = useCallback(node => {
    if (node !== null) {
      setStackFilterBoxes(node?.offsetHeight > MIN_HEIGHT_TO_STACK_FILTERS);
      titleBoxRef.current = node
    }
  }, []);

  useEffect(() => {
    const checkShouldStackFilterBoxes = () => {
      setStackFilterBoxes(titleBoxRef?.current?.offsetHeight > MIN_HEIGHT_TO_STACK_FILTERS)
    }

    window.addEventListener('resize', checkShouldStackFilterBoxes)
    return () => window.removeEventListener('resize', checkShouldStackFilterBoxes)
  }, [])

  const [localForceFiltersOpen, setLocalForceOpenFilters] = useState(false)
  return (
    !resultsLoading && (
      <HeadingContainer alignItems="center">
        <Heading gap={1}>
          <TitleBox onClick={() => setLocalForceOpenFilters(true)}>
            <WhiteBox p={1} borderRadius={8}>
              <MagGlassIcon />
            </WhiteBox>
            <ActionSentence
              filters={filters}
              variant="h6"
              sx={{ flex: { xs: 1, sm: 'auto' } }}
              ref={measuredRef}
            >
              <Typography
                variant="h6"
                color="info.main"
                fontSize={16}
                component="span"
                sx={{ ml: 1, mt: '0.44em', alignSelf: 'baseline' }}
              >
                ({totalResults + ' Results'})
              </Typography>
            </ActionSentence>
          </TitleBox>
          <HBox gap={0.5} sx={{ flexDirection: stackFilterBoxes ? 'column' : 'row' }}>
            <FilterBox
              open={forceFiltersOpen || localForceFiltersOpen}
              onClose={() => {
                setLocalForceOpenFilters(false)
                if (onFiltersClosed) {
                  onFiltersClosed()
                }
              }}
            />
            <LocationBox />
          </HBox>
        </Heading>
      </HeadingContainer>
    )
  )
}

const HeadingContainer = styled(Box)`
  ${({ theme }) => `

    padding: ${theme.spacing(1)};
    border-radius: ${theme.shape.borderRadius}px;
    background: ${theme.palette.mode == 'dark' ? colors.DARK_MODE_80 : colors.WHITE};
    margin: 0 ${theme.spacing(3)};

    ${theme.breakpoints.up('md')} {
    margin: ${theme.spacing(2)} ${theme.spacing(5)} ${theme.spacing(0)};
    }
  `}
`

const Heading = styled(HBox)`
  width: 100%;
  justify-content: space-between;
  align-items: center;
`

const TitleBox = styled(InfoBox)`
  flex: 1;
  flex-direction: row;
  gap: 1;
  padding: ${({ theme }) => theme.spacing(1)};
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
`

export default SearchResultsDefaultHeader
