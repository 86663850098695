import React from 'react'
import { actionSelectorState } from '../../context/action-selector-context'
import { FormFragment, FormFragmentInput } from '../../styles/styled-components/action-selector'
import ActionInput, { ActionInputPropsBase, InputOption } from './action-input'

const CandidateIssuesSelect = ({ suggestedSearchResultsPortal }: ActionInputPropsBase) => {
  const { loading, optionsIssues, selectedIssue, setSelectedIssue } = actionSelectorState()
  return (
    <FormFragment>
      <FormFragmentInput>
        <ActionInput
          id={'candidate-issue'}
          loading={loading && !selectedIssue}
          options={optionsIssues?.map(issue => ({
            slug: issue.slug?.current,
            title: issue.title
          }))}
          value={
            selectedIssue && {
              slug: selectedIssue.slug?.current,
              title: selectedIssue.title
            }
          }
          placeholder={'find an issue'}
          suggestedSearchResultsPortal={suggestedSearchResultsPortal}
          onCloseClick={() => {
            setSelectedIssue && setSelectedIssue(undefined)
          }}
          onValueChanged={(value?: InputOption) => {
            setSelectedIssue &&
              setSelectedIssue(optionsIssues?.find(({ slug }) => slug?.current === value?.slug))
          }}
        />
      </FormFragmentInput>
    </FormFragment>
  )
}

export default CandidateIssuesSelect
