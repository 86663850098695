import * as React from 'react'
import { PropsWithChildren, useContext } from 'react'

import { isInBrowser } from '../utilities/browser'
import { getErrorMessage } from '../utilities/data'
import { fetchLocationData } from '../async/civic-info'
import { LocationResponse } from '../types/civic-info-types'

type LocationSettings = {
  address?: string
  prioritizeLocation?: boolean
  restrictToLocation?: boolean
}
export const LocationContext = React.createContext<{
  settings: LocationSettings
  locationData?: LocationResponse
  locationLoading?: boolean
  locationError?: string
  setSettings: (settings: LocationSettings) => void
  setLocationData: (locationData?: LocationResponse) => void
}>({
  settings: {},
  setSettings: () => {},
  setLocationData: () => {}
})

const getLocationFromLocalStorage = () => {
  const location = localStorage.getItem('INI_AT_LOCATION_DATA')
  if (location) {
    return JSON.parse(location)
  }
}

export const LocationProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [settings, updateSettings] = React.useState<LocationSettings>({
    address: (isInBrowser() && localStorage.getItem('INI_AT_ADDRESS')) || undefined,
    prioritizeLocation:
      (isInBrowser() && Boolean(localStorage.getItem('INI_AT__USER_PRIORITIZE_LOCATION'))) ||
      undefined,
    restrictToLocation:
      (isInBrowser() && Boolean(localStorage.getItem('INI_AT__USER_RESTRICT_TO_LOCATION'))) ||
      undefined
  })

  const [locationError, setLocationError] = React.useState<string>()
  const [locationLoading, islocationLoading] = React.useState(false)
  const [locationData, setLocationData] = React.useState<LocationResponse | undefined>(
    (isInBrowser() && getLocationFromLocalStorage()) || undefined
  )

  const setSettings = async ({
    address: newAddress,
    prioritizeLocation: newPrioritizeLocation,
    restrictToLocation: newRestricToLocation
  }: typeof settings) => {
    isInBrowser() && newAddress && newAddress !== ''
      ? localStorage.setItem('INI_AT_ADDRESS', newAddress)
      : localStorage.removeItem('INI_AT_ADDRESS')

    const addressUpdate = !newAddress || newAddress === '' ? undefined : newAddress
    if (!addressUpdate) {
      setLocationData(undefined)
    } else if (addressUpdate !== settings.address) {
      islocationLoading(true)
      setLocationError(undefined)
      try {
        const data = await fetchLocationData(addressUpdate)

        localStorage.setItem('INI_AT_LOCATION_DATA', JSON.stringify(data))
        setLocationData(data)
      } catch (err) {
        setLocationError(
          getErrorMessage(
            err,
            "We couldn't find your location info from the provided address. Please try again."
          )
        )
      }
      islocationLoading(false)
    }

    updateSettings({
      address: addressUpdate,
      prioritizeLocation:
        newPrioritizeLocation ||
        // auto set this to true if user is going from no address to an address
        Boolean(!settings.address && addressUpdate),
      restrictToLocation: newRestricToLocation
    })
  }

  return (
    <LocationContext.Provider
      value={{
        settings,
        setSettings,
        locationData,
        setLocationData,
        locationLoading,
        locationError
      }}
    >
      {children}
    </LocationContext.Provider>
  )
}

export const locationState = () => useContext(LocationContext)
