import { useEffect, useLayoutEffect, useRef, useState } from 'react'
import { authState } from '../state/auth-state'
import { useTheme } from '@mui/material'
import { isInBrowser } from './browser'

export const usePrevious = <T>(value: T) => {
  const ref = useRef<T>()
  useEffect(() => {
    ref.current = value
  })
  return ref.current
}

export const isAuthUserAccount = (accountId?: string) => {
  const { authUser } = authState()
  return accountId && authUser?.uid == accountId
}

export const useMaterialBreakpoint = () => {
  const theme = useTheme()
  const [size, setSize] = useState<keyof typeof theme.breakpoints.values>()
  useLayoutEffect(() => {
    if (isInBrowser()) {
      const setDirection = () => {
        const { innerWidth } = window
        if (innerWidth > theme.breakpoints.values.xl) {
          setSize('xl')
        } else if (innerWidth > theme.breakpoints.values.lg) {
          setSize('lg')
        } else if (innerWidth > theme.breakpoints.values.md) {
          setSize('md')
        } else if (innerWidth > theme.breakpoints.values.sm) {
          setSize('sm')
        } else if (innerWidth > theme.breakpoints.values.xs) {
          setSize('xs')
        }
      }
      setDirection()

      window.addEventListener('resize', setDirection)
      return () => window.removeEventListener('resize', setDirection)
    }
  }, [])

  return size
}
