import { CircularProgress } from '@mui/material'
import { navigate } from 'gatsby'
import React from 'react'
import { colors } from '../../constants/colors'
import { actionSelectorState } from '../../context/action-selector-context'
import ArrowRight from '../../images/icons/arrow-right'
import PrimaryButton from '../buttons/primary-button'

const SearchButton = ({ onClick }: { onClick?: () => void }) => {
  const { loading, query, canSearch } = actionSelectorState()

  const actionsLoading = canSearch && loading
  const isLoadingData = actionsLoading

  return (
    <PrimaryButton
      variant="contained"
      disabled={isLoadingData || !canSearch}
      onClick={() => {
        query && navigate(query)
        onClick && onClick()
      }}
      disableElevation
      startIcon={
        isLoadingData ? <CircularProgress color="info" /> : <ArrowRight color={colors.WHITE} />
      }
      sx={{ px: 4 }}
    >
      {!isLoadingData && 'take action'}
    </PrimaryButton>
  )
}

export default SearchButton
