import React, { useEffect, useRef, useState } from 'react'
import { HBox, VBox } from '../elements/basic-elements'
import { Typography, Button, Box, CircularProgress } from '@mui/material'
import ChipList, { ChipProps } from './chip-list'
import Input from './input'
import { filtersState } from '../state/filters-state'
import { ErrorText } from '../elements/form-elements'

export type FilterUpdateProps = {
  title: string
  options: ChipProps[]
  onSave: (activeChips: ChipProps[]) => void
  error?: string
  loading?: boolean
  onClose?: () => void
}

const FilterUpdateScreen: React.FC<FilterUpdateProps> = props => {
  const { filtersLoading } = filtersState()
  const chipContainer = useRef<HTMLDivElement>()
  const [activeFilters, setActiveFilters] = useState(props.options)
  const [filteredChips, setFilteredChips] = useState(props.options)
  const [search, setSearch] = useState('')
  const noOptions = props.options.length == 0
  const loading = filtersLoading && noOptions
  const disable = loading || noOptions

  // reset state when prop options change
  useEffect(() => {
    setActiveFilters(props.options)
    setFilteredChips(props.options)
  }, [props.options])

  useEffect(() => {
    if (search !== '') {
      if (chipContainer.current) {
        chipContainer.current.style.minHeight = chipContainer.current.offsetHeight + 'px'

        requestAnimationFrame(() => {
          const matches = activeFilters.filter(({ title }) => title.indexOf(search) > -1)
          setFilteredChips(matches)
        })
      }
    } else {
      setFilteredChips(props.options)
    }
  }, [search])

  return (
    <VBox justifyContent="center" gap={2} maxHeight="100%">
      <Typography variant="h4" textAlign="center" color="textPrimary">
        {props.title}
      </Typography>{' '}
      <Input
        hasValue={search !== ''}
        onCloseClick={() => {
          setSearch('')
        }}
      >
        {({ Input }) => {
          return (
            <Input
              disabled={disable}
              placeholder="Search options"
              value={search}
              onChange={e => {
                setSearch(e.target.value)
              }}
            />
          )
        }}
      </Input>
      {loading && (
        <HBox justifyContent="center">
          <CircularProgress sx={{ width: 24, height: 24 }} size="sm" />
        </HBox>
      )}
      {!loading && noOptions && (
        <ErrorText textAlign="center">
          {props.error || 'No options found, please try again later.'}
        </ErrorText>
      )}
      {!loading && !noOptions && (
        <Box flex={1} overflow="scroll" ref={chipContainer}>
          <ChipList
            items={filteredChips}
            onToggle={chip => {
              const activeFiltersCopy = [...activeFilters]
              const index = activeFiltersCopy.findIndex(c => c.title === chip.title)
              activeFiltersCopy[index].active = chip.active
              setActiveFilters(activeFiltersCopy)
            }}
            center
          />
        </Box>
      )}
      <Button
        fullWidth
        variant="contained"
        disableElevation
        disabled={disable}
        color="primary"
        onClick={() => {
          props.onSave(activeFilters.filter(({ active }) => active))
          props.onClose && props.onClose()
        }}
      >
        Update
      </Button>
    </VBox>
  )
}

export default FilterUpdateScreen
