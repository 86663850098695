import { isInBrowser } from './browser'
import { randomHash, slugify } from './string'

import { User } from 'firebase/auth'
import { attempt } from '../async/utils'
import { FieldValue } from 'firebase/firestore'
import {
  ActionToolAccountWithId,
  ActionToolUserWithId,
  UserAction,
  UserActionDoc,
  UserActionHistory,
  UserFriendFeedData
} from '../types/firebase-types'
import { updateLsArray } from './ls'
import {
  createAccountData,
  createDoc,
  getAccount,
  getAccountsWithUserId,
  getDocsFromCollection,
  observeAccountData,
  removeDoc,
  syncAccountData
} from '../async/firebase'
import { createDocumentIdQuery, createInArrayQuery } from '../async/firebase/utils'
import { fetchActions } from '../async/sanity'
import {
  testVerification,
  checkVerification,
  createStripeVerificationSession,
  cancelStripeVerificationSession,
  cancelTest,
  getCustomerFromEmail
} from '../async/stripe'
import { UserActionHistoryData } from '../types/component-types'
import { SocialHref } from '../constants/socials'
import { getUserFromEmail } from '../async/beehiiv'
import { PremiumTier } from '../types/beehiiv-types'
import { Stripe } from '@stripe/stripe-js'

export interface PartialUserActionHistory {
  action_slug: string
  user_action_id?: string
}

export const getFavorites = (account?: ActionToolAccountWithId) => {
  let favorites: string[] = account?.user?.saved_actions || []
  if (!favorites && isInBrowser()) {
    // if user is logged in, use their favorites
    const lsFavorites = window.localStorage.getItem('INI_AT__USER_FAVORITES')
    favorites = lsFavorites?.split(',') || []
  }
  return favorites
}

export const toggleFavorite = async (
  slug: string,
  favorited: boolean,
  account?: ActionToolAccountWithId
) => {
  return await attempt(async () => {
    const favorites = [...getFavorites(account)]

    const currentFavorited = favorites.indexOf(slug) > -1
    if (!favorited && currentFavorited) {
      // remove from favorites
      favorites.splice(favorites.indexOf(slug), 1)
    } else if (favorited && !currentFavorited) {
      // add to favorites
      favorites.push(slug)
    }

    if (account?.id) {
      // if user is logged in, set favorites to their profile
      await syncAccountData(account.id, { 'user.saved_actions': favorites })
    } else if (isInBrowser()) {
      updateLsArray('INI_AT__USER_FAVORITES', favorites)
    }
    return favorites
  })
}

export const getSocialUrl = (href?: string, socialHref?: SocialHref) => {
  if (href && socialHref) {
    try {
      const url = new URL(href)
      if (url.origin == socialHref.baseUrl) {
        return url.href
      }
    } catch {
      return `${socialHref.profileUrl}${href}`
    }
  }
}

export const getUserActionData = async (actionsTaken: UserActionHistory[]) => {
  const actionSlugs = actionsTaken.map(({ action_slug }) => action_slug)
  const actionIds = actionsTaken
    .map(({ user_action_id }) => user_action_id)
    .filter(exists => exists) as string[]

  const actions = (await fetchActions(actionSlugs)) || []
  const userActions = // @ts-expect-error: existing type issue
    (
      await getDocsFromCollection<UserActionDoc>('user_actions', createDocumentIdQuery(actionIds))
    ).data.map(doc => ({
      ...doc,
      date_completed: doc?.date_completed?.toDate()
    }))

  return actions.map(data => {
    const userAction = userActions.find(
      action => action.action_slug && action.action_slug == data.slug?.current
    )
    return {
      ...data,
      date_completed: userAction?.date_completed
    } as UserActionHistoryData
  })
}

export const getActionsTakenByUsers = async (ids: string[]): Promise<UserFriendFeedData> => {
  const profiles = // @ts-expect-error: existing type issue
    (
      await getDocsFromCollection<ActionToolAccountWithId>('accounts', createDocumentIdQuery(ids))
    ).data.reduce((publicAccounts, account) => {
      // if user either has no hide history setting or it doesn't equal true
      if (!(account.user?.settings.privacy?.hide_history == true)) {
        publicAccounts.push(account)
      }
      return publicAccounts
    }, [] as ActionToolAccountWithId[])

  const publicIds = profiles.map(({ id }) => id)

  // fetch the user action docs
  const actions = await getDocsFromCollection<UserActionDoc>(
    'user_actions',
    // @ts-expect-error: existing type issue
    createInArrayQuery('user_id', publicIds),
    {
      orderBy: 'date_completed',
      desc: true
    }
  )

  const actionsTaken = actions.data.map(
    data => ({ ...data, date_completed: data.date_completed?.toDate() }) as UserAction
  )

  const actionsToFetch = Array.from(new Set(actionsTaken.map(({ action_slug }) => action_slug)))
  const actionData = await fetchActions(actionsToFetch)

  return actionsTaken.map(actionTaken => {
    const action = actionData?.find(({ slug }) => slug?.current === actionTaken.action_slug)
    const profile = profiles.find(({ id }) => id === actionTaken.user_id)
    return {
      action,
      profile,
      ...actionTaken
    }
  })
}

export const getActionHistory = (account?: ActionToolAccountWithId) => {
  return account?.user?.action_history || []
}

export const setVerificationModalShown = async (uid: string, shown: boolean) => {
  const dateShown = shown ? new Date() : null
  await syncAccountData(uid, {
    'stripe_verification.modal_date': dateShown
  })
}

export const toggleActionTaken = async (
  slug: string,
  taken: boolean,
  account?: ActionToolAccountWithId
) => {
  return await attempt(async () => {
    const actionHistory: UserActionHistory | PartialUserActionHistory[] = [
      ...getActionHistory(account)
    ]
    const actionCurrentlyTaken = actionHistory.find(({ action_slug }) => action_slug === slug)
    const uid = account?.id

    if (taken && !actionCurrentlyTaken) {
      // create new action taken record

      const data = {
        action_slug: slug,
        date_completed: new Date(),
        user_id: uid,
        user_name: account?.user?.profile?.public?.name
      }

      const userAction: PartialUserActionHistory = {
        action_slug: slug
      }
      if (uid) {
        const actionId = slug + '-' + account.id
        userAction.user_action_id = actionId

        // add action to user_actions collection
        await createDoc('user_actions', actionId, data)
      }
      actionHistory.push(userAction)
    } else if (!taken && actionCurrentlyTaken) {
      // remove action from record

      const actionIndex = actionHistory.findIndex(({ action_slug }) => action_slug === slug)
      const removed = actionHistory.splice(actionIndex, 1)

      removed.forEach(async doc => {
        if (doc.user_action_id) {
          // remove action from user_actions collection
          await removeDoc('user_actions', doc.user_action_id)
        }
      })
    }

    if (uid) {
      // update account data
      await syncAccountData(account.id, {
        'user.action_history': actionHistory
      })
    }

    return actionHistory
  })
}

export const syncStripeAndBeehiivData = async (accountId: string, email: string) => {
  const updates: { [x: string]: FieldValue | Partial<unknown> | null }[] = []
  // we only want to update the user's stripe data if the request to get
  // the latest data does not fail; if it succeeds without finding an existing
  // user, then we get set the stripe data to null
  await attempt(
    async () => {
      const { customer } = (await getCustomerFromEmail(email)) || {}

      updates.push({ stripe_customer_id: customer?.id || null })
    },
    err => console.log(err || `Could not fetch ${email} from Beehiiv `)
  )

  // we only want to update the user's beehiiv data if the request to get
  // the latest data does not fail; if it succeeds without finding an existing
  // user, then we get set the beehiiv data to null
  await attempt(
    async () => {
      const beehiivUser = await getUserFromEmail(email)
      updates.push(
        { beehiiv_id: beehiivUser?.id || null },
        { premium_access: Boolean(beehiivUser?.subscription_tier === PremiumTier) }
      )
    },
    err => console.log(err || `Could not fetch ${email} from Beehiiv `)
  )

  if (updates.length > 0) {
    await syncAccountData(accountId, Object.assign({}, ...updates))
  }
}

export const generateUniqueFriendlyId = async (uid: string, name: string) => {
  let userId = slugify(name)
  const existingUsers = await getAccountsWithUserId(userId)

  const usersNotCurrentUser = existingUsers.filter(({ id }) => id !== uid)
  const existingUserIds = usersNotCurrentUser.map(({ user_id }) => user_id)

  // hash length should be 1 place higher than the number of entries to
  // ensure there's always a potential unique hash
  const digits = String(existingUserIds.length).length + 1
  const hashLength = Math.max(digits, 4)

  while (existingUserIds.includes(userId)) {
    userId = `${userId}-${randomHash(hashLength)}`
  }
  return userId
}

export const createNewAccount = async (accountId: string, email: string) => {
  const { customer } =
    (await attempt(
      async () => await getCustomerFromEmail(email),
      err => err || `Could not fetch ${email} from Beehiiv `
    )) || {}

  // @ts-expect-error: existing type issue
  const { beehiivUser } =
    (await attempt(
      async () => await getUserFromEmail(email),
      err => err || `Could not fetch ${email} from Beehiiv `
    )) || {}

  const user_id = await generateUniqueFriendlyId(accountId, 'wciduser')

  await createAccountData(accountId, {
    email,
    beehiiv_id: beehiivUser?.id || null,
    stripe_customer_id: customer?.id || null,
    premium_access: Boolean(beehiivUser?.subscription_tier === PremiumTier),
    user_id,
    user: {
      action_history: [],
      saved_actions: [],
      profile: {
        public: { name: null },
        private: {}
      },
      settings: {
        is_private: true,
        privacy: {
          hide_history: false,
          hide_location: false,
          hide_pronouns: false,
          hide_skills_cobenefits: false,
          hide_socials: false
        }
      }
    }
  })
}

export const initializeAccount = async (
  accountId: string,
  email: string,
  onLoadedAccount?: (user: ActionToolAccountWithId) => void
) => {
  const existingAccount = await getAccount(accountId)
  if (existingAccount == false) {
    // create account first if this is the first time user is logging in
    await createNewAccount(accountId, email)
    await observeAccountData(accountId, onLoadedAccount)
  } else if (existingAccount) {
    onLoadedAccount(existingAccount)
    await observeAccountData(accountId, onLoadedAccount)
    // ensure all third party authUser data is synced
    await syncStripeAndBeehiivData(accountId, email)
  }
}

export const initializeUserVerification = async (
  client: Stripe,
  authUser: User,
  publicIntent: boolean
) => {
  await syncAccountData(authUser.uid, {
    'stripe_verification.public_intent': Boolean(publicIntent)
  })

  if (process.env.GATSBY_VERIFICATION_MODE == 'live') {
    await attempt(async () => {
      const clientSecret = await createStripeVerificationSession(authUser.uid)

      if (clientSecret) {
        const session = await client.verifyIdentity(clientSecret)
        const { error } = session
        if (error) {
          throw new Error(
            `We weren't able to complete your verification attempt; please try again.`
          )
        }
      } else {
        throw new Error('Unable to create Stripe verification session')
      }
    })
  } else {
    if (authUser.email) {
      await testVerification(authUser.email, 'Conor Britain')
    } else {
      throw new Error('No email for authUser found!')
    }
  }
}

export const checkUserVerification = async (session_id: string) => {
  await checkVerification(session_id)
}

export const stripPrivateData = (doc: ActionToolUserWithId) => {
  if (doc.settings?.is_private) return
  if (doc.settings?.privacy?.hide_location == true) {
    delete doc.profile.private.location
    delete doc.profile.private.location_data
  }
  if (doc.settings?.privacy?.hide_pronouns == true) {
    delete doc.profile.private.pronouns
  }
  if (doc.settings?.privacy?.hide_skills_cobenefits == true) {
    delete doc.profile.private.skills
    delete doc.profile.private.cobenefits
  }
  if (doc.settings?.privacy?.hide_socials == true) {
    delete doc.profile.private.website
    delete doc.profile.private.linkedin
    delete doc.profile.private.threads
    delete doc.profile.private.twitter
    delete doc.profile.private.bluesky
  }
  if (doc.settings?.privacy?.hide_history == true) {
    delete doc.action_history
  }
  return doc
}

export const removeUserVerfication = async (account?: ActionToolAccountWithId) => {
  if (process.env.GATSBY_VERIFICATION_MODE == 'live') {
    if (account?.stripe_verification?.session_id) {
      await cancelStripeVerificationSession(account.stripe_verification.session_id)
    }
  } else {
    if (account?.email) {
      await cancelTest(account.email)
    } else {
      throw new Error('No account email found')
    }
  }
}
