import { PlaceOutlined } from '@mui/icons-material'
import { Badge, Box, IconButton, styled } from '@mui/material'
import * as React from 'react'
import { colors } from '../constants/colors'

import { resultsState } from '../context/results-context'
import FullScreenModal from './full-screen-modal'
import LocationControls from './location-controls'

const LocationBox = () => {
  const { filters } = resultsState()
  const { locations, expandLocations } = filters
  // const { address, prioritizeLocation, restrictToLocation } = settings
  const [showFilterModal, setShowFilterModal] = React.useState(false)
  const closeModal = () => {
    setShowFilterModal(false)
  }

  const filterCount = [...(locations || []).map(location => location), expandLocations].filter(
    exists => exists
  ).length

  return (
    <LocationBoxElement>
      <StyledBadge color="primary" badgeContent={filterCount}>
        <StyledIconButton
          sx={{ width: { xs: 40, md: 50 }, height: { xs: 40, md: 50 } }}
          aria-label={`Location Filters`}
          onClick={() => setShowFilterModal(true)}
        >
          <PlaceOutlined sx={{ color: 'currentcolor' }} fontSize="large" />
        </StyledIconButton>
      </StyledBadge>

      <FullScreenModal
        open={showFilterModal}
        colorScheme="grey"
        contentPadding={false}
        onClose={closeModal}
      >
        <LocationControls onSave={closeModal} />
      </FullScreenModal>
    </LocationBoxElement>
  )
}

const StyledBadge = styled(Badge)`
  pointer-events: none;
`

const StyledIconButton = styled(IconButton)`
  ${({ theme }) => {
    const background = theme.palette.secondary.main
    const color = colors.WHITE
    const padding = theme.spacing(1)
    return `
      background-color: ${background};
      border-radius: 30%;
      pointer-events: all;
      color: ${color};
      padding: ${padding};
      &:hover {
        background-color: ${background};
      };
  `
  }}
`

const LocationBoxElement = styled(Box)`
  ${({ theme }) => {
    return `
        display: inline-flex;
        justify-content: center;
        align-items: center;
        white-space: nowrap;

        background: ${theme.palette.mode == 'dark' ? colors.DARK_MODE_70 : colors.WHITE};
    `
  }}
`

export default LocationBox
