import { Button, ButtonProps, styled } from '@mui/material'
import React from 'react'

const StandardButton: React.FC<ButtonProps> = ({ ...rest }) => {
    return (<ResponsiveButton {...rest} size='small' />)
}

const ResponsiveButton = styled(Button)(({ theme }) => ({
    [theme.breakpoints.down("sm")]: {
        flex: 1,
        fontSize: "16px",
        lineHeight: "17.6px",
        borderRadius: "12px",
        "& .MuiButton-startIcon, .MuiButton-endIcon": {
            width: "24px",
            height: "24px",
        },
    },
}));

export default StandardButton
