import { BeehiivUser } from '../../types/beehiiv-types'
import { fetchNetlify } from '../netlify'

export const getUserFromEmail = async (email: string) =>
  await fetchNetlify<BeehiivUser>(`/.netlify/functions/beehiiv-account-lookup?email=${email}`)

export const createBeehiivUser = async (email: string, stripe_customer_id: string) =>
  await fetchNetlify<BeehiivUser>(
    `/.netlify/functions/beehiiv-account-create?email=${email}&stripe_customer_id=${stripe_customer_id}`
  )

export const updateSubscriberMetadata = async (
  subscription_id?: string | null,
  {
    profile_url,
    cobenefits
  }: {
    profile_url?: string
    cobenefits?: string
  } = {}
) => {
  let fetchUrl = `/.netlify/functions/beehiiv-update_subscriber_metadata?subscription_id=${subscription_id}`

  if (profile_url) {
    fetchUrl += `&profile_url=${profile_url}`
  }
  if (cobenefits !== undefined) {
    fetchUrl += `&cobenefits=${cobenefits == '' ? 'none' : cobenefits}`
  }
  await fetchNetlify(fetchUrl)
}
