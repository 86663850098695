import { Info, InfoOutlined } from '@mui/icons-material'
import React, { Fragment, ReactNode } from 'react'
import { fetchPersonalizedActions, fetchRecentActions, hasDataForPersonalizedActions } from '../../async/sanity'
import { ActionToolAccountWithId } from '../../types/firebase-types'
import { SanitySuggestedActionResult } from '../../types/sanity-types'
import { shuffleArray } from '../../utilities/array'
import { getActionSentenceFromFilters } from '../../utilities/sanity-filters'
import { capitalizeFirstLetter } from '../../utilities/string'
import ActionSentence from '../action-sentence'

export enum SuggestionType {
  FEATURED = 'featured',
  PERSONAL = 'personal',
  HOT = 'hot',
  NEW = 'new'
}

export enum SuggestionDestination {
  ACTION = 'action',
  DYNAMIC = 'dynamic',
  URL = 'url'
}
export type SuggestedActionConfig = {
  destination?: SuggestionDestination
  type?: SuggestionType
  cardProps: SuggestedActionCardProps
}
export type SuggestedActionCardContent = {
  title?: ReactNode
  description?: ReactNode
  image?: ReactNode
  url?: string
}
export type SuggestedActionCardProps = {
  // assetUrl?: string
  // gatsbyImageData?: GatsbyImageProps
  loading?: boolean
  loadingMessage?: string
  error?: string
  actionData?: Queries.SanityAction
  type?: SuggestionType
  content: SuggestedActionCardContent
}

const errorCardData = {
  loading: false,
  error: 'Could not load this action.'
}

const createCardData = (
  existing: SuggestedActionCardProps,
  action?: SanitySuggestedActionResult
) => {
  const newContent: SuggestedActionCardContent = {}
  if (action) {
    const verbFragment =
      getActionSentenceFromFilters(
        { verbs: shuffleArray(action?.verbs) },
        { verbReplacements: [{ match: 'Be heard about', replacement: 'Be heard:' }] }
      ).verbs || []
    console.log('verb Frag:', verbFragment)
    newContent.title =
      capitalizeFirstLetter(verbFragment[0] || '') + ' ' + (action?.data?.title ?? '')

    newContent.image = action?.organization?.logo?.asset?.url && (
      <img
        src={action?.organization?.logo?.asset?.url}
        style={{
          width: '100%',
          height: '100%',
          objectFit: 'contain'
        }}
      />
    )
  }
  return {
    ...existing,
    loading: false,
    actionData: action?.data,
    content: {
      ...existing.content,
      ...newContent
    }
  }
}

export const buildNewActionCards = async (
  cardData: SuggestedActionConfig[],
  updateCards: (cards: SuggestedActionCardProps[], indeces: number[]) => void
) => {
  const cardIndeces = cardData.reduce(
    (indeces, { type, destination }, i) =>
      type === SuggestionType.NEW && destination === SuggestionDestination.DYNAMIC
        ? [...indeces, i]
        : indeces,
    [] as number[]
  )
  if (cardIndeces.length > 0) {
    try {
      const recentActions = await fetchRecentActions(cardIndeces.length)
      const updates =
        recentActions?.map((action, i) =>
          createCardData(cardData[cardIndeces[i]].cardProps, action)
        ) || []

      updateCards(updates, cardIndeces)
    } catch {
      updateCards(
        cardIndeces.map(i => {
          return { ...cardData[i].cardProps, ...errorCardData }
        }),
        cardIndeces
      )
    }
  }
}

export const buildPersonalActionCards = async (
  cardData: SuggestedActionConfig[],
  updateCards: (cards: SuggestedActionCardProps[], indeces: number[]) => void,
  accountLoading: boolean,
  account?: ActionToolAccountWithId
) => {
  const cardIndeces = cardData.reduce(
    (indeces, { type }, i) => (type === SuggestionType.PERSONAL ? [...indeces, i] : indeces),
    [] as number[]
  )
  if (cardIndeces.length > 0 && !accountLoading) {
    if (!account || !hasDataForPersonalizedActions(account)) {
      updateCards(
        cardIndeces.map(i => {
          return { ...cardData[i].cardProps, loading: false }
        }),
        cardIndeces
      )
    } else {
      try {
        updateCards(
          cardIndeces.map(i => {
            return { ...cardData[i].cardProps, loading: true }
          }),
          cardIndeces
        )
        const personalActions = await fetchPersonalizedActions(cardIndeces.length, account)
        const updates =
          cardIndeces?.map((index, i) => {
            const action = personalActions && personalActions[i]
            const existing = cardData[index].cardProps
            const newCardData = createCardData(existing, action)
            const verbShuffled = (shuffleArray(action?.verbs) ?? [])[0]
            const nounShuffled = (shuffleArray(action?.nouns) ?? [])[0]
            const cobenefitShuffled = (shuffleArray(action?.cobenefits) ?? [])[0]
            const skillShuffled = (shuffleArray(action?.skills) ?? [])[0]
            const description = action
              ? {
                  description: (
                    <ActionSentence
                      beginning={
                        <Fragment>
                          <Info fontSize="small" sx={{ display: 'inlineBlock' }} /> Because you want
                          to
                        </Fragment>
                      }
                      placeholders={{
                        verb: (
                          <Fragment>
                            <InfoOutlined
                              fontSize="small"
                              sx={{ display: 'inlineBlock', verticalAlign: 'text-bottom' }}
                            />{' '}
                            Because you follow
                          </Fragment>
                        ),
                        noun: 'causes'
                      }}
                      variant="body1"
                      fontSize={14}
                      color="currentcolor"
                      filters={{
                        // all the rigamarole below is to make sure we only have
                        // one entry per action sentence fragment, and to add
                        // some variety
                        verbs: verbShuffled && [verbShuffled],
                        nouns: nounShuffled && [nounShuffled],
                        cobenefits: cobenefitShuffled && [cobenefitShuffled],
                        skills: skillShuffled && [skillShuffled]
                      }}
                    />
                  )
                }
              : {}
            return {
              ...newCardData,
              content: {
                ...newCardData.content,
                ...description
              }
            }
          }) || []

        updateCards(updates, cardIndeces)
      } catch (err) {
        console.log('err:', err)

        updateCards(
          cardIndeces.map(i => {
            return { ...cardData[i].cardProps, ...errorCardData }
          }),
          cardIndeces
        )
      }
    }
  }
}
