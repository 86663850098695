import { CandidateCategories } from '../async/sanity/utils'

export const sitePaddingHorz = 6
export const sitePaddingVert = 3

export const candidateCategoryOptions = [
  {
    slug: CandidateCategories.ARE_ENDORSED_BY,
    title: 'are endorsed by',
    field: 'endorsements'
  },
  {
    slug: CandidateCategories.SUPPORT,
    title: 'support',
    field: 'nouns'
  }
]
