import { getSanitySlug } from '../async/sanity/utils'
import { LocationType } from '../types/location-types'
import { isInBrowser } from './browser'
import { addSentenceStructureToStringArray } from './string'

export type FilterPrimitives = {
  verbs?: string[]
  nouns?: string[]
  cobenefits?: string[]
  skills?: string[]
  endorsements?: string[]
  issues?: string[]
  mediums?: string[]
  levels?: string[]
  impacts?: string[]
  sorts?: string[]
  verbsExclusive?: boolean
  nounsExclusive?: boolean
  cobenefitsExclusive?: boolean
  endorsementsExclusive?: boolean
  skillsExclusive?: boolean
  issuesExclusive?: boolean

  locations?: string[]
  expandLocations?: boolean
}
export const getFiltersFromParamObject = (params: URLSearchParams): FilterPrimitives => {
  const nouns = params?.get('nouns')?.split(',') || undefined
  const verbs = params?.get('verbs')?.split(',') || undefined
  const cobenefits = params?.get('cobenefits')?.split(',') || undefined
  const skills = params?.get('skills')?.split(',') || undefined
  const endorsements = params?.get('endorsements')?.split(',') || undefined
  const issues = params?.get('issues')?.split(',') || undefined
  const mediums = params?.get('mediums')?.split(',') || undefined
  const levels = params?.get('levels')?.split(',') || undefined
  const impacts = params?.get('impacts')?.split(',') || undefined
  const sorts = params?.get('sorts')?.split(',') || undefined
  const verbsExclusiveParam = params.get('verbsExclusive')
  const verbsExclusive = verbsExclusiveParam !== undefined && verbsExclusiveParam === 'true'
  const nounsExclusiveParam = params.get('nounsExclusive')
  const nounsExclusive = nounsExclusiveParam !== undefined && nounsExclusiveParam === 'true'
  const cobenefitsExclusiveParam = params.get('cobenefitsExclusive')
  const cobenefitsExclusive =
    cobenefitsExclusiveParam !== undefined && cobenefitsExclusiveParam === 'true'
  const skillsExclusiveParam = params.get('skillsExclusive')
  const skillsExclusive = skillsExclusiveParam !== undefined && skillsExclusiveParam === 'true'
  const endorsementsExclusiveParam = params.get('endorsementsExclusive')
  const endorsementsExclusive =
    endorsementsExclusiveParam !== undefined && endorsementsExclusiveParam === 'true'
  const issuesExclusiveParam = params.get('issuesExclusive')
  const issuesExclusive = issuesExclusiveParam !== undefined && issuesExclusiveParam === 'true'

  // location specific

  // states
  const locations = params.get('locations')?.split(',') || undefined
  const expandLocationsParam = params.get('expandLocations')
  const expandLocations = expandLocationsParam !== undefined && expandLocationsParam === 'true'

  return {
    nouns,
    verbs,
    cobenefits,
    skills,
    endorsements,
    issues,
    mediums,
    levels,
    impacts,
    sorts,
    verbsExclusive,
    nounsExclusive,
    cobenefitsExclusive,
    skillsExclusive,
    endorsementsExclusive,
    issuesExclusive,
    locations,
    expandLocations
  }
}

export const parameterizeFilters = (filters: FilterPrimitives) => {
  const {
    nouns,
    verbs,
    cobenefits,
    endorsements,
    issues,
    mediums,
    skills,
    levels,
    impacts,
    sorts,
    verbsExclusive,
    nounsExclusive,
    cobenefitsExclusive,
    endorsementsExclusive,
    skillsExclusive,

    issuesExclusive,
    locations,
    expandLocations
  } = filters
  const nounParams = nouns && `nouns=${nouns}`
  const verbParams = verbs && `verbs=${verbs}`
  const endorsementParams = endorsements && `endorsements=${endorsements}`
  const issueParams = issues && `issues=${issues}`
  const mediumParams = mediums && `mediums=${mediums}`
  const cobenefitParams = cobenefits && `cobenefits=${cobenefits}`
  const skillParams = skills && `skills=${skills}`

  const impactParams = impacts && `impacts=${impacts}`
  const levelParams = levels && `levels=${levels}`
  const sortParams = sorts && `sorts=${sorts}`
  const verbsExclusiveParam = verbsExclusive && 'verbsExclusive=true'
  const nounsExclusiveParam = nounsExclusive && 'nounsExclusive=true'
  const endorsementsExclusiveParam = endorsementsExclusive && 'endorsementsExclusive=true'
  const issuesExclusiveParam = issuesExclusive && 'issuesExclusive=true'

  const cobenefitsExclusiveParam = cobenefitsExclusive && 'cobenefitsExclusive=true'
  const skillsExclusiveParam = skillsExclusive && 'skillsExclusive=true'

  const locationsParams = locations && `locations=${locations}`
  const expandLocationsParam = locations && expandLocations && 'expandLocations=true'

  const filtersParams = [
    verbParams,
    nounParams,
    cobenefitParams,
    skillParams,
    endorsementParams,
    issueParams,
    mediumParams,
    levelParams,
    impactParams,
    sortParams,
    verbsExclusiveParam,
    nounsExclusiveParam,
    cobenefitsExclusiveParam,
    skillsExclusiveParam,
    endorsementsExclusiveParam,
    issuesExclusiveParam,

    locationsParams,
    expandLocationsParam
  ].filter(exists => Boolean(exists))

  return filtersParams.join('&')
}

export type Filters = {
  nouns?: Queries.SanityNoun[]
  verbs?: Queries.SanityVerb[]
  cobenefits?: Queries.SanityCobenefits[]
  skills?: Queries.SanitySkill[]
  endorsements?: Queries.SanityOrganization[]
  issues?: Queries.SanityIssue[]
  mediums?: Queries.SanityMedium[]
  levels?: LocationType[]
  impacts?: string[]
  sorts?: string[]
  verbsExclusive?: boolean
  nounsExclusive?: boolean
  cobenefitsExclusive?: boolean
  skillsExclusive?: boolean
  endorsementsExclusive?: boolean
  issuesExclusive?: boolean

  locations?: string[]
  expandLocations?: boolean
}
export const fetchSanityFiltersFromParams = async (params: URLSearchParams): Promise<Filters> => {
  if (!isInBrowser()) {
    return {}
  }
  const {
    nouns,
    verbs,
    cobenefits,
    skills,
    endorsements,
    issues,
    mediums,
    levels,
    impacts,
    sorts,
    verbsExclusive,
    nounsExclusive,
    cobenefitsExclusive,
    skillsExclusive,
    issuesExclusive,
    endorsementsExclusive,
    locations,
    expandLocations
  } = getFiltersFromParamObject(params)
  const parameterizedFilters = parameterizeFilters({
    nouns,
    verbs,
    cobenefits,
    endorsements,
    issues,
    mediums,
    skills,
  })
  const filtersRequest = await fetch(
    `/.netlify/functions/sanity-filters-from-params?${parameterizedFilters}`
  ).catch(error => {
    throw new Error(error)
  })
  const {
    nouns: nounFilters,
    verbs: verbFilters,
    cobenefits: cobenefitsFilters,
    endorsements: endorsementsFilters,
    issues: issuesFilters,
    skills: skillsFilters,
    mediums: mediumsFilters
  } = (await filtersRequest?.json()) as {
    nouns: Queries.SanityNoun[] | undefined
    verbs: Queries.SanityVerb[] | undefined
    cobenefits: Queries.SanityCobenefits[] | undefined
    endorsements: Queries.SanityOrganization[] | undefined
    issues: Queries.SanityIssue[] | undefined
    skills: Queries.SanitySkill[] | undefined
    mediums: Queries.SanityMedium[] | undefined
  }
  return {
    nouns: nounFilters,
    verbs: verbFilters,
    cobenefits: cobenefitsFilters,
    endorsements: endorsementsFilters,
    issues: issuesFilters,
    skills: skillsFilters,
    mediums: mediumsFilters,
    levels: levels as LocationType[],
    impacts,
    sorts,
    verbsExclusive,
    nounsExclusive,
    cobenefitsExclusive,
    skillsExclusive,
    endorsementsExclusive,
    issuesExclusive,
    locations: locations,
    expandLocations
  }
}

type WordReplacement = { match: string; replacement: string }
type SentenceConfig = {
  verbReplacements?: WordReplacement[]
}

const replaceText = (text: string, replacements?: WordReplacement[]) =>
  replacements?.find(({ match }) => match.toLowerCase() === text.toLowerCase())?.replacement

export const convertFiltersToParamObject = (filters: Filters) => {
  const {
    nouns,
    verbs,
    cobenefits,
    skills,
    endorsements,
    issues,
    mediums,
    impacts,
    levels,
    sorts,
    verbsExclusive,
    nounsExclusive,
    cobenefitsExclusive,
    skillsExclusive,
    endorsementsExclusive,
    issuesExclusive,

    locations,
    expandLocations
  } = filters

  return {
    nouns: nouns && (nouns.map(({ slug }) => getSanitySlug(slug)) as string[]),
    verbs: verbs && (verbs.map(({ slug }) => getSanitySlug(slug)) as string[]),
    cobenefits: cobenefits && (cobenefits.map(({ slug }) => getSanitySlug(slug)) as string[]),
    skills: skills && (skills.map(({ title }) => title) as string[]),
    endorsements: endorsements && (endorsements.map(({ slug }) => getSanitySlug(slug)) as string[]),
    issues: issues && (issues.map(({ slug }) => getSanitySlug(slug)) as string[]),
    mediums: mediums && (mediums.map(({ slug }) => getSanitySlug(slug)) as string[]),
    impacts,
    levels,
    sorts,
    verbsExclusive,
    nounsExclusive,
    cobenefitsExclusive,
    skillsExclusive,
    endorsementsExclusive,
    issuesExclusive,
    locations,
    expandLocations: locations && expandLocations
  }
}

export const getActionSentenceFromFilters = (filters: Filters, sentenceConfig?: SentenceConfig) => {
  const verbTitles =
    filters.verbs &&
    (
      filters.verbs?.map(
        ({ title, preposition }) => title + (preposition ? ' ' + preposition : '')
      ) as string[]
    ).map(title => replaceText(title, sentenceConfig?.verbReplacements) || title)

  const testVerbs =
    verbTitles &&
    addSentenceStructureToStringArray(verbTitles, filters.verbsExclusive ? 'and' : 'or')

  const nounTitles =
    filters?.nouns?.length > 0 && (filters.nouns?.map(({ title }) => title) as string[])
  const cobenefitTitles =
    filters.cobenefits?.length > 0 && (filters.cobenefits?.map(({ title }) => title) as string[])

  const endorsementTitles =
    filters.endorsements?.length > 0 &&
    (filters.endorsements?.map(({ title }) => title) as string[])

  const issueTitles =
    filters.issues?.length > 0 && (filters.issues?.map(({ title }) => title) as string[])
  const skillTitles =
    filters.skills?.length > 0 && (filters.skills?.map(({ title }) => title) as string[])

  return {
    verbs: testVerbs,
    nouns:
      nounTitles &&
      addSentenceStructureToStringArray(nounTitles, filters.nounsExclusive ? 'and' : 'or'),
    cobenefits:
      cobenefitTitles &&
      addSentenceStructureToStringArray(
        cobenefitTitles,
        filters.cobenefitsExclusive ? 'and' : 'or'
      ),
    skills:
      skillTitles &&
      addSentenceStructureToStringArray(skillTitles, filters.skillsExclusive ? 'and' : 'or'),
    endorsements:
      endorsementTitles &&
      addSentenceStructureToStringArray(
        endorsementTitles,
        filters.endorsementsExclusive ? 'and' : 'or'
      ),
    issues:
      issueTitles &&
      addSentenceStructureToStringArray(issueTitles, filters.issuesExclusive ? 'and' : 'or')
  }
}
