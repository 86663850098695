import { Backdrop, CircularProgress } from '@mui/material'
import * as React from 'react'
const LoadingScreen: React.FC<{
  loading: boolean
  hideProgress?: boolean
  scheme?: 'dark' | 'light'
  hideBackground?: boolean
}> = ({ loading, hideProgress, scheme = 'dark', hideBackground }) => {
  return (
    <Backdrop
      sx={{
        color: scheme == 'dark' ? '#fff' : '#000',
        backgroundColor: hideBackground
          ? 'rgba(0,0,0,0)'
          : scheme == 'dark'
            ? 'rgba(0,0,0,0.5)'
            : 'rgba(255,255,255,0.5)',
        position: 'fixed',
        zIndex: theme => theme.zIndex.drawer + 1
      }}
      open={loading}
    >
      {!hideProgress && <CircularProgress color="inherit" />}
    </Backdrop>
  )
}

export default LoadingScreen
