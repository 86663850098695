import { Badge, Box, IconButton, styled } from '@mui/material'
import * as React from 'react'
import { colors } from '../constants/colors'
import { actionDataState } from '../context/action-data-context'
import { candidateDataState } from '../context/candidate-data-context'
import { resultsState } from '../context/results-context'
import FilterIcon from '../images/icons/filter-icon'
import CenteredModal from './centered-modal'
import Confirmation from './confirmation'
import FilterControls from './filter-controls'
import FullScreenModal from './full-screen-modal'

const FilterBox = ({ open, onClose }: { open: boolean; onClose?: () => void }) => {
  const { totalActions = 0 } = actionDataState()
  const { totalCandidates = 0 } = candidateDataState()
  const { filters } = resultsState()
  const [showFilterModal, setShowFilterModal] = React.useState(open)

  React.useEffect(() => {
    setShowFilterModal(open)
  }, [open])

  const activeFilters = [
    filters.verbsExclusive && 1,
    filters.nounsExclusive && 1,
    filters.cobenefitsExclusive && 1,
    filters.skillsExclusive && 1,
    filters.endorsementsExclusive && 1,
    filters.issuesExclusive && 1,
    filters.verbs && filters.verbs.length,
    filters.nouns && filters.nouns?.length,
    filters.cobenefits && filters.cobenefits?.length,
    filters.skills && filters.skills?.length,
    filters.mediums && filters.mediums?.length,
    filters.levels && filters.levels?.length,
    filters.sorts && filters.sorts?.length,
    filters.endorsements && filters.endorsements?.length,
    filters.issues && filters.issues?.length
  ].filter(exists => Boolean(exists))

  const filterCount = (activeFilters as number[]).reduce((a: number, b: number) => a + b, 0)

  const totalResults = totalActions + totalCandidates
  const plural = totalResults === 1 ? '' : 's'
  const activeFilterText =
    totalResults === 0 ? 'No Active Filters' : totalResults + `Active Filter${plural}`

  // ------------------------------------------------
  // Editor state management
  // ------------------------------------------------

  const [hasMadeEdits, setHasMadeEdits] = React.useState(false)
  const [showUnsavedWarning, setShowUnsavedWarning] = React.useState(false)

  const closeModal = () => {
    setShowFilterModal(false)
    if (onClose) {
      onClose()
    }
  }

  const carefullyCloseEditor = React.useCallback(() => {
    if (hasMadeEdits) {
      setShowUnsavedWarning(true)
    } else {
      closeModal()
    }
  }, [hasMadeEdits])

  const hideWarningModal = () => {
    setShowUnsavedWarning(false)
  }

  const closeWithoutSaving = () => {
    setShowUnsavedWarning(false)
    setHasMadeEdits(false)
    closeModal()
  }

  return (
    <FilterBoxElement>
      <StyledBadge color="primary" badgeContent={filterCount}>
        <StyledIconButton
          sx={{ width: { xs: 42, md: 50 }, height: { xs: 42, md: 50 } }}
          aria-label={`Search Filters - ${activeFilterText}`}
          onClick={() => setShowFilterModal(true)}
        >
          <FilterIcon />
        </StyledIconButton>
      </StyledBadge>

      <FullScreenModal
        open={showFilterModal}
        colorScheme="grey"
        contentPadding={false}
        onClose={carefullyCloseEditor}
        allowInnerScroll
      >
        <FilterControls onSave={closeModal} onEdit={() => setHasMadeEdits(true)} />
      </FullScreenModal>

      <CenteredModal open={showUnsavedWarning} onClose={hideWarningModal}>
        <Confirmation
          onDeny={closeWithoutSaving}
          onConfirm={hideWarningModal}
          title={`You have unsaved filters!`}
          description="Are you sure you want to close without applying your new filters?"
          denyText="don't apply"
          confirmText="i'll save"
        />
      </CenteredModal>
    </FilterBoxElement>
  )
}

const StyledBadge = styled(Badge)`
  pointer-events: none;
`

const StyledIconButton = styled(IconButton)`
  ${({ theme }) => {
    const background = theme.palette.secondary.main
    const color = colors.GEORGIAN_BAY_100
    const padding = theme.spacing(1)
    return `
      background-color: ${background};
      border-radius: 30%;
      pointer-events: all;
      color: ${color};
      padding: ${padding};
      &:hover {
        background-color: ${background};
      };
  `
  }}
`

const FilterBoxElement = styled(Box)`
  ${({ theme }) => {
    return `
        display: inline-flex;
        justify-content: center;
        align-items: center;
        white-space: nowrap;

        background: ${theme.palette.mode == 'dark' ? colors.DARK_MODE_70 : colors.WHITE};
    `
  }}
`

export default FilterBox
