import { Modal, Slide, styled, useTheme } from '@mui/material'
import { Box } from '@mui/system'
import React, { PropsWithChildren, useLayoutEffect, useState } from 'react'
import { colors } from '../constants/colors'
import { CoverBox, SquareButton, VBox } from '../elements/basic-elements'
import CloseIcon from '../images/icons/close-icon'
import { isInBrowser } from '../utilities/browser'
import { excludeScProps } from '../utilities/browser'
import { MODAL_WIDTH_MEDIUM } from '../constants/sizes'

type ColorSchemes = 'blue' | 'grey' | 'white'

const FullScreenModal: React.FC<
  PropsWithChildren & {
    open: boolean
    onClose: () => void
    allowInnerScroll?: boolean
    contentPadding?: boolean
    colorScheme?: ColorSchemes
    size?: number
  }
> = ({
  children,
  open,
  allowInnerScroll = false,
  contentPadding = true,
  colorScheme = 'blue',
  size = MODAL_WIDTH_MEDIUM,
  onClose
}) => {
  const [slideDirection, setSlideDirection] = useState<'up' | 'left'>('up')
  const theme = useTheme()

  useLayoutEffect(() => {
    if (isInBrowser()) {
      const setDirection = () => {
        const { innerWidth } = window
        setSlideDirection(innerWidth > theme.breakpoints.values.md ? 'left' : 'up')
      }
      setDirection()
      window.addEventListener('resize', setDirection)
      return () => window.removeEventListener('resize', setDirection)
    }
  }, [])
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={onClose}
      closeAfterTransition
      sx={{
        position: 'fixed',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        zIndex: theme => theme.zIndex.modal
      }}
    >
      <Slide direction={slideDirection} in={open} timeout={500} easing="cubic-bezier(0, 0, 0, 1)">
        <ModalContainer
          modalSize={size}
          sx={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            overflow: 'auto'
          }}
          colorScheme={colorScheme}
        >
          <Box
            position="absolute"
            zIndex={10}
            right={theme => theme.spacing(2)}
            top={theme => theme.spacing(2)}
          >
            <SquareButton
              variant="text"
              disableElevation
              color="secondary"
              onClick={() => {
                onClose && onClose()
              }}
              sx={{ position: 'relative', overflow: 'hidden' }}
            >
              <CoverBox sx={{ background: 'white', opacity: 0.4 }}></CoverBox>
              <Box sx={{ position: 'relative' }}>
                <CloseIcon />
              </Box>
            </SquareButton>
          </Box>
          <VBox
            sx={{ pt: theme => (contentPadding ? theme.spacing(10) : 0) }}
            height={allowInnerScroll ? '100%' : undefined}
            minHeight="100%"
          >
            {children}
          </VBox>
        </ModalContainer>
      </Slide>
    </Modal>
  )
}

const getColors = (colorScheme: ColorSchemes) => {
  const schemes = {
    blue: {
      background: colors.GLACIER_BLUE_70,
      backgroundDark: colors.DARK_MODE_80
    },
    grey: {
      background: colors.BLACK_10,
      backgroundDark: colors.DARK_MODE_80
    },
    white: {
      background: '#FFFFFF',
      backgroundDark: colors.DARK_MODE_80
    }
  }
  return schemes[colorScheme]
}

const ModalContainer = styled(Box, excludeScProps('colorScheme'))<{
  colorScheme: ColorSchemes
  modalSize: number
}>`
  ${({ theme, modalSize, colorScheme }) => {
    const schemeColors = getColors(colorScheme)
    return `
      background: ${
        theme.palette.mode == 'dark' ? schemeColors.backgroundDark : schemeColors.background
      };

      ${theme.breakpoints.up('md')} {
          max-width: calc(100% - ${theme.spacing(4)});
          width: ${modalSize}px;
          right: ${theme.spacing(2)};
          top: ${theme.spacing(2)};
          bottom: ${theme.spacing(2)};
          height: auto;
          border-radius: ${theme.shape.borderRadius}px;
      }
    `
  }}
`

export default FullScreenModal
