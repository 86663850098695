export const createEmptyArray = (length: number) => Array.apply(null, Array(length))

export const shuffleArray = <T>(array?: T[]) => {
  if (!array) return
  let currentIndex = array.length,
    randomIndex

  while (currentIndex > 0) {
    randomIndex = Math.floor(Math.random() * currentIndex)
    currentIndex--
    ;[array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]]
  }

  return array
}

export const removeDuplicates = <T>(array: T[]) => {
  const uniqueItems = new Set([...array])

  return Array.from(uniqueItems)
}

export const getRandomIndex = <T>(array: T[]): number => {
  return Math.floor(Math.random() * array.length)
}
