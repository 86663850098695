import React from 'react'
import { actionSelectorState } from '../../context/action-selector-context'
import { FormFragment, FormFragmentInput } from '../../styles/styled-components/action-selector'
import ActionInput, { ActionInputPropsBase, InputOption } from './action-input'

const CandidateEndorsementSelect = ({ suggestedSearchResultsPortal }: ActionInputPropsBase) => {
  const { loading, optionsEndorsements, selectedEndorsement, setSelectedEndorsement } =
    actionSelectorState()
  return (
    <FormFragment>
      <FormFragmentInput>
        <ActionInput
          id={'candidate-endorsement'}
          loading={loading && !selectedEndorsement}
          options={optionsEndorsements?.map(organization => {
            return {
              slug: organization.slug?.current,
              title: organization.title
            }
          })}
          value={
            selectedEndorsement && {
              slug: selectedEndorsement.slug?.current,
              title: selectedEndorsement.title
            }
          }
          placeholder={'find an organization'}
          suggestedSearchResultsPortal={suggestedSearchResultsPortal}
          onCloseClick={() => {
            setSelectedEndorsement && setSelectedEndorsement(undefined)
          }}
          onValueChanged={(value?: InputOption) => {
            setSelectedEndorsement &&
              setSelectedEndorsement(
                optionsEndorsements?.find(({ slug }) => slug?.current === value?.slug)
              )
          }}
        />
      </FormFragmentInput>
    </FormFragment>
  )
}

export default CandidateEndorsementSelect
