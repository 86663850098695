import { InfoOutlined } from '@mui/icons-material'
import { Box, Checkbox, Tooltip, Typography, useTheme } from '@mui/material'
import React, { Fragment } from 'react'
import { colors } from '../../constants/colors'
import { actionSelectorState } from '../../context/action-selector-context'
import { HBox } from '../../elements/basic-elements'
import {
  FormFragment,
  FormFragmentInput,
  FormFragmentText,
  TextFragment,
  TooltipContainer
} from '../../styles/styled-components/action-selector'
import InputComponent from '../input'
import TooltipTitle from '../tooltip-title'

const LocationInput = () => {
  const { location, setLocation, expandLocations, setExpandLocations } = actionSelectorState()
  const theme = useTheme()
  return (
    <Fragment>
      <FormFragment>
        <FormFragmentText>
          <TooltipContainer>
            <TextFragment variant="h2" color="textPrimary">
              in
              <Tooltip
                enterTouchDelay={0}
                placement="top"
                title={
                  <TooltipTitle
                    description={[
                      'This helps us find actions near you and look up your representatives.'
                    ]}
                  />
                }
              >
                <InfoOutlined sx={{ ml: 0.5 }} fontSize="small" />
              </Tooltip>
            </TextFragment>
          </TooltipContainer>
        </FormFragmentText>
        <FormFragmentInput>
          <InputComponent
            hasValue={Boolean(location)}
            onCloseClick={() => {
              setLocation(undefined)
            }}
            background={theme.palette.mode == 'dark' ? colors.DARK_MODE_50 : colors.BLACK_10}
          >
            {({ Input }) => {
              return (
                <Box
                  sx={{
                    padding: theme => `${theme.spacing(1)} 0`
                  }}
                >
                  <Input
                    placeholder={'city/state (optional)'}
                    style={{
                      // emulating h4 fonts styles; need to just tie this in to the
                      // typography theme
                      fontFamily: 'Hco Gotham',
                      letterSpacing: '-0.03em',
                      minWidth: '100%',
                      lineHeight: 1.3,
                      fontSize: 20,
                      textOverflow: 'ellipsis'
                    }}
                    value={location}
                    onBlur={() => {
                      setLocation(location)
                    }}
                    onKeyDown={e => {
                      if (e.key === 'Enter') {
                        setLocation(location)
                      }
                    }}
                    onChange={e => {
                      setLocation(e.target.value)
                    }}
                  />
                </Box>
              )
            }}
          </InputComponent>
        </FormFragmentInput>
      </FormFragment>

      {location && (
        <HBox justifyContent="flex-end" alignItems="center">
          <Checkbox
            size="small"
            color="info"
            checked={!expandLocations}
            onChange={e => {
              setExpandLocations(!e.target.checked)
            }}
          />
          <Typography fontSize={12} color="textPrimary" sx={{ ml: -0.25 }}>
            Restrict results to my location
          </Typography>
        </HBox>
      )}
    </Fragment>
  )
}

export default LocationInput
