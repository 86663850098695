import { LocationType } from '../../types/location-types'
import { removeDuplicates } from '../../utilities/array'

export enum CandidateCategories {
  ARE_ENDORSED_BY = 'are_endorsed_by',
  SUPPORT = 'support'
}

export const sanityString = (string: Queries.Maybe<string>) => {
  return string !== null ? string : ''
}

export const candidateVerbSlugs = ['support', 'donate', 'fundraise']
export const candidateNounSlugs = ['candidates']
export const isCandidateSlug = (slug?: string) => slug && candidateNounSlugs.includes(slug)

export const removeDuplicatesBySlug = (array: { slug: { current: string } }[]) => {
  const unique = removeDuplicates(array.map(item => item?.slug?.current))
  return unique.map(itemSlug => array.find(item => item?.slug?.current === itemSlug))
}

const defaults = { nonTextBehavior: 'remove' }
export const blocksToText = (blocks: any[], opts = {}) => {
  const options = Object.assign({}, defaults, opts)
  return (blocks || []).map(block => {
    if (block._type !== 'block' || !block.children) {
      return options.nonTextBehavior === 'remove' ? '' : `[${block._type} block]`
    }

    return block.children.map((child: any) => child?.text).join('')
  })
}

export const getUniqueCobenefits = (cobenefits: Queries.SanityCobenefits[]) => {
  if (!cobenefits) return
  const cleanCobenefits = cobenefits.filter(cobenefit => cobenefit?.slug?.current)
  const uniqueSlugs = Array.from(new Set(cleanCobenefits.map(({ slug }) => slug?.current)))
  return uniqueSlugs.map(currSlug => cleanCobenefits.find(({ slug }) => currSlug == slug?.current))
}

export const getSanitySlug = (slug?: Pick<Queries.Maybe<Queries.SanitySlug>, 'current'>) =>
  slug?.current && slug.current !== null && slug.current ? slug.current : undefined

export const getBeHeardLocationStringFromSanityLocation = (location: Queries.SanityLocation) => {
  // if (!location.isRegional) {
  // let locationString = ''
  // if (location.zipcodes && location.zipcodes.length > 0) {
  //   return location.zipcodes
  // }
  // if (location.cities && location.cities.length > 0) {
  //   return location.cities
  // }
  if (location.states && location.states.length > 0) {
    return (location.states as string[]).sort((a, b) => a.localeCompare(b))
  }
  if (location.countries && location.countries.length > 0) {
    return (location.countries as string[]).sort((a, b) => a.localeCompare(b))
  }
  // }
}

export const getLocationStringFromSanityLocation = (location: Queries.SanityLocation) => {
  if (!location.isRegional) {
    return 'Global'
  } else {
    let locationString = ''
    if (location.countries && location.countries.length > 0) {
      locationString += 'Countries: ' + location.countries.join(', ')
    }
    if (location.states && location.states.length > 0) {
      locationString += 'States: ' + location.states.join(', ')
    }
    if (location.cities && location.cities.length > 0) {
      locationString += 'States: ' + location.cities.join(', ')
    }
    if (location.zipcodes && location.zipcodes.length > 0) {
      locationString += 'States: ' + location.zipcodes.join(', ')
    }
    return locationString
  }
}

export const getLocationType = (
  location?: Queries.Maybe<Queries.SanityLocation>
): LocationType | undefined => {
  if (!location?.isRegional) {
    return 'global'
  } else {
    if (location?.zipcodes && location?.zipcodes.length > 0) {
      return 'local'
    }
    if (location?.cities && location?.cities.length > 0) {
      return 'city'
    }
    if (location?.states && location?.states.length > 0) {
      return 'state'
    }
    if (location?.countries && location?.countries.length > 0) {
      return 'federal'
    }
  }
}
