import { Box, PaletteMode } from '@mui/material'
import * as React from 'react'
import { PropsWithChildren, useContext, useState } from 'react'

export const ColorModeContext = React.createContext<{
  mode: PaletteMode
  setMode?: React.Dispatch<React.SetStateAction<PaletteMode>>
}>({
  mode: 'light'
})

export const ColorModeProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [mode, setMode] = useState<PaletteMode | undefined>(undefined)
  React.useEffect(() => {
    const root = window.document.documentElement
    const initialColorValue = root.style.getPropertyValue('--initial-color-mode') as PaletteMode
    setMode(initialColorValue)

    const darkModeMediaQuery = window.matchMedia('(prefers-color-scheme: dark)')

    const listener = () => setMode(darkModeMediaQuery.matches ? 'dark' : 'light')
    darkModeMediaQuery.addListener(listener)

    return () => darkModeMediaQuery.removeListener(listener)
  }, [])

  const value = React.useMemo(() => ({ mode, setMode }), [mode])

  return mode ? (
    <ColorModeContext.Provider value={value}>{children}</ColorModeContext.Provider>
  ) : (
    <Box />
  )
}

export const colorModeContext = () => useContext(ColorModeContext)
