export const HEADER_HEIGHT = 96
export const MAX_PAGE_WIDTH = 1280
export const SINGLE_COLUMN = 600
export const PAGE_PADDING_TOP = HEADER_HEIGHT + 24
export const PAGE_PADDING_BOTTOM = 24

export const COLLAPSE_BUTTON_SPACING_OFFSET = 2.25

export const MODAL_HEADER_HEIGHT = 72

export const MODAL_WIDTH_MEDIUM = 430
export const MODAL_WIDTH_LARGE = 990

export const MAX_RESULTS_WIDTH = 1340
