import { Box } from '@mui/material'
import React, { useEffect } from 'react'
import { BrowserRouter as Router, useLocation } from 'react-router-dom'
import { ActionDataProvider } from '../context/action-data-context'
import { ActionSelectorProvider } from '../context/action-selector-context'
import { AuthProvider } from '../state/auth-state'
import { FiltersProvider } from '../state/filters-state'
import { LocationProvider } from '../context/location-context'
import { PageProvider } from '../context/page-context'
import Theme from '../context/theme-context'
import PageWithHeader from '../components/pages/page-with-header'
import { isInBrowser } from '../utilities/browser'
import ResultsLayout from './results-layout'
import DefaultLayout from './default-layout'
import { CandidateDataProvider } from '../context/candidate-data-context'
import { ColorModeProvider } from '../context/color-context'
import * as Fathom from 'fathom-client'
import { UserSearchProvider } from '../state/user-search-state'
import HeaderOnboarding from '../components/headers/header-onboarding'
import HeaderMain from '../components/headers/header-main/header-main'

const Layout = props => {
  const RouterWrapper = isInBrowser() ? Router : Box
  // TODO: refactor this so all data is just in one context

  if (isInBrowser()) {
    if (process.env.NODE_ENV == 'production') {
      // Gleap.initialize('iwmdDvARGVUum36H1277Ryn6QFbYdEfu')
      Fathom.load('URZHUQPU')
    }
  }

  /* TODO: Refactor this into one state provider template */

  return (
    <RouterWrapper>
      <AuthProvider>
        <LocationProvider>
          <ColorModeProvider>
            <Theme>
              <PageProvider>
                <FiltersProvider>
                  {/* TODO: UserSearchProvider should really be isolated to a community page template */}
                  <UserSearchProvider>
                    {/* TODO: A number of these should really be isolated to an action data template or integrated into the Results template */}
                    <ActionDataProvider>
                      <CandidateDataProvider>
                        <ActionSelectorProvider>
                          {<LayoutLookup {...props} />}
                        </ActionSelectorProvider>
                      </CandidateDataProvider>
                    </ActionDataProvider>
                  </UserSearchProvider>
                </FiltersProvider>
              </PageProvider>
            </Theme>
          </ColorModeProvider>
        </LocationProvider>
      </AuthProvider>
    </RouterWrapper>
  )
}

const layouts = {
  actions: props => (
    <ResultsLayout {...props} key="actions">
      {props.children}
    </ResultsLayout>
  ),
  explore: props => (
    <ResultsLayout {...props} key="explore">
      {props.children}
    </ResultsLayout>
  )
}

const headers = {
  onboarding: <HeaderOnboarding />
}

const LayoutLookup = props => {
  const layout = props.pageContext.layout
  const Layout = (layout && layouts[layout]) || DefaultLayout
  const Header = (layout && headers[layout]) || HeaderMain

  return (
    <PageWithHeader Header={Header}>
      <Layout>{props.children}</Layout>
    </PageWithHeader>
  )
}

export default Layout
