import {
  fetchSignInMethodsForEmail,
  isSignInWithEmailLink,
  signInWithEmailLink
} from 'firebase/auth'
import { isInBrowser } from '../../utilities/browser'
import { documentId, where } from 'firebase/firestore'
import { auth } from './config'
import { createSearchableString } from '../../utilities/string'
import {
  getFileSizeInKb,
  getImageTypeFromBase64String,
  parseBase64Data
} from '../../utilities/image'

export const accountExists = async (email: string) => {
  const signInMethods = await fetchSignInMethodsForEmail(auth, email)

  return signInMethods.length > 0
}

export const isFirebaseRedirectUrl = () => {
  return isInBrowser() && window.location.href && isSignInWithEmailLink(auth, window.location.href)
}

export const cleanupFirebaseRedirectUrl = () => {
  const href = isInBrowser() && window.location.href
  if (href && isSignInWithEmailLink(auth, href)) {
    // remove params
    const params = new URLSearchParams(window.location.search)
    if (params) {
      window.history.replaceState({}, document.title, window.location.pathname)
    }
  }
}

export const handleFirebaseRedirectUrl = async () => {
  const email =
    window.localStorage.getItem('wcid_signin_email') ||
    window.prompt('Please provide your email for confirmation')

  // The client SDK will parse the code from the link for you.
  if (email) {
    await signInWithEmailLink(auth, email, window.location.href)
      .then(() => {
        // Clear email from storage.
        window.localStorage.removeItem('wcid_signin_email')

        // remove params
        cleanupFirebaseRedirectUrl()
      })
      .catch(error => {
        const message =
          error.code === 'auth/invalid-action-code' &&
          'Your verification has expired, please try again.'
        if (message) {
          alert(message)
          return true
        }
      })
  }
}

export const createDocumentIdQuery = (ids: string[]) => {
  return where(documentId(), 'in', ids)
}

export const createInArrayQuery = (field: string, ids: string[]) => {
  return where(field, 'in', ids)
}

export const createSearchableName = (name?: string) => {
  if (!name) {
    return
  }
  const splitBySpaces = name.split(' ')
  return splitBySpaces.map(fragment => createSearchableString(fragment))
}

export const testVerifiedNameChange = (nameChange: string, verifiedName: string) => {
  const verifiedNameFragments = verifiedName.split(' ').map(name => name.toLowerCase())
  const nameFragments = nameChange.split(' ').map(name => name.toLowerCase())
  return (
    nameFragments.length > 0 &&
    nameFragments.every((fragment, i) => {
      const verifiedFragmentIndex = verifiedNameFragments[i].indexOf(fragment)
      return (
        verifiedFragmentIndex !== -1 &&
        (i != 0 || verifiedFragmentIndex !== verifiedNameFragments.length - 1) // first name must exist and match
      )
    })
  )
}

export const validateAvatar = (base64string: string) => {
  const image = base64string
  const type = getImageTypeFromBase64String(image)

  if (type !== 'jpeg' && type !== 'jpg' && type !== 'png') {
    return {
      valid: false,
      reason: 'Not a valid file type. Must be a .jpg or .png'
    }
  }

  const imageString = parseBase64Data(image)

  var sizeInKb = getFileSizeInKb(imageString, type)

  if (sizeInKb >= 5000) {
    return {
      valid: false,
      reason: 'Uploaded image must be below 5mb'
    }
  }
  return { valid: true }
}
