import React from 'react'

import { pageState } from '../../context/page-context'
import HeaderDesktop from './header-main/header_desktop'
import HeaderMobile from './header-main/header_mobile'
import { Box } from '@mui/material'

const HeaderOnboarding = () => {
  const { isMobile } = pageState()
  return <Box p={2}>{isMobile ? <HeaderMobile /> : <HeaderDesktop />}</Box>
}

export default HeaderOnboarding
