import { Box, Typography } from '@mui/material'
import React from 'react'

const TooltipTitle: React.FC<{ description: string | string[] }> = ({ description }) => {
  const descriptions = description && Array.isArray(description) ? description : [description]

  return (
    <Box>
      {descriptions?.map((string, i) => (
        <Typography variant="body2" textAlign="center">
          <React.Fragment>
            {string}
            {i !== descriptions.length - 1 && <br />}
          </React.Fragment>
        </Typography>
      ))}
    </Box>
  )
}

export default TooltipTitle
