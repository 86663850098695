import { Box, Button, Divider, Typography } from '@mui/material'
import * as React from 'react'
import { colors } from '../constants/colors'
import { HBox, SquareButton, VBox } from '../elements/basic-elements'

import { Delete } from '@mui/icons-material'
import { resultsState } from '../context/results-context'
import { ButtonPrimary, ButtonText } from '../elements/form-elements'
import FormSwitch from './form-switch'
import Input from './input'

const LocationControls: React.FC<{ onSave: Function }> = ({ onSave }) => {
  // const { settings, setSettings } = locationState()
  const { changeFilters, filters } = resultsState()
  const { locations, expandLocations } = filters
  // const { address, prioritizeLocation, restrictToLocation } = settings

  const [tempLocations, setTempLocation] = React.useState(locations || [''])

  const [tempExpandLocation, setTempExpandLocation] = React.useState(expandLocations)

  const clearAllFilters = () => {
    setTempLocation([''])
    setTempExpandLocation(false)
  }

  const saveChanges = React.useCallback(async () => {
    // don't save the placeholder location
    const newLocations = [...tempLocations].filter(loc => loc != '')
    const hasLocations = newLocations.length > 0
    onSave()

    await changeFilters({
      ...filters,
      locations: hasLocations ? newLocations : undefined,
      expandLocations: hasLocations && tempExpandLocation
    })
  }, [tempLocations, tempExpandLocation])

  const addEmptyLocation = () => {
    const newLocations = [...tempLocations]
    newLocations.push('')
    setTempLocation(newLocations)
  }

  const updateLocation = (loc: string, index: number, save?: boolean) => {
    const newLocations = [...tempLocations]
    newLocations.splice(index, 1, loc)
    if (save && loc == '' && index > 0 && index == newLocations.length - 2) {
      newLocations.splice(index, 1)
    }
    setTempLocation(newLocations)
  }

  const removeLocation = (index: number) => {
    const newLocations = [...tempLocations]
    newLocations.splice(index, 1)
    setTempLocation(newLocations)
  }

  return (
    <VBox flex={1} pb={2} gap={2}>
      <VBox flex={1} px={2}>
        <HBox py={3} alignItems="center">
          <HBox sx={{ flex: 1 }}>
            <Button
              variant="text"
              color="info"
              sx={{ fontSize: 15, color: colors.BLACK_50 }}
              onClick={clearAllFilters}
            >
              Clear All
            </Button>
          </HBox>
          <Typography sx={{ fontSize: 28 }} color="textPrimary">
            Location
          </Typography>
          <Box sx={{ flex: 1 }}></Box>
        </HBox>
        <VBox gap={1}>
          {tempLocations.map((location, i) => {
            const hasLocation = Boolean(location) && location !== ''
            return (
              <Box sx={{ opacity: hasLocation ? 1 : 0.5 }} display={'flex'} gap={1}>
                <Input
                  hasValue={hasLocation}
                  onCloseClick={() => updateLocation('', i, true)}
                  disableClose
                  label={'City/State'}
                  flexAuto
                >
                  {({ TextBox }) => {
                    return (
                      <TextBox
                        type="text"
                        value={location}
                        aria-label={'Location input'}
                        onChange={e => {
                          updateLocation(e.target.value, i)
                        }}
                        onBlur={e => {
                          updateLocation(e.target.value, i, true)
                        }}
                      />
                    )
                  }}
                </Input>
                <Box>
                  <SquareButton
                    fullWidth
                    variant="contained"
                    disableElevation
                    color="error"
                    onClick={() => removeLocation(i)}
                    disabled={i == 0 && !hasLocation}
                  >
                    <Delete />
                  </SquareButton>
                </Box>
              </Box>
            )
          })}
          <Divider />

          <VBox gap={2} width="100%">
            <FormSwitch
              text="Include results outside my location"
              value={Boolean(tempLocations && tempExpandLocation == true)}
              disabled={tempLocations.length == 0}
              onChange={async (shouldRestrict: boolean) => setTempExpandLocation(shouldRestrict)}
            />
            {
              <ButtonPrimary
                fullWidth
                variant="contained"
                disableElevation
                color="secondary"
                onClick={addEmptyLocation}
                disabled={tempLocations[tempLocations.length - 1] == ''}
              >
                <ButtonText lightColor="textPrimary">add another location</ButtonText>
              </ButtonPrimary>
            }
          </VBox>
        </VBox>
      </VBox>
      <Box px={2}>
        <Button
          fullWidth
          variant="contained"
          disableElevation
          color="primary"
          onClick={saveChanges}
        >
          show actions
        </Button>
      </Box>
    </VBox>
  )
}

export default LocationControls
