import { Badge, Box, styled, Typography, useMediaQuery, useTheme } from '@mui/material'
import { useLocation } from '@reach/router'
import React, { useEffect, useState } from 'react'
import { authState } from '../../../state/auth-state'

import { Menu } from '@mui/icons-material'
import { Link, navigate } from 'gatsby'

import { MODAL_WIDTH_MEDIUM } from '../../../constants/sizes'
import { HBox, SquareButton, VBox, WhiteBox } from '../../../elements/basic-elements'
import useSearchModal from '../../../hooks/useSearchModal'
import FaqIcon from '../../../images/icons/faq-icon'
import LogoutIcon from '../../../images/icons/logout-icon'
import MagGlassIcon from '../../../images/icons/mag-glass-icon'
import NewsletterIcon from '../../../images/icons/newsletter-icon'
import PodcastIcon from '../../../images/icons/podcast-icon'
import PremiumIcon from '../../../images/icons/premium-icon'
import ShopIcon from '../../../images/icons/shop-icon'
import WcidLogo from '../../../images/icons/wcid_logo'
import profilePlaceholder from '../../../images/profile-placeholder.png'
import ActionSelector from '../../action-selector'
import CenteredModal from '../../centered-modal'
import Confirmation from '../../confirmation'
import FullScreenModal from '../../full-screen-modal'
import IconAndLabelButton, { IconAndLabelButtonProps } from '../../icon-and-label-button'
import PartialScreenModal from '../../partial-screen-modal'
import { Avatar } from '../../user-avatar'
import BecomeImportantButton from '../become-important-button'
import StandardButton from '../../buttons/standard-button'
import LoadingShimmer from '../../loading-shimmer'
import { colors } from '../../../constants/colors'

const HeaderMobile = () => {
  const { account, accountLoading, logout } = authState()
  const theme = useTheme()
  const useSmallNavbar = useMediaQuery(theme.breakpoints.down('sm'))

  const image = account?.user?.profile.public.avatar
  const userName = account?.user?.profile.public.name
  const premium = account?.premium_access
  const requiresSpaceForPremiumCueAndAccount = account != null && !premium
  const showUserName = useSmallNavbar ? !requiresSpaceForPremiumCueAndAccount : true

  const [showMobileMenu, setShowMobileMenu] = useState(false)

  // -------------------------------------------
  // autoclose on nav
  // -------------------------------------------

  const route = useLocation()
  useEffect(() => {
    setShowMobileMenu(false)
  }, [route.pathname])

  // -------------------------------------------
  // search modal
  // -------------------------------------------

  const searchModalProps = useSearchModal()

  // -------------------------------------------
  // confirm logout modal
  // -------------------------------------------

  const [logoutModalOpen, setLogoutModalOpen] = useState(false)
  const confirmLogout = () => {
    logout()
    hideLogoutModal()
  }
  const hideLogoutModal = () => {
    setLogoutModalOpen(false)
  }

  return (
    <Box width="100%">
      <HBox gap={2} width="100%" alignItems="flex-start">
        <VBox gap={0.5} justifyContent="flex-start" flex={1}>
          <LogoLink to="/" activeClassName="active">
            <WcidLogo size={useSmallNavbar ? 44 : 64} />
          </LogoLink>
        </VBox>
        <HBox gap={1} flex={1} justifyContent="flex-end">
          {accountLoading ? (
            <LoadingShimmer
              borderRadius='15px'
              width='175px'
              height='48px'
              customColors={theme.palette.mode === 'light' ? { base: colors.BLACK_10, highlight: colors.WHITE } : null} speed={2} />
          ) :
            (<>
              {!premium && <BecomeImportantButton hasLimitedSpace={requiresSpaceForPremiumCueAndAccount} />}
              {account && (
                <HBox gap={1}>
                  <StandardButton
                    variant='contained'
                    color="tertiary"
                    disableElevation
                    sx={{ padding: '4px' }}
                    onClick={() => {
                      navigate(account?.user_id ? `/profile/${account?.user_id}` : '/profile')
                    }}
                  >
                    <HBox gap={1.5} alignItems="center">
                      <Box>
                        <Badge badgeContent={premium ? <PremiumIcon /> : 0}>
                          <Avatar src={image || profilePlaceholder} size={useSmallNavbar ? 38 : 56} />
                        </Badge>
                      </Box>
                      {showUserName && userName && < Typography
                        color="textPrimary"
                        fontSize={14}
                        textAlign="left"
                        sx={{
                          pr: 1,
                          whiteSpace: userName && userName?.length > 16 ? 'initial' : 'nowrap'
                        }}
                      >
                        {userName.split(' ')[0]}
                      </Typography>}
                    </HBox>
                  </StandardButton>
                </HBox>
              )}
              <SquareButton
                variant='contained'
                color="tertiary"
                disableElevation
                onClick={() => setShowMobileMenu(true)}
              >
                <Menu sx={{ color: theme => theme.palette.text.primary }} />
              </SquareButton>
            </>
            )}
        </HBox>
      </HBox>
      <PartialScreenModal open={showMobileMenu} onClose={() => setShowMobileMenu(false)}>
        <VBox sx={{ minHeight: '50vh' }} justifyContent="space-between">
          <VBox mt={1} gap={1} onClick={() => setShowMobileMenu(false)}>
            <WhiteBox p={0.25} height={64} sx={{ mx: 'auto'}}>
              <MenuButton
                color="secondary"
                label={account ? 'Logout' : 'Login'}
                icon={account ? <LogoutIcon /> : undefined}
                sx={{ width: 'auto' }}
                onClick={() => {
                  if (account) {
                      setLogoutModalOpen(true)
                  } else {
                      navigate('/login')
                  }
                }}
              />
            </WhiteBox>
            <WhiteBox p={0.25} height={64}>
              <MenuButton
                color="secondary"
                label="Search"
                icon={<MagGlassIcon size={24} />}
                onClick={() => {
                  requestAnimationFrame(() => {
                    searchModalProps.setModalOpen(true)
                  })
                }}
              />
            </WhiteBox>
            <WhiteBox p={0.25} height={64}>
              <MenuButton
                color="secondary"
                label="Shop"
                icon={<ShopIcon size={24} />}
                component="a"
                href="https://shop.importantnotimportant.com/"
                target="_blank"
              />
            </WhiteBox>

            <WhiteBox p={0.25} height={64}>
              <MenuButton
                color="secondary"
                label="Podcast"
                icon={<PodcastIcon size={24} />}
                component={'a'}
                href="https://podcast.importantnotimportant.com/"
                target="_blank"
              />
            </WhiteBox>
            <WhiteBox p={0.25} height={64}>
              <MenuButton
                color="secondary"
                label="Newsletter"
                icon={<NewsletterIcon size={24} />}
                component={'a'}
                href="https://www.importantnotimportant.com/subscribe"
                target="_blank"
              />
            </WhiteBox>
            <WhiteBox p={0.25} height={64}>
              <MenuButton
                color="secondary"
                label="FAQ"
                icon={<FaqIcon size={24} />}
                component="a"
                href="/faq"
              />
            </WhiteBox>
          </VBox>
        </VBox>
      </PartialScreenModal>

      <CenteredModal open={logoutModalOpen} onClose={hideLogoutModal}>
        <Confirmation
          onDeny={hideLogoutModal}
          onConfirm={confirmLogout}
          title={`Confirm Logout`}
          description="Are you sure you want to logout from WCID?"
          denyText="oops, no"
          confirmText="yes"
        />
      </CenteredModal>

      <FullScreenModal
        open={Boolean(searchModalProps.modalOpen)}
        size={MODAL_WIDTH_MEDIUM}
        onClose={() => searchModalProps.setModalOpen(false)}
      >
        <VBox p={2} flex={1}>
          <ActionSelector
            resultsOffset={2}
            sx={{ flex: 1 }}
            onSearch={() => searchModalProps.setModalOpen(false)}
          />
        </VBox>
      </FullScreenModal>
    </Box>
  )
}

const LogoLink = styled(Link)`
  text-decoration: none;
  display: flex;
  align-items: center;
  position: relative;
`

const MenuButton = styled((props: IconAndLabelButtonProps) => (
  <IconAndLabelButton
    flexDirection="row"
    {...props}
    sx={{ height: '100%', width: '100%', px: 3, ...props.sx }}
  />
))``

export default HeaderMobile
