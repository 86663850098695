import { Card, CardContent, SxProps, styled } from '@mui/material'
import React, { FC, PropsWithChildren } from 'react'
import { colors } from '../constants/colors'

export const CardStandard: FC<PropsWithChildren & { sx?: SxProps }> = ({ children, sx = {} }) => (
  <StyledCard
    sx={{
      // maxWidth: 345,
      p: 2.5,
      width: '100%',
      ...sx
    }}
  >
    <CardContentNoPadding>{children}</CardContentNoPadding>
  </StyledCard>
)

const StyledCard = styled(Card)`
  ${({ theme }) => `
    background: ${theme.palette.mode == 'dark' ? colors.DARK_MODE_80 : colors.WHITE};
    `}
`

export const CardContentNoPadding = styled(CardContent)(`
  padding: 0;
  width: 100%;
  &:last-child {
    padding-bottom: 0;
  }
`)

export default CardStandard
