import { Typography } from '@mui/material'
import React, { PropsWithChildren } from 'react'
import { HBox } from '../elements/basic-elements'
import { IconLabelProps } from './icon-and-label'

const IconAndLabelHorizontal: React.FC<PropsWithChildren<IconLabelProps>> = ({
  children,
  label,
  icon
}) => {
  return (
    <HBox width="100%" gap={2} justifyContent="space-between" alignItems="center">
      {label && (
        <Typography fontWeight={500} fontSize={16} color="textPrimary">
          {label}
        </Typography>
      )}
      {children}
      {icon}
    </HBox>
  )
}

export default IconAndLabelHorizontal
