import {Box, Button, Card, Typography, styled} from '@mui/material'
import * as React from 'react'
import {colors} from '../constants/colors'
import {HBox} from '../elements/basic-elements'

type ConfirmationProps = {
  title?: string
  description?: string
  confirmText?: string
  denyText?: string
  onDeny?: () => void
  onConfirm: () => void
  disableConfirm?: boolean
}

const Confirmation: React.FC<React.PropsWithChildren<ConfirmationProps>> = ({
  title,
  description,
  confirmText,
  denyText,
  onDeny,
  onConfirm,
  disableConfirm,
  children,
}) => {
  return (
    <CardEl>
      <CardContent>
        {title && (
          <Typography variant="h4" color={colors.WHITE} pt={2}>
            {title}
          </Typography>
        )}
        {description && <Typography variant="body2">{description}</Typography>}
        {children}
        <HBox gap={2} width="100%" pt={2}>
          {onDeny && (
            <Button
              fullWidth
              variant="contained"
              disableElevation
              sx={{py: 2}}
              color="secondary"
              onClick={onDeny}
            >
              <Typography variant="body1" color={colors.WHITE} fontSize={24}>
                {denyText || 'No'}
              </Typography>
            </Button>
          )}
          <Button
            fullWidth
            variant="contained"
            disableElevation
            sx={{py: 2}}
            color="primary"
            onClick={onConfirm}
            disabled={disableConfirm}
          >
            <Typography variant="body1" color={colors.WHITE} fontSize={24}>
              {confirmText || 'Yes'}
            </Typography>
          </Button>
        </HBox>
      </CardContent>
    </CardEl>
  )
}

const CardEl = styled(Card)`
  ${({theme}) => `
    padding: ${theme.spacing(2.5)};
    width: 450px;
    max-width: 100%;
    overflow: scroll;
    margin:auto;
    &::-webkit-scrollbar {
      display: none;
    }
    color: ${colors.WHITE};
    background: ${
      theme.palette.mode == 'dark'
        ? colors.DARK_MODE_80
        : colors.GEORGIAN_BAY_70
    };
  `}
`

const CardContent = styled(Box)`
  flex: 1;
  display: flex;
  align-items: center;
  width: 100%;
  flex-direction: column;
  justify-content: space-between;
  gap: ${({theme}) => theme.spacing(1)};
  text-align: center;
`
export default Confirmation
