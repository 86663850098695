import React from 'react'
import { actionSelectorState } from '../../context/action-selector-context'
import {
  FormFragment,
  FormFragmentInput,
  FormFragmentText,
  TextFragment
} from '../../styles/styled-components/action-selector'
import { useMaterialBreakpoint } from '../../utilities/react_hooks'
import ActionInput, { ActionInputPropsBase, InputOption } from './action-input'
import { createVerbInputOption } from './utils'

const VerbSelect = ({ suggestedSearchResultsPortal }: ActionInputPropsBase) => {
  const size = useMaterialBreakpoint()

  const { loading, optionsVerbs, selectedVerb, setSelectedVerb, resetSearch } =
    actionSelectorState()

  return (
    <FormFragment wrap={true}>
      <FormFragmentText>
        <TextFragment variant="h2" color="textPrimary">
          I want to
        </TextFragment>
      </FormFragmentText>
      <FormFragmentInput>
        {/* need to prevent verb input from rendering until the browser
      gives us its size */}
        {size && (
          <ActionInput
            id='verb'
            options={optionsVerbs?.map(verb => createVerbInputOption(verb)) || []}
            value={selectedVerb && createVerbInputOption(selectedVerb)}
            placeholder={'choose a verb'}
            loading={loading && !selectedVerb}
            suggestedSearchResultsPortal={suggestedSearchResultsPortal}
            revealOptionsDelay={500}
            onCloseClick={resetSearch}
            onValueChanged={(value?: InputOption) => {
              const newVerb =
                value && optionsVerbs?.find(({ slug }) => slug?.current === value.slug)
              setSelectedVerb && setSelectedVerb(newVerb)
            }}
          />
        )}
      </FormFragmentInput>
    </FormFragment>
  )
}

export default VerbSelect
