import React from 'react'
import { DEFAULT_PATH_COLOR, IconPath } from '../../elements/basic-elements'
import { CustomIconProps } from '../../types/component-types'

import { colors } from '../../constants/colors'
import { useTheme } from '@mui/material'

const FeaturedIcon = ({ size = 24, color = DEFAULT_PATH_COLOR }: CustomIconProps) => {
  const theme = useTheme()

  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <IconPath
        d="M21 4.24284C21 3.88795 20.6396 3.6464 20.3098 3.77738C16.5879 5.25541 14.2466 5.87888 10.4302 6.43809C10.1838 6.47421 10 6.68518 10 6.93428V14.0618C10 14.3126 10.1863 14.5244 10.4347 14.5589C14.1411 15.0729 16.4787 15.7199 20.312 17.2268C20.6413 17.3563 21 17.1149 21 16.761V4.24284Z"
        fill={color ?? (theme.palette.mode == 'dark' ? colors.DARK_MODE_70 : '#FFFFFF')}
      />
      <IconPath
        d="M3 8.5C3 7.39543 3.89543 6.5 5 6.5H8.5C8.77614 6.5 9 6.72386 9 7V14.4055C9 14.468 8.98797 14.5302 8.96587 14.5886C8.21318 16.5774 8.41444 18.4182 8.79905 19.8365C8.88837 20.1659 8.64757 20.5 8.30629 20.5H5.5C5.22386 20.5 5.00199 20.2769 5.01715 20.0012C5.05279 19.3534 5.16247 18.167 5.5 17C5.65612 16.4602 6.02348 15.8443 6.35555 15.3589C6.59556 15.0081 6.35068 14.5 5.9256 14.5H5C3.89543 14.5 3 13.6046 3 12.5V8.5Z"
        fill={color ?? (theme.palette.mode == 'dark' ? colors.DARK_MODE_70 : '#FFFFFF')}
      />
    </svg>
  )
}

export default FeaturedIcon
