import { Box, styled } from '@mui/material'
import { navigate } from 'gatsby'
import * as React from 'react'

import { actionDataState } from '../context/action-data-context'
import { resultsState } from '../context/results-context'

import { convertFiltersToParamObject, parameterizeFilters } from '../utilities/sanity-filters'
import { getDomain } from '../utilities/browser'
import FullScreenModal from './full-screen-modal'
import ResultCard from './result-card'
import ResultsList from './result-list'

const ActionsList = ({ title, onPageChange }: { title?: string; onPageChange?: Function }) => {
  const { actions, totalActions, loading, nextPage, fetchNextPage } = actionDataState()

  const isExplorePage = () => {
    return window.location.pathname.split('/').filter(p => p)[0] === 'explore'
  }

  const {
    filters,
    activeAction,
    showResultModal,
    modalWasClickedInto,
    setModalWasClickedInto,
    setActiveAction
  } = resultsState()

  return (
    <Container>
      <ResultsList
        title={title}
        results={actions}
        total={totalActions}
        loading={Boolean(loading)}
        nextPage={nextPage}
        fetchNextPage={fetchNextPage}
        onPageChange={onPageChange}
      >
        {data => {
          const action = data as Queries.SanityAction
          const {
            title,
            location,
            nouns,
            rank,
            description,
            descriptionShort,
            slug,
            categories,
            organization,
            tags,
            url
          } = action
          const actionUrl = isExplorePage()
            ? `/explore/action/${slug?.current}`
            : `/action/${slug?.current}`
          return (
            <ResultCard
              key={slug?.current}
              // @ts-expect-error: existing type issue
              data={{
                title,
                location,
                nouns,
                rank,
                slug,
                categories,
                organization,
                tags,
                description: descriptionShort || description,
                url,
                actionUrl,
                shareUrl: `${getDomain()}${actionUrl}`
              }}
              consolidate
              footer="list"
              onDetailClick={() => {
                setModalWasClickedInto(true)
                setActiveAction(action)
              }}
            />
          )
        }}
      </ResultsList>

      <FullScreenModal
        open={Boolean(showResultModal)}
        colorScheme="white"
        allowInnerScroll
        onClose={() => {
          if (modalWasClickedInto) {
            navigate(-1)
          } else {
            // this is happening if user landed on a specific action
            navigate(
              !isExplorePage()
                ? `/results/?${parameterizeFilters(convertFiltersToParamObject(filters))}`
                : '/explore'
            )
          }
        }}
      >
        {activeAction && <ResultCard data={activeAction} footer="detail" />}
      </FullScreenModal>
    </Container>
  )
}

const Container = styled(Box)`
  flex: 1;
  display: flex;
  flex-direction: column;
`
export default ActionsList
