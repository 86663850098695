import * as React from 'react'
import { PropsWithChildren } from 'react'
import { locationState } from './location-context'

export type CandidateData = {
  candidates: Queries.SanityCandidate[]
  fetchNewCandidates: Function
  fetchPrevPage: Function
  fetchNextPage: Function
  prevPage?: number
  nextPage?: number
  setCandidates: Function
  setTotalCandidates: Function
  setLoading: Function
  reset: Function
  totalCandidates?: number
  error?: string
  loading?: boolean
}

export const CandidateDataContext = React.createContext<CandidateData>({
  candidates: [],
  fetchNewCandidates: () => {},
  fetchPrevPage: () => {},
  fetchNextPage: () => {},
  setCandidates: () => {},
  setTotalCandidates: () => {},
  setLoading: () => {},
  reset: () => {}
})
let controller: AbortController | undefined

export const CandidateDataProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const { settings: locationSettings, locationData } = locationState()
  const [query, setQuery] = React.useState<string>()
  const [loading, setLoading] = React.useState(false)
  const [prevPage, setPrevPage] = React.useState<number | undefined>()
  const [nextPage, setNextPage] = React.useState<number | undefined>()
  const [error, setError] = React.useState<string>()
  const [candidates, setCandidates] = React.useState<Queries.SanityCandidate[]>([])
  const [totalCandidates, setTotalCandidates] = React.useState<number>()

  const reset = () => {
    setCandidates([])
    setTotalCandidates(0)
  }
  const fetchCandidates = React.useCallback(
    async (queryString = '') => {
      setLoading(true)
      if (controller) {
        controller.abort()
        controller = undefined
      }
      controller = new AbortController()
      const signal = controller.signal

      const request = await fetch(`/.netlify/functions/sanity-candidates?${queryString}`, {
        signal
      }).catch(error => {
        if (error.name === 'AbortError') {
          return
        } else {
          setError(error)
        }
      })
      // @ts-expect-error: existing type issue
      if (request?.status !== 200) {
        setError('There was an error loading this data.')
      }
      // @ts-expect-error: existing type issue
      const results = (await request?.json()) as {
        candidates: Queries.SanityCandidate[]
        count: number
        nextPage?: number
        prevPage?: number
      }
      setLoading(false)
      controller = undefined
      return results
    },
    [locationData, locationSettings]
  )

  const fetchNewCandidates = React.useCallback(
    async (queryString = '', force = false) => {
      if (query !== queryString || force) {
        setQuery(queryString)

        const response = await fetchCandidates(`page=0&${queryString}`)
        if (response) {
          const { candidates, count, prevPage: newPrevPage, nextPage: newNextPage } = response

          setCandidates(candidates)
          setTotalCandidates(count)
          setNextPage(newNextPage)
          setPrevPage(newPrevPage)
        }
      }
    },
    [locationData, locationSettings, query]
  )

  const fetchNextPage = React.useCallback(async () => {
    if (nextPage) {
      const {
        candidates: newCandidates,
        prevPage,
        nextPage: newNextPage
      } = await fetchCandidates(`page=${nextPage}&${query}}`)

      setCandidates([...candidates, ...newCandidates])
      setNextPage(newNextPage)
      setPrevPage(prevPage)
    }
  }, [candidates, query, nextPage, locationSettings, locationData])
  const fetchPrevPage = React.useCallback(async () => {
    if (prevPage) {
      const {
        candidates,
        nextPage,
        prevPage: newPrevPage
      } = await fetchCandidates(`page=${prevPage}&${query}`)
      setCandidates(candidates)
      setNextPage(nextPage)
      setPrevPage(newPrevPage)
    }
  }, [query, prevPage, locationSettings, locationData])

  const value = React.useMemo(
    () => ({
      loading,
      candidates,
      totalCandidates,
      error,
      prevPage,
      nextPage,
      setCandidates,
      fetchNewCandidates,
      fetchNextPage,
      fetchPrevPage,
      setLoading,
      setTotalCandidates,
      reset
    }),
    [
      loading,
      totalCandidates,
      prevPage,
      nextPage,
      fetchNewCandidates,
      fetchNextPage,
      fetchPrevPage,
      error,
      candidates
    ]
  )

  return <CandidateDataContext.Provider value={value}>{children}</CandidateDataContext.Provider>
}

export const candidateDataState = () => React.useContext(CandidateDataContext)
