import React from 'react'
import { HBox } from '../../elements/basic-elements'
import { AdditionalFilterPill } from '../../styles/styled-components/inputs/pills'
import { actionSelectorState, FilterType, isFilterActivated } from '../../context/action-selector-context'

export const ADDITIONAL_FILTERS_ID = 'additional-filters'
export const ADDITIONAL_FILTERS_PADDING = 16

const FILTERS = [
  { type: FilterType.COBENEFITS, name: 'co-benefits +' },
  { type: FilterType.SKILLS, name: 'skills +' },
  { type: FilterType.MEDIUMS, name: 'mediums +' },
  { type: FilterType.IMPACT, name: 'impact +' }
]

const AdditionalFilters = () => {
  const { activeFilters, setActiveFilters } = actionSelectorState()
  const allFiltersActivated = activeFilters?.length === Object.keys(FilterType).length

  return (
    !allFiltersActivated ?
      <HBox gap={1} sx={{ '&:not(:only-child)': { paddingTop: `${ADDITIONAL_FILTERS_PADDING}px` }, marginLeft: 2, overflowX: 'auto' }}>
      {FILTERS.map(filter => {
        const activateFilter = () => {
          setActiveFilters(filters => filters.concat(filter.type))
          // Since we prevented the default below, manually blur any focused input field now that
          // we've captured the click
          if (document.activeElement instanceof HTMLElement) {
            document.activeElement.blur();
          }
        }
        return !isFilterActivated(filter.type, activeFilters) ?
          <AdditionalFilterPill
            key={`filter-${filter.name}`}
            id={ADDITIONAL_FILTERS_ID}
            animate
            onClick={activateFilter}
            onMouseDown={(e) => {
              // Prevent default because it will cause a focused input field to blur, which results in the options
              // from that input field being removed from the DOM, which in turn causes this component to move up,
              // which results in onMouseUp, and thus onClick, not firing on this component
              e.preventDefault()
            }}
          >
            {filter.name}
          </AdditionalFilterPill> : null
      })}
      </HBox> : null
  )
}

export default AdditionalFilters
