import { Box, Button, styled, Typography } from '@mui/material'
import { excludeScProps } from '../../../utilities/browser'
import { colors } from '../../../constants/colors'

export const FormContainer = styled(Box)`
  position: relative;
  width: 100%;
  max-width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: ${({ theme }) => theme.spacing(4)};

  ${({ theme }) => theme.breakpoints.up('md')} {
    padding-top: 0;
  }
`

export const DesktopSpacer = styled(Box, excludeScProps('shrink'))<{ shrink: boolean }>`
  display: none;
  margin-top: -3rem;
  ${({ theme }) => theme.breakpoints.up('md')} {
    display: block;
    flex: 1;
    flex: ${({ shrink }) => (shrink ? '0.33' : '1')};
    transition: flex 0.5s cubic-bezier(0.5, 0, 0, 1);
  }
`

export const Form = styled(Box)`
  ${({ theme }) => `
    width: 100%;
    border-radius: 24px;
    padding: ${theme.spacing(1.25)} ${theme.spacing(1.25)};
    background: ${theme.palette.mode == 'dark' ? colors.DARK_MODE_70 : colors.WHITE};
  `}
`

export const BelowForm = styled(Box)`
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  ${({ theme }) => `
    padding-top: ${theme.spacing(2)};
    padding-bottom: ${theme.spacing(2)};
  `}
`

export const ActionFormFragments = styled(Box)`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  ${({ theme }) => `
    gap: ${theme.spacing(1)} ${theme.spacing(1.5)};
  `}
`

export const FormFragment = styled(Box, excludeScProps('wrap'))<{ wrap?: boolean }>`
  ${({ wrap }) => `
    flex: 1 0 auto;
    flex-wrap: ${wrap ? 'wrap' : 'nowrap'};
  `}
  max-width: 100%;
  display: inline-flex;
  align-items: center;
  ${({ theme }) => `
    gap: ${theme.spacing(1)} ${theme.spacing(1.5)};
    ${theme.breakpoints.down('sm')} {
      flex-wrap: wrap;
    }
  `}
`
export const FormFragmentText = styled(Box)`
  ${({ theme }) => `
    padding: ${theme.spacing(1.5)} 0;
  `}
  @keyframes up {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  animation: up 0.4s cubic-bezier(0, 0, 0, 1);
`
export const FormFragmentInput = styled(Box)<{ grow?: boolean }>`
  ${({ grow }) => `
    flex: 1 0 auto;
    flex-wrap: ${grow ? 'wrap' : 'no-wrap'};
  `}
  display: flex;
  animation: up 0.4s cubic-bezier(0, 0, 0, 1);
  max-width: 100%;
`

export const TextFragment = styled(Typography)`
  flex: 0 0 auto;
  white-space: nowrap;
  font-size: 24px;
  padding-left: ${({ theme }) => theme.spacing(1)};
`

export const RemainingSpaceContainer = styled(Box) <{ offset?: number }>`
  ${({ offset, theme }) => `
    position: relative;
    flex: 1;
    margin-left: ${offset ? `-${theme.spacing(offset)}` : '0'};
    width: ${offset ? `calc(100% + ${theme.spacing(offset * 2)})` : '100%'};
  `}
`

export const SuggestionsAndFiltersPortal = styled(Box)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column-reverse;
  justify-content: flex-end;
  float: none;
`

export const SubmitButton = styled(Button)`
  animation: up 0.4s cubic-bezier(0, 0, 0, 1);
  delay: 0.2s;
  animation-fill-mode: backwards;
`

export const TooltipContainer = styled(Box)`
  display: inline-flex;
  align-items: flex-start;
`
