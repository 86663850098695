import { PageProps } from 'gatsby'
import React, { useEffect } from 'react'
import SearchResults from '../components/search-results'
import { ActionPageProvider } from '../context/results-context'
import { actionDataState } from '../context/action-data-context'
import { candidateDataState } from '../context/candidate-data-context'

const ResultsLayout: React.FC<PageProps> = props => {
  const { setActions, setTotalActions } = actionDataState()
  const { setCandidates, setTotalCandidates } = candidateDataState()

  useEffect(() => {
    return () => {
      setActions([])
      setTotalActions(0)
      setCandidates([])
      setTotalCandidates(0)
    }
  }, [])

  return (
    <ActionPageProvider>
      <SearchResults></SearchResults>
      {props.children}
    </ActionPageProvider>
  )
}

export default ResultsLayout
