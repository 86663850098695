import { FormControl, Input, InputLabel, styled, Theme } from '@mui/material'
import { colors } from '../../../constants/colors'

export const InputWrapperBase = styled(FormControl)<{ background?: string; fullWidth?: boolean }>`
  position: relative;
  width: 100%;
  min-width: ${({ fullWidth }) => (fullWidth ? '100%' : 'auto')};
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(0.5)};
  ${({ theme, background }) => `
    padding: ${theme.spacing(2)};
    border-radius: ${theme.shape.borderRadius}px;
    padding-top: 0;
    padding-bottom: 0;
    background: ${
      background || (theme.palette.mode == 'dark' ? colors.DARK_MODE_50 : colors.WHITE)
    };
  `}
`

export const InputLabelBase = styled(InputLabel)`
  ${({ theme }) => `
    &.root {
      color: ${theme.palette.mode == 'dark' ? theme.palette.text.primary : theme.palette.info.dark};
      transform: translate(0, ${theme.spacing(2.75)}) scale(1);
      transition: all 200ms cubic-bezier(0.0, 0, 0.2, 1);


      &[data-shrink='true'] {
        transform: translate(0, ${theme.spacing(1)}) scale(0.75);
        // opacity: 0;

        ${theme.breakpoints.down('md')} {
          transform: translate(0, ${theme.spacing(1)}) scale(0.75);
        }
      }
      &.Mui-error {
          color: ${theme.palette.error.main};
      }

  `}
`

export const inputStyles = (theme: Theme) => `
  width: 100%;
  display: inline-block;
  border: none;
  outline: none;
  background: none;
  user-select: none;
  font-weight: 500;
  padding: 0;
  font-weight: 500;
  font-size: 17px;
  flex: 1;
  // margin-top: 0!important;
  padding: 8px 0!important;
  border-radius: ${theme.shape.borderRadius}px;
  color: ${theme.palette.mode == 'dark' ? colors.WHITE : colors.BLACK_100};
  ::placeholder {
    color: ${theme.palette.mode == 'dark' ? colors.DARK_MODE_30 : colors.BLACK_50};
  }
`
export const TextBoxBase = styled(Input)`
  ${({ theme }) => inputStyles(theme)}
`

export const InputBase = styled('input')`
  ${({ theme }) => inputStyles(theme)}
`

export const ActionInputField = styled(InputBase)`
  width: '100%';
  font-family: 'Hco Gotham';
  letter-spacing: '-0.03em';
  line-height: 1.3;
  font-size: 20px;
  text-overflow: 'ellipsis';
  font-weight: 400;
`
