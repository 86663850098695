import { Modal, Slide, styled, SxProps } from '@mui/material'
import React, { PropsWithChildren } from 'react'
import { VBox } from '../elements/basic-elements'

const CenteredModal: React.FC<
  PropsWithChildren & {
    open: boolean,
    onClose: () => void,
    sx?: SxProps
  } 
  > = ({ children, open, onClose, sx }) => {
  return (
    <StyledModal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={onClose}
      closeAfterTransition
      sx={sx}
    >
      <ModalContainer justifyContent="flex-end">
        <Slide direction={'up'} in={open} timeout={500} easing="cubic-bezier(0, 0, 0, 1)">
          <ModalContent>{children}</ModalContent>
        </Slide>
      </ModalContainer>
    </StyledModal>
  )
}

const StyledModal = styled(Modal)`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  ${({ theme }) => `
    z-index:  ${theme.zIndex.modal};

    ${theme.breakpoints.up('md')} {
        align-items: center;
    }
  `}
`

const ModalContainer = styled(VBox)`
  pointer-events: none;
  ${({ theme }) => {
    return `
        width: 100%;
      ${theme.breakpoints.up('md')} {
        width: auto;
      }
  `
  }}
`

const ModalContent = styled(VBox)`
  pointer-events: all;
  ${({ theme }) => `
        max-height: calc(100vh - 100px);
        overflow: scroll;
        padding: ${theme.spacing(2)};
        border-radius:
        ${theme.shape.borderRadius} ${theme.shape.borderRadius} 0 0;

        ${theme.breakpoints.up('md')} {
            max-height: 100%;
            height: 100%;
            width: 100%;
            border-radius: ${theme.shape.borderRadius}px;
        }
    `}
`

export default CenteredModal
