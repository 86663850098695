import React from 'react'
import CenteredModal from './centered-modal'
import WcidLogo from '../images/logos/wcid-logo.svg'
import { Box, Typography } from '@mui/material'
import { SquareButton, VBox, HBox } from '../elements/basic-elements'
import CloseIcon from '../images/icons/close-icon'
import CardStandard from './card-standard'
import IndicatorTag from './indicator-tag'
import { SuggestionType } from './suggested-actions-list/suggested-action-utils'
import PrimaryButton from './buttons/primary-button'
import { navigate } from 'gatsby'

interface GetMembershipModalProps {
  content: {
    headline?: string
    description?: string[]
    features?: string
    cta?: string
  }
  open: boolean
  onClose: () => void
}

const GetMembershipModal = ({ content, open, onClose }: GetMembershipModalProps) => {
  return (
    <CenteredModal open={open} onClose={onClose} sx={{ alignItems: 'center' }}>
      <CardStandard
        sx={{
          position: 'relative',
          padding: { xs: '60px 20px 30px', sm: '70px 40px 30px' },
          width: { xs: '90%', sm: '527px' },
          alignSelf: 'center'
        }}
      >
        <SquareButton
          variant="contained"
          color="infoSecondary"
          disableElevation
          sx={{ position: 'absolute', top: 20, right: 20 }}
          onClick={onClose}
        >
          <CloseIcon />
        </SquareButton>
        <Box display="flex" justifyContent="center" paddingBottom="25px">
          <img src={WcidLogo} />
        </Box>
        <VBox gap="30px" padding={{ xs: 0, md: '0px 40px' }}>
          <VBox
            gap="15px"
            sx={{ padding: { xs: 0, sm: '0px 21px' } }}
            justifyContent="center"
            textAlign="center"
          >
            <Typography variant="h4" color="textPrimary">
              {content?.headline}
            </Typography>
            <Typography variant="body2" color="textPrimary">
              {content?.description}
            </Typography>
          </VBox>
          <VBox padding={{ xs: '0px 25px', md: 0 }} gap="15px">
            {content?.features?.map(featureText => {
              return (
                <HBox gap={2.5} alignItems="center">
                  <Box>
                    <IndicatorTag size="small" type={SuggestionType.PERSONAL} />
                  </Box>
                  <Typography variant="body2" color="textPrimary">
                    {featureText}
                  </Typography>
                </HBox>
              )
            })}
            <Box textAlign="center" paddingTop={{ xs: '5px', sm: '40px' }}>
              <PrimaryButton
                variant="contained"
                disableElevation
                fullWidth
                onClick={() => {
                  navigate('/register')
                }}
              >
                {content?.cta}
              </PrimaryButton>
            </Box>
          </VBox>
        </VBox>
      </CardStandard>
    </CenteredModal>
  )
}

export default GetMembershipModal
