import { styled } from '@mui/material'
import { Box } from '@mui/system'
import React, { FC, PropsWithChildren } from 'react'
import { colors } from '../../constants/colors'
import { pageState } from '../../context/page-context'
import { VBox } from '../../elements/basic-elements'

const PageWithHeader: FC<PropsWithChildren<{ Header?: React.FC }>> = ({ children, Header }) => {
  const { pageRef, pageContentRef } = pageState()

  return (
    <PageContainer ref={pageRef}>
      {Header && (
        <HeaderContainer>
          <Header />
        </HeaderContainer>
      )}
      <PageContentContainer>
        <PageContent ref={pageContentRef}>{children}</PageContent>
      </PageContentContainer>
    </PageContainer>
  )
}

const PageContainer = styled(Box)`
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 100svh;
    background: ${colors.GLACIER_BLUE_70};

    @media (prefers-color-scheme: dark) {
      background: ${colors.DARK_MODE_100};
    }

  * {
    &::-webkit-scrollbar {
      display: none;
    }
  }
`
const HeaderContainer = styled(Box)`
  position: relative;
  z-index: 1;
`

const PageContentContainer = styled(VBox)`
  position: relative;
  z-index: 0;
  flex: 1;
  padding: 0;
`
const PageContent = styled(VBox)`
  min-height: 100%;
  flex: 1;
`

export default PageWithHeader
