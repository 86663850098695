import { Box } from '@mui/material'
import React from 'react'
import { candidateCategoryOptions } from '../../constants'
import { actionSelectorState } from '../../context/action-selector-context'
import {
  FormFragment,
  FormFragmentInput,
  FormFragmentText,
  TextFragment
} from '../../styles/styled-components/action-selector'
import ActionInput, { ActionInputPropsBase, InputOption } from './action-input'

const CandidateSelect = ({ suggestedSearchResultsPortal }: ActionInputPropsBase) => {
  const { selectedCandidateCategory, setSelectedCandidateCategory } = actionSelectorState()

  return (
    <Box>
      <FormFragment>
        <FormFragmentText>
          <TextFragment variant="h2" color="textPrimary">
            who
          </TextFragment>
        </FormFragmentText>
        <FormFragmentInput>
          <ActionInput
            id={'candidate-category'}
            options={candidateCategoryOptions}
            value={candidateCategoryOptions.find(({ slug }) => slug === selectedCandidateCategory)}
            placeholder={'are...'}
            suggestedSearchResultsPortal={suggestedSearchResultsPortal}
            onCloseClick={() => {
              setSelectedCandidateCategory && setSelectedCandidateCategory(undefined)
            }}
            onValueChanged={(value?: InputOption) => {
              setSelectedCandidateCategory && setSelectedCandidateCategory(value?.slug)
            }}
          />
        </FormFragmentInput>
      </FormFragment>
    </Box>
  )
}

export default CandidateSelect
