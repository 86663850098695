export const getImageTypeFromBase64String = (image: string) => {
  return image.substring('data:image/'.length, image.indexOf(';base64'))
}

export const parseBase64Data = (image: string) => {
  return image.replace(/^data:\w+\/\w+;base64,/, '')
}

export const getJpgOrPng = (image: string) => {
  return image === 'jpeg' || image === 'jpg' ? 'jpg' : image === 'png' ? 'png' : ''
}

export const getFileSizeInKb = (image: string, type: string) => {
  const stringLength = image.length - `data:image/${type};base64,`.length
  const sizeInBytes = 4 * Math.ceil(stringLength / 3) * 0.5624896334383812
  return sizeInBytes / 1000
}
