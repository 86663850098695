import { styled } from '@mui/material'
import React from 'react'
import { CustomIconProps } from '../../types/component-types'
import { DEFAULT_PATH_COLOR, getColorForPath, IconPath } from '../../elements/basic-elements'

const MagGlassIcon = ({ size = 18, color = DEFAULT_PATH_COLOR }: CustomIconProps) => (
  <svg
    width={size || '18'}
    height={size || '18'}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <StyledCircle
      cx="8.64518"
      cy="8.64518"
      r="6.72917"
      stroke={color}
      strokeWidth="1.5"
      vectorEffect="non-scaling-stroke"
    />
    <IconPath d="M13.6035 13.6035L16.0827 16.0827" stroke={color} />
  </svg>
)

const StyledCircle = styled('circle')<{ stroke: string }>`
  ${({ stroke, theme }) => `
    stroke: ${getColorForPath(theme, stroke)};
  `}
`

export default MagGlassIcon
