export const colors = {
  GEORGIAN_BAY_100: '#004660',
  GEORGIAN_BAY_70: '#237D9D',
  GEORGIAN_BAY_30: '#63AEC9',
  GEORGIAN_BAY_10: '#D9EDF3',
  GLACIER_BLUE_100: '#6FB1C8',
  GLACIER_BLUE_70: '#D4E8EF',
  GLACIER_BLUE_S70: '#21353C',
  BLACK_100: '#222222',
  BLACK_50: '#919191',
  BLACK_30: '#BDBDBD',
  BLACK_10: '#F2F2F2',
  RED_100: '#FF4438',
  ALZARIAN_100: '#E81B23',
  GREEN_100: '#319135',
  BLUE_100: '#0044C9',
  WHITE: '#FFFFFF',
  DARK_MODE_100: '#00394E',
  DARK_MODE_80: '#00455E',
  DARK_MODE_70: '#015270',
  DARK_MODE_50: '#237D9D',
  DARK_MODE_30: '#ADD4E2',
  DARK_MODE_10: '#D9EDF3',
  BLUE_DEMOCRAT_100: '#0044C9',
  RED_REPUBLICAN_100: '#E81B23'
}
