import React, { useMemo } from "react"
import { WhiteBox } from "../../elements/basic-elements"
import { FormFragment, FormFragmentText, TextFragment, FormFragmentInput } from "../../styles/styled-components/action-selector"
import ActionInput, { ActionInputPropsBase, InputOption } from "./action-input"
import { createSearchParamOptionFromEntity } from "./utils"
import { actionSelectorState, FilterType } from "../../context/action-selector-context"

export type SanityOption = { slug?: Pick<Queries.Maybe<Queries.SanitySlug>, 'current'>, title: string }

interface FilterSelectProps {
    label: string
    filterType: FilterType
    matchingOptions?: string[]
    renderOption?: (option: InputOption) => React.ReactNode
    sortOptions?: boolean
}

const FilterSelect = (props: ActionInputPropsBase & FilterSelectProps) => {
    const { selectedFilterValues, setSelectedFilterValues, optionsFilters } = actionSelectorState()

    const { label, filterType, matchingOptions } = props
    const allOptionEntities = optionsFilters?.[filterType]
    const selectedOption = selectedFilterValues?.[filterType]
    const isLoading = !allOptionEntities || !matchingOptions
    const setSelectedOption = (option) => { setSelectedFilterValues(value => ({ ...value, [filterType]: option })) }

    const inputOptions = useMemo(() => {
        return isLoading ? [] : allOptionEntities?.map(entity => {
            return createSearchParamOptionFromEntity(entity, matchingOptions ?? [])
        })
    }, [isLoading, allOptionEntities, matchingOptions])

    return (
        <WhiteBox sx={{ p: 1, width: '100%', marginTop: 1 }}>
            <FormFragment sx={{ display: 'flex' }}>
                <FormFragmentText>
                    <TextFragment variant="h2" color="textPrimary">
                        {label}
                    </TextFragment>
                </FormFragmentText>
                <FormFragmentInput sx={{ flex: 1 }}>
                    <ActionInput
                        id={label}
                        options={inputOptions}
                        value={selectedOption}
                        loading={isLoading}
                        placeholder={'choose an option'}
                        renderOption={props.renderOption}
                        suggestedSearchResultsPortal={props.suggestedSearchResultsPortal}
                        onCloseClick={() => { setSelectedOption && setSelectedOption(undefined) }}
                        onValueChanged={(value?: InputOption) => { setSelectedOption && setSelectedOption(value) }}
                        sortOptions={props.sortOptions}
                    />
                </FormFragmentInput>
            </FormFragment>
        </WhiteBox>
    )

}

export default FilterSelect