import { Button, ButtonProps, Typography } from '@mui/material'
import React from 'react'

const PrimaryButton: React.FC<ButtonProps> = ({ children, ...rest }) => {
  return (
    <Button color="primary" {...rest}>
      <Typography color={rest.color} fontWeight={500}>
        {children}
      </Typography>
    </Button>
  )
}

export default PrimaryButton
