import React from 'react'
import { DEFAULT_PATH_COLOR, IconPath } from '../../elements/basic-elements'
import { CustomIconProps } from '../../types/component-types'

const ShopIcon = ({ size, color = DEFAULT_PATH_COLOR }: CustomIconProps) => (
  <svg
    width={size}
    height={size / 0.94444}
    viewBox="0 0 17 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <IconPath
      d="M2.68732 9.02063C3.06782 7.11816 3.25806 6.16692 3.88669 5.55427C4.00287 5.44103 4.12857 5.33799 4.26239 5.24626C4.98643 4.75 5.95651 4.75 7.89666 4.75H9.10291C11.0431 4.75 12.0131 4.75 12.7372 5.24626C12.871 5.33799 12.9967 5.44103 13.1129 5.55427C13.7415 6.16692 13.9317 7.11816 14.3122 9.02063C14.8585 11.752 15.1316 13.1176 14.5029 14.0854C14.389 14.2606 14.2562 14.4227 14.1067 14.5687C13.281 15.375 11.8883 15.375 9.10291 15.375H7.89665C5.11123 15.375 3.71851 15.375 2.8929 14.5687C2.74341 14.4227 2.61053 14.2606 2.49668 14.0854C1.86792 13.1176 2.14105 11.752 2.68732 9.02063Z"
      stroke={color}
    />
    <IconPath
      d="M6.375 4.74935V4.04102C6.375 2.86741 7.32639 1.91602 8.5 1.91602C9.6736 1.91602 10.625 2.86741 10.625 4.04102V4.74935"
      stroke={color}
    />
    <IconPath
      d="M6.49414 11.1243C6.78586 11.9497 7.57299 12.541 8.49822 12.541C9.42346 12.541 10.2106 11.9497 10.5023 11.1243"
      stroke={color}
    />
  </svg>
)

export default ShopIcon
